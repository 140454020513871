import NextLink from "next/link";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  AspectRatio,
  Box,
  BoxProps,
  chakra,
  Checkbox,
  Flex,
  Image as ChakraImage,
  Link,
  LinkBox,
  LinkOverlay,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { API, graphqlOperation } from "aws-amplify";
import { Item, Sell } from "src/API";
import { itemByAddress, sellByItemByStatus } from "src/graphql/queries";

import {
  Creator,
  Listing,
  Metaplex,
  Nft,
  NftWithToken,
  Sft,
  SftWithToken,
} from "@metaplex-foundation/js";
import { Connection, PublicKey } from "@solana/web3.js";

import { motion } from "framer-motion";
import { useParameterStore } from "utils/context";
import { Verified } from "./logo";

export default function CardItem(props: {
  items: Item;
  size?: "small" | "medium";
  isFullWidh?: boolean;
  disabled?: boolean;
  enableSelectItem?: boolean;
  selectedItems?: Item[];
  selectItemHandler?: Dispatch<SetStateAction<Item[]>>;
  boxStyle?: BoxProps;
  solToIdrPrice?: number | null;
  connectionUrl?: string | null;
  showCheckBox?: boolean;
}) {
  const {
    address,
    id,
    name,
    imageUrl,
    creator,
    recipientAddress,
    owner,
    price,
  } = props.items;
  const {
    isFullWidh,
    disabled,
    enableSelectItem,
    selectedItems,
    selectItemHandler,
    boxStyle,
    solToIdrPrice,
    connectionUrl,
    showCheckBox,
  } = props;

  const bgColor = useColorModeValue("white", "main.darkblue");
  const textColor = useColorModeValue("gray.500", "darkPurple.300");
  const bgHover = useColorModeValue("gray.50", "main.newDarkBlue");

  const isSelected =
    selectedItems &&
    selectedItems.length !== 0 &&
    selectedItems?.filter((f) => f.id == id).length > 0;

  return (
    <LinkBox
      as={motion.div}
      layout={"size"}
      width={isFullWidh ? "full" : "auto"}
      rounded="lg"
      position="relative"
      p={isSelected ? "1px" : "1px"}
      shadow={isSelected ? "glow-blue" : "md"}
      bg={"gradient.blue"}
      transition={"0.25s ease-in-out"}
      transitionProperty={"filter, box-shadow"}
      cursor={!disabled ? "pointer" : "auto"}
      role={!disabled ? "group" : "none"}
      filter={
        isSelected || disabled || !enableSelectItem
          ? "brightness(100%)"
          : "brightness(50%)"
      }
      _hover={{
        filter: "brightness(100%)",
        shadow: isSelected ? "glow-blue" : "lg",
      }}
      {...boxStyle}
    >
      <Box
        position={"relative"}
        overflow={"visible"}
        rounded={"lg"}
        bg={bgColor}
        height={"full"}
        transition={"filter 0.25s ease-in-out"}
      >
        <Box width={"full"} overflow={"hidden"} roundedTop="lg">
          <AspectRatio
            ratio={1}
            width={"full"}
            overflow={"hidden"}
            _groupHover={{ transform: "scale(1.05)" }}
            transition={"all 0.25s ease-in-out"}
          >
            <ChakraImage
              src={imageUrl}
              alt={`Picture of ${name}`}
              objectFit="cover"
              w={"full"}
              h={"full"}
              fallback={<Skeleton w={"full"} h={"full"} />}
            />
          </AspectRatio>
        </Box>
        <Box
          p={3}
          _groupHover={{ bg: bgHover }}
          roundedBottom={"lg"}
          transition={"all 0.25s ease-in-out"}
        >
          <Flex justifyContent="space-between">
            <Box textAlign={"left"}>
              {!disabled && (
                <LinkOverlay
                  as={NextLink}
                  href={`/item/${id}`}
                  aria-label={`Read more about ${name}`}
                ></LinkOverlay>
              )}

              <Text
                fontWeight={"semibold"}
                fontSize={
                  props.size === undefined
                    ? "sm"
                    : props.size == "small"
                    ? "xs"
                    : "sm"
                }
                noOfLines={1}
              >
                {name}
              </Text>
              <Text
                fontSize="xs"
                fontWeight="normal"
                color={textColor}
                display={"flex"}
                alignItems={"center"}
              >
                <Link
                  as={NextLink}
                  noOfLines={1}
                  href={`/creator/${owner ? owner.name : creator?.name}`}
                >
                  {owner ? (
                    <>
                      {owner.name}
                      {owner.type === "OFFICIAL" && <Verified />}
                    </>
                  ) : (
                    <>
                      {creator?.name}
                      {creator?.type === "OFFICIAL" && <Verified />}
                    </>
                  )}
                </Link>
              </Text>
            </Box>
            {price && price.length > 0 && (
              <Box
                fontSize="2xl"
                textAlign={"right"}
                width={"50%"}
                flexBasis={"50%"}
                flexShrink={0}
              >
                <Text
                  fontSize={
                    props.size === undefined
                      ? "sm"
                      : props.size == "small"
                      ? "xs"
                      : "sm"
                  }
                  fontWeight="semibold"
                  whiteSpace={"nowrap"}
                >
                  {price[0]?.value} {price[0]?.currency}
                </Text>
                <Text
                  fontSize={
                    props.size === undefined
                      ? "xs"
                      : props.size == "small"
                      ? "xs"
                      : "xs"
                  }
                >
                  {solToIdrPrice &&
                    price[0] &&
                    Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumSignificantDigits: 3,
                    }).format(Math.ceil(price[0]?.value * solToIdrPrice))}
                </Text>
              </Box>
            )}
          </Flex>
        </Box>
        {enableSelectItem && (
          <Flex
            cursor={"default"}
            alignItems={"center"}
            p={2}
            bg={"rgba(255, 255, 255, 0.35)"}
            backdropFilter={"blur(15px)"}
            rounded={"full"}
            visibility={
              isSelected ||
              (selectedItems && selectedItems?.length > 0) ||
              showCheckBox
                ? "visible"
                : "hidden"
            }
            position={"absolute"}
            top={2}
            right={2}
            zIndex={1}
            transform={"all 0.25s ease-in-out"}
            _groupHover={{ visibility: "visible" }}
          >
            <Flex
              borderWidth={"2px"}
              p={"2px"}
              bg={isSelected ? "gray.50" : "transparent"}
              borderColor={"gray.50"}
              justifyContent={"center"}
              alignItems={"center"}
              rounded={"md"}
            >
              <Checkbox
                cursor={"cell"}
                size={"md"}
                variant={"main"}
                transform={"all 0.25s ease-in-out"}
                isChecked={isSelected}
                onChange={(e) => {
                  selectItemHandler
                    ? e.target.checked
                      ? selectItemHandler((s) => [...s, props.items])
                      : selectItemHandler((s) => [
                          ...s.filter((f) => f.id !== id),
                        ])
                    : "";
                }}
              />
            </Flex>
          </Flex>
        )}
        {/* <Divider />
        <Box p="3">
          <Flex justifyContent="space-between">
            <Box>
              <Button
                color={useColorModeValue("gray.600", "gray.400")}
                variant="link"
              >
                Buy Now
              </Button>
            </Box>
            <LoveButton like={false} total={20} />
          </Flex>
        </Box> */}
      </Box>
    </LinkBox>
  );
}

export function NftCardItem(props: {
  items: Nft | Sft | NftWithToken | SftWithToken;
  size?: "small" | "medium";
  isFullWidh?: boolean;
  disabled?: boolean;
  enableSelectItem?: boolean;
  selectItemHandler?: Dispatch<SetStateAction<string[]>>;
  boxStyle?: BoxProps;
}) {
  const { address, json, creators } = props.items;
  const {
    isFullWidh,
    disabled,
    enableSelectItem,
    selectItemHandler,
    boxStyle,
  } = props;

  const bgColor = useColorModeValue("white", "main.darkblue");
  const textColor = useColorModeValue("gray.500", "darkPurple.300");
  const bgHover = useColorModeValue("gray.50", "main.newDarkBlue");

  const [item, setItem] = useState<Item>();
  const [checked, setChecked] = useState<boolean>(false);
  const [listSells, setListSells] = useState<{
    loaded: boolean;
    sells: Sell[];
  }>({ loaded: false, sells: [] });

  const creator: Creator | null = creators
    ? creators.filter((f) => f.share == 100)[0]
    : null;

  useEffect(() => {
    const getItemDetails = async () => {
      const itemData: any = await API.graphql(
        graphqlOperation(itemByAddress, { address: address.toString() })
      );
      const data = itemData.data.itemByAddress.items;
      if (data.length > 0) {
        setItem(data[0]);
      }
    };

    if (address) {
      getItemDetails();
    }
  }, [address]);

  return (
    <>
      {json && (
        <LinkBox
          as={motion.div}
          layout={"size"}
          width={isFullWidh ? "full" : "auto"}
          rounded="lg"
          position="relative"
          p={checked ? 0 : "1px"}
          shadow={checked ? "glow-blue" : "md"}
          bg={"gradient.blue"}
          transition={"0.25s ease-in-out"}
          transitionProperty={"filter, box-shadow"}
          cursor={!disabled ? "pointer" : "auto"}
          role={!disabled ? "group" : "none"}
          filter={
            checked || disabled || !enableSelectItem
              ? "brightness(100%)"
              : "brightness(70%)"
          }
          _hover={{
            filter: "brightness(100%)",
            shadow: checked ? "glow-blue" : "lg",
          }}
          {...boxStyle}
        >
          <Box
            position={"relative"}
            overflow={"visible"}
            rounded={"lg"}
            bg={bgColor}
            height={"full"}
            transition={"filter 0.25s ease-in-out"}
          >
            <Box width={"full"} overflow={"hidden"} roundedTop="lg">
              <AspectRatio
                ratio={1}
                width={"full"}
                overflow={"hidden"}
                _groupHover={{ transform: "scale(1.05)" }}
                transition={"all 0.25s ease-in-out"}
              >
                {json.image ? (
                  <ChakraImage
                    src={String(json.image)}
                    alt={`Picture of ${json.name}`}
                    objectFit="cover"
                    w={"full"}
                    h={"full"}
                    fallback={<Skeleton w={"full"} h={"full"} />}
                  />
                ) : (
                  <ChakraImage
                    src={"/images/default-image/undefined-image.webp"}
                    alt={`Picture of ${json.name}`}
                    objectFit="cover"
                    w={"full"}
                    h={"full"}
                    fallback={<Skeleton w={"full"} h={"full"} />}
                  />
                )}
              </AspectRatio>
            </Box>
            <Box
              p={3}
              _groupHover={{ bg: bgHover }}
              roundedBottom={"lg"}
              transition={"all 0.25s ease-in-out"}
            >
              <Flex justifyContent="space-between">
                <Box textAlign={"left"} maxWidth={"100%"}>
                  {!disabled && (
                    <LinkOverlay
                      as={NextLink}
                      href={`/item/${address.toString()}`}
                      aria-label={`Read more about ${name}`}
                    ></LinkOverlay>
                  )}
                  <Text
                    fontWeight={"semibold"}
                    fontSize={
                      props.size === undefined
                        ? "sm"
                        : props.size == "small"
                        ? "xs"
                        : "sm"
                    }
                    noOfLines={1}
                  >
                    {json.name}
                  </Text>
                  <Text
                    color={textColor}
                    fontSize="xs"
                    fontWeight="normal"
                    display={"flex"}
                    alignItems={"center"}
                    noOfLines={1}
                  >
                    {item?.owner ? (
                      <Link as={NextLink} href={`/creator/${item.owner.name}`}>
                        {item.owner.name}
                      </Link>
                    ) : (
                      creator && (
                        <Link
                          as={NextLink}
                          href={`/creator/${creator.address.toString() ?? ""}`}
                        >
                          {creator.address.toString().substring(0, 6) +
                            "..." +
                            creator?.address
                              .toString()
                              .substring(
                                creator?.address.toString().length - 6,
                                creator?.address.toString().length
                              )}
                          {/* {creators[1]?.type === "OFFICIAL" && <Verified />} */}
                        </Link>
                      )
                    )}
                  </Text>
                </Box>
              </Flex>
            </Box>
            {enableSelectItem && (
              <Flex
                cursor={"default"}
                alignItems={"center"}
                p={2}
                bg={"rgba(255, 255, 255, 0.35)"}
                backdropFilter={"blur(15px)"}
                rounded={"full"}
                visibility={checked ? "visible" : "hidden"}
                position={"absolute"}
                top={2}
                right={2}
                zIndex={1}
                transform={"all 0.25s ease-in-out"}
                _groupHover={{ visibility: "visible" }}
              >
                <Checkbox
                  cursor={"cell"}
                  size={"lg"}
                  variant={"main"}
                  transform={"all 0.25s ease-in-out"}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    selectItemHandler
                      ? e.target.checked
                        ? selectItemHandler((s) => [...s, address.toString()])
                        : selectItemHandler((s) => [
                            ...s.filter((f) => f !== address.toString()),
                          ])
                      : "";
                  }}
                />
              </Flex>
            )}
          </Box>
        </LinkBox>
      )}
    </>
  );
}

export const CardItemSkeleton = (props: { size?: "small" | "medium" }) => {
  const bgColor = useColorModeValue("white", "main.darkblue");
  const { size } = props;

  return (
    <Box
      as={motion.div}
      layout={"size"}
      maxW="sm"
      rounded="lg"
      position="relative"
      p={"1px"}
      bg={"gradient.blue"}
    >
      <Box position={"relative"} rounded="lg" bg={bgColor} height={"full"}>
        <AspectRatio
          ratio={1}
          width={"full"}
          overflow={"hidden"}
          roundedTop="lg"
        >
          <Skeleton w={"full"} h={"full"} />
        </AspectRatio>
        <Box py={3} px={2}>
          <Flex justifyContent="space-between">
            <Stack p={2} alignItems={"flex-start"} textAlign={"left"}>
              <Skeleton w={size == "small" ? 8 : 12} h={2} />
              <Skeleton w={size == "small" ? 14 : 20} h={2} />
            </Stack>
            <Stack p={2} alignItems={"flex-end"} textAlign={"right"}>
              <Skeleton w={size == "small" ? 8 : 12} h={2} />
              <Skeleton w={size == "small" ? 14 : 20} h={2} />
            </Stack>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export const CardItemSmall = (props: { item: Item }) => {
  const { id, name, imageUrl, creator, collection } = props.item;
  const textColor = useColorModeValue("gray.500", "gray.400");
  const bgHover = useColorModeValue("blackAlpha.50", "blackAlpha.200");

  return (
    <LinkBox
      as={Flex}
      gap={4}
      alignItems={"center"}
      p={2}
      _hover={{ bg: bgHover }}
      pl={4}
    >
      <AspectRatio
        ratio={1}
        w={8}
        h={8}
        overflow={"hidden"}
        roundedTop="sm"
        _groupHover={{ transform: "scale(1.05)" }}
        transition={"all 0.25s ease-in-out"}
      >
        <ChakraImage
          src={
            imageUrl ? imageUrl : "/images/default-image/default-banner-2.webp"
          }
          alt={`Picture of ${name}`}
          objectFit="cover"
          fallback={<Skeleton w={"full"} h={"full"} />}
        />
      </AspectRatio>
      <Box>
        <Text
          fontSize={"sm"}
          fontWeight={"semibold"}
          lineHeight={1.5}
          noOfLines={1}
        >
          {name}
        </Text>
        <Text fontSize={"xs"} lineHeight={1.5} color={textColor}>
          {collection?.name}
        </Text>
      </Box>
      <LinkOverlay
        as={NextLink}
        href={`/item/${id}`}
        aria-label={`Read more about ${name}`}
      />
    </LinkBox>
  );
};

export const CardItemSmallSkeleton = () => {
  return (
    <Flex gap={4} p={2} pl={4} w={"full"} alignItems={"center"}>
      <AspectRatio ratio={1} w={8} h={8} overflow={"hidden"} roundedTop="sm">
        <Skeleton w={"full"} h={"full"} />
      </AspectRatio>
      <Box flexGrow={1} h={"auto"}>
        <SkeletonText noOfLines={2} maxW={"80%"} />
      </Box>
    </Flex>
  );
};

export const EmptyItems = ({ text }: { text?: string }) => {
  return (
    <VStack w={"full"} gap={2}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        rounded={"md"}
        p={2}
        w={12}
        h={12}
        border={"2px dashed"}
        borderColor={"spaceCadet.600"}
        color={"spaceCadet.600"}
      >
        <strong>!</strong>
      </Flex>
      <Text color={"spaceCadet.600"}>
        No {text ? text : "NFTs"} data yet...
      </Text>
    </VStack>
  );
};
