/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type MutationCallbackSignUp = {
  __typename: "MutationCallbackSignUp",
  user?: UserProfile | null,
  errorMessage?: string | null,
};

export type UserProfile = {
  __typename: "UserProfile",
  id: string,
  name: string,
  email?: string | null,
  profilePictureUrl?: string | null,
  profileBannerUrl?: string | null,
  description?: string | null,
  type: UserType,
  collections?: ModelCollectionConnection | null,
  items?: ModelItemConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  wallets?: ModelWalletConnection | null,
  whitelistUser?: ModelWhitelistUserConnection | null,
  executeNFT?: ModelExecuteNFTConnection | null,
};

export enum UserType {
  NORMAL = "NORMAL",
  OFFICIAL = "OFFICIAL",
}


export type ModelCollectionConnection = {
  __typename: "ModelCollectionConnection",
  items:  Array<Collection | null >,
  nextToken?: string | null,
};

export type Collection = {
  __typename: "Collection",
  id: string,
  slug?: string | null,
  name: string,
  description?: string | null,
  thumbnailUrl?: string | null,
  bannerUrl?: string | null,
  metadataUrl?: string | null,
  creatorId: string,
  creator?: UserProfile | null,
  items?: ModelItemConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  address?: string | null,
  status?: CollectionStatus | null,
  startDate?: string | null,
  endDate?: string | null,
  totalItems?: number | null,
  price?:  Array<Price | null > | null,
};

export type ModelItemConnection = {
  __typename: "ModelItemConnection",
  items:  Array<Item | null >,
  nextToken?: string | null,
};

export type Item = {
  __typename: "Item",
  id: string,
  name: string,
  description?: string | null,
  imageUrl: string,
  metadataUrl: string,
  ownerId: string,
  owner?: UserProfile | null,
  creatorId: string,
  creator?: UserProfile | null,
  collectionId: string,
  collection?: Collection | null,
  tradeStateAddress?: string | null,
  recipientAddress?: string | null,
  address?: string | null,
  price?:  Array<Price | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  holdUserId?: string | null,
};

export type Price = {
  __typename: "Price",
  value: number,
  currency?: PriceCurrency | null,
};

export enum PriceCurrency {
  IDR = "IDR",
  SOL = "SOL",
}


export enum CollectionStatus {
  DRAFT = "DRAFT",
  COMING_SOON = "COMING_SOON",
  AVAILABLE = "AVAILABLE",
  ENDED = "ENDED",
  SOLD_OUT = "SOLD_OUT",
}


export type ModelWalletConnection = {
  __typename: "ModelWalletConnection",
  items:  Array<Wallet | null >,
  nextToken?: string | null,
};

export type Wallet = {
  __typename: "Wallet",
  id: string,
  userId: string,
  user?: UserProfile | null,
  currency?: string | null,
  publicKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelWhitelistUserConnection = {
  __typename: "ModelWhitelistUserConnection",
  items:  Array<WhitelistUser | null >,
  nextToken?: string | null,
};

export type WhitelistUser = {
  __typename: "WhitelistUser",
  id: string,
  userId: string,
  user?: UserProfile | null,
  whitelistId: string,
  price?:  Array<Price | null > | null,
  ttl: number,
  createdAt: string,
  updatedAt: string,
};

export type ModelExecuteNFTConnection = {
  __typename: "ModelExecuteNFTConnection",
  items:  Array<ExecuteNFT | null >,
  nextToken?: string | null,
};

export type ExecuteNFT = {
  __typename: "ExecuteNFT",
  id: string,
  orderId: string,
  quantity?: number | null,
  price?: Price | null,
  userId: string,
  user?: UserProfile | null,
  collectionId: string,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type CreatePaymentInput = {
  amount: number,
  itemId: string,
  paymentGateway: PaymentGateway,
  bank?: PaymentBank | null,
  cardRequest?: CardRequest | null,
};

export enum PaymentGateway {
  MIDTRANS = "MIDTRANS",
}


export enum PaymentBank {
  bni = "bni",
  bri = "bri",
  mandiri = "mandiri",
  permata = "permata",
  bca = "bca",
  gopay = "gopay",
  qris = "qris",
  shopeepay = "shopeepay",
  creditCard = "creditCard",
}


export type CardRequest = {
  cardNumber?: string | null,
  cardExpMonth?: string | null,
  cardExpYear?: string | null,
  cardCvv?: string | null,
};

export type MutationCallbackPayment = {
  __typename: "MutationCallbackPayment",
  purchaseId: string,
  amount: number,
  success: boolean,
  bank?: PaymentBank | null,
  virtualAccount?: string | null,
  checkoutUrl?:  Array<CheckoutUrl | null > | null,
  expiredAt?: string | null,
  errorMessage?: string | null,
};

export type CheckoutUrl = {
  __typename: "CheckoutUrl",
  name: string,
  method: string,
  url: string,
};

export type CreateSnapPaymentInput = {
  amount: number,
  itemId: string,
};

export type MutationCallbackPaymentSnap = {
  __typename: "MutationCallbackPaymentSnap",
  token?: string | null,
  success?: boolean | null,
  redirectUrl?: string | null,
  expiredAt?: string | null,
  errorMessage?: string | null,
};

export enum SellStatus {
  ONGOING = "ONGOING",
  END = "END",
}


export type MutationCallbackSell = {
  __typename: "MutationCallbackSell",
  itemId: string,
  errorMessage?: string | null,
};

export enum AuctionMethodType {
  HIGHEST = "HIGHEST",
  DECLINING = "DECLINING",
}


export type CancelStatus = {
  __typename: "CancelStatus",
  success?: boolean | null,
  errorMessage?: string | null,
};

export type SingleItemUploadInput = {
  imageUrl?: string | null,
  name?: string | null,
  externalLink?: string | null,
  description?: string | null,
  collectionId?: string | null,
  properties?: Array< Property | null > | null,
  supply?: number | null,
  price?: PriceInput | null,
};

export type Property = {
  type?: string | null,
  name?: string | null,
};

export type PriceInput = {
  value: number,
  currency?: PriceCurrency | null,
};

export type MutationCallbackUploadNft = {
  __typename: "MutationCallbackUploadNft",
  data?: Item | null,
  errorMessage?: string | null,
};

export type MutationCallbackOffer = {
  __typename: "MutationCallbackOffer",
  itemId: string,
  errorMessage?: string | null,
};

export type MutationCallbackCandyMachine = {
  __typename: "MutationCallbackCandyMachine",
  status?: string | null,
  errorMessage?: string | null,
};

export type MutationCallbackListing = {
  __typename: "MutationCallbackListing",
  userId: string,
  status?: string | null,
  errorMessage?: string | null,
};

export type MutationCallbackWhitelist = {
  __typename: "MutationCallbackWhitelist",
  success?: boolean | null,
  errorMessage?: string | null,
};

export type MutationCallbackWhitelistUser = {
  __typename: "MutationCallbackWhitelistUser",
  success?: boolean | null,
  errorMessage?: string | null,
};

export type MutationCallbackMintingCandy = {
  __typename: "MutationCallbackMintingCandy",
  status?: string | null,
  errorMessage?: string | null,
};

export type MutationCallbackListingSingleItem = {
  __typename: "MutationCallbackListingSingleItem",
  status?: string | null,
  errorMessage?: string | null,
};

export type MutationCallbackBuyNFT = {
  __typename: "MutationCallbackBuyNFT",
  itemId: string,
  address: string,
  status: boolean,
  redirectUrl?: string | null,
  token?: string | null,
  errorMessage?: string | null,
};

export type MutationCallbackGeneral = {
  __typename: "MutationCallbackGeneral",
  status?: string | null,
  errorMessage?: string | null,
};

export type CollectionExecute = {
  collectionId: string,
  quantity: number,
};

export type MutationCallbackExecuteNFT = {
  __typename: "MutationCallbackExecuteNFT",
  success?: boolean | null,
  redirectUrl?: string | null,
  token?: string | null,
  errorMessage?: string | null,
};

export type RemindLaunchpad = {
  __typename: "RemindLaunchpad",
  id: string,
  collectionId: string,
  userId: string,
  ttl: number,
  createdAt: string,
  updatedAt: string,
};

export type MutationCallbackGeneralWithUserPools = {
  __typename: "MutationCallbackGeneralWithUserPools",
  status?: string | null,
  errorMessage?: string | null,
};

export type MutationCallbackDeleteFileByCreator = {
  __typename: "MutationCallbackDeleteFileByCreator",
  success: boolean,
};

export type ItemListing = {
  currency: PriceCurrency,
  itemId: string,
  price: number,
  endDate: string,
};

export type InputCreateCollectionV2 = {
  slug: string,
  name: string,
  description?: string | null,
  thumbnailUrl?: string | null,
  bannerUrl?: string | null,
  metadataUrl?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  price: number,
  currency: PriceCurrency,
  mintingType: MintingType,
};

export enum MintingType {
  GACHA = "GACHA",
  DIRECT_BUY = "DIRECT_BUY",
}


export type MutationCallbackCreateCollectionV2 = {
  __typename: "MutationCallbackCreateCollectionV2",
  collection?: Collection | null,
  errorMessage?: string | null,
};

export type ResponseAsyncStepFunction = {
  __typename: "ResponseAsyncStepFunction",
  success: boolean,
};

export type ResponseBasePayment = {
  __typename: "ResponseBasePayment",
  success: boolean,
  redirectUrl?: string | null,
  token?: string | null,
  errorMessage?: string | null,
};

export type MutationCallbackUpdateTotalItemCollection = {
  __typename: "MutationCallbackUpdateTotalItemCollection",
  collectionId: string,
  totalItems: number,
};

export type MutationCallbackSolTransfer = {
  __typename: "MutationCallbackSolTransfer",
  signature?: string | null,
  errorMessage?: string | null,
};

export type CreateUserProfileInput = {
  id?: string | null,
  name: string,
  email?: string | null,
  profilePictureUrl?: string | null,
  profileBannerUrl?: string | null,
  description?: string | null,
  type: UserType,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelUserProfileConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  profilePictureUrl?: ModelStringInput | null,
  profileBannerUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelUserTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserProfileConditionInput | null > | null,
  or?: Array< ModelUserProfileConditionInput | null > | null,
  not?: ModelUserProfileConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type UpdateUserProfileInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  profilePictureUrl?: string | null,
  profileBannerUrl?: string | null,
  description?: string | null,
  type?: UserType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteUserProfileInput = {
  id: string,
};

export type CreateWhitelistInput = {
  id?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  expiredAt?: string | null,
  price?: Array< PriceInput | null > | null,
};

export type ModelWhitelistConditionInput = {
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  expiredAt?: ModelStringInput | null,
  and?: Array< ModelWhitelistConditionInput | null > | null,
  or?: Array< ModelWhitelistConditionInput | null > | null,
  not?: ModelWhitelistConditionInput | null,
};

export type Whitelist = {
  __typename: "Whitelist",
  id: string,
  startDate?: string | null,
  endDate?: string | null,
  expiredAt?: string | null,
  price?:  Array<Price | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateWhitelistInput = {
  id: string,
  startDate?: string | null,
  endDate?: string | null,
  expiredAt?: string | null,
  price?: Array< PriceInput | null > | null,
};

export type DeleteWhitelistInput = {
  id: string,
};

export type CreateWhitelistUserInput = {
  id?: string | null,
  userId: string,
  whitelistId: string,
  price?: Array< PriceInput | null > | null,
  ttl: number,
};

export type ModelWhitelistUserConditionInput = {
  userId?: ModelIDInput | null,
  whitelistId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelWhitelistUserConditionInput | null > | null,
  or?: Array< ModelWhitelistUserConditionInput | null > | null,
  not?: ModelWhitelistUserConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateWhitelistUserInput = {
  id: string,
  userId?: string | null,
  whitelistId?: string | null,
  price?: Array< PriceInput | null > | null,
  ttl?: number | null,
};

export type DeleteWhitelistUserInput = {
  id: string,
};

export type CreateCollectionInput = {
  id?: string | null,
  slug?: string | null,
  name: string,
  description?: string | null,
  thumbnailUrl?: string | null,
  bannerUrl?: string | null,
  metadataUrl?: string | null,
  creatorId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  address?: string | null,
  status?: CollectionStatus | null,
  startDate?: string | null,
  endDate?: string | null,
  totalItems?: number | null,
  price?: Array< PriceInput | null > | null,
};

export type ModelCollectionConditionInput = {
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  thumbnailUrl?: ModelStringInput | null,
  bannerUrl?: ModelStringInput | null,
  metadataUrl?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  address?: ModelStringInput | null,
  status?: ModelCollectionStatusInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  totalItems?: ModelIntInput | null,
  and?: Array< ModelCollectionConditionInput | null > | null,
  or?: Array< ModelCollectionConditionInput | null > | null,
  not?: ModelCollectionConditionInput | null,
};

export type ModelCollectionStatusInput = {
  eq?: CollectionStatus | null,
  ne?: CollectionStatus | null,
};

export type UpdateCollectionInput = {
  id: string,
  slug?: string | null,
  name?: string | null,
  description?: string | null,
  thumbnailUrl?: string | null,
  bannerUrl?: string | null,
  metadataUrl?: string | null,
  creatorId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  address?: string | null,
  status?: CollectionStatus | null,
  startDate?: string | null,
  endDate?: string | null,
  totalItems?: number | null,
  price?: Array< PriceInput | null > | null,
};

export type DeleteCollectionInput = {
  id: string,
};

export type CreateExecuteNFTInput = {
  id?: string | null,
  orderId: string,
  quantity?: number | null,
  price?: PriceInput | null,
  userId: string,
  collectionId: string,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelExecuteNFTConditionInput = {
  orderId?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  collectionId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelExecuteNFTConditionInput | null > | null,
  or?: Array< ModelExecuteNFTConditionInput | null > | null,
  not?: ModelExecuteNFTConditionInput | null,
};

export type UpdateExecuteNFTInput = {
  id: string,
  orderId?: string | null,
  quantity?: number | null,
  price?: PriceInput | null,
  userId?: string | null,
  collectionId?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteExecuteNFTInput = {
  id: string,
};

export type CreateItemInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  imageUrl: string,
  metadataUrl: string,
  ownerId: string,
  creatorId: string,
  collectionId: string,
  tradeStateAddress?: string | null,
  recipientAddress?: string | null,
  address?: string | null,
  price?: Array< PriceInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  holdUserId?: string | null,
};

export type ModelItemConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  metadataUrl?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  collectionId?: ModelIDInput | null,
  tradeStateAddress?: ModelStringInput | null,
  recipientAddress?: ModelStringInput | null,
  address?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  holdUserId?: ModelStringInput | null,
  and?: Array< ModelItemConditionInput | null > | null,
  or?: Array< ModelItemConditionInput | null > | null,
  not?: ModelItemConditionInput | null,
};

export type UpdateItemInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  imageUrl?: string | null,
  metadataUrl?: string | null,
  ownerId?: string | null,
  creatorId?: string | null,
  collectionId?: string | null,
  tradeStateAddress?: string | null,
  recipientAddress?: string | null,
  address?: string | null,
  price?: Array< PriceInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  holdUserId?: string | null,
};

export type DeleteItemInput = {
  id: string,
};

export type CreateSellInput = {
  id?: string | null,
  itemId: string,
  ownerId: string,
  status?: SellStatus | null,
  price?: Array< PriceInput | null > | null,
  reservedFor?: string | null,
  sellDuration?: DurationInput | null,
  auctionMethod?: AuctionMethodType | null,
  sellingTypes?: SellingMethod | null,
  currency?: PriceCurrency | null,
  reservedPrice?: Array< PriceInput | null > | null,
  endingPrice?: Array< PriceInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DurationInput = {
  startDate?: string | null,
  endDate?: string | null,
};

export enum SellingMethod {
  FIXEDPRICE = "FIXEDPRICE",
  TIMEAUCTION = "TIMEAUCTION",
}


export type ModelSellConditionInput = {
  itemId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  status?: ModelSellStatusInput | null,
  reservedFor?: ModelIDInput | null,
  auctionMethod?: ModelAuctionMethodTypeInput | null,
  sellingTypes?: ModelSellingMethodInput | null,
  currency?: ModelPriceCurrencyInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSellConditionInput | null > | null,
  or?: Array< ModelSellConditionInput | null > | null,
  not?: ModelSellConditionInput | null,
};

export type ModelSellStatusInput = {
  eq?: SellStatus | null,
  ne?: SellStatus | null,
};

export type ModelAuctionMethodTypeInput = {
  eq?: AuctionMethodType | null,
  ne?: AuctionMethodType | null,
};

export type ModelSellingMethodInput = {
  eq?: SellingMethod | null,
  ne?: SellingMethod | null,
};

export type ModelPriceCurrencyInput = {
  eq?: PriceCurrency | null,
  ne?: PriceCurrency | null,
};

export type Sell = {
  __typename: "Sell",
  id: string,
  itemId: string,
  item?: Item | null,
  ownerId: string,
  owner?: UserProfile | null,
  status?: SellStatus | null,
  price?:  Array<Price | null > | null,
  reservedFor?: string | null,
  sellDuration?: Duration | null,
  auctionMethod?: AuctionMethodType | null,
  sellingTypes?: SellingMethod | null,
  currency?: PriceCurrency | null,
  reservedPrice?:  Array<Price | null > | null,
  endingPrice?:  Array<Price | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type Duration = {
  __typename: "Duration",
  startDate?: string | null,
  endDate?: string | null,
};

export type UpdateSellInput = {
  id: string,
  itemId?: string | null,
  ownerId?: string | null,
  status?: SellStatus | null,
  price?: Array< PriceInput | null > | null,
  reservedFor?: string | null,
  sellDuration?: DurationInput | null,
  auctionMethod?: AuctionMethodType | null,
  sellingTypes?: SellingMethod | null,
  currency?: PriceCurrency | null,
  reservedPrice?: Array< PriceInput | null > | null,
  endingPrice?: Array< PriceInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteSellInput = {
  id: string,
};

export type CreateSellDurationInput = {
  id?: string | null,
  itemId: string,
  sellId: string,
  ttl: number,
};

export type ModelSellDurationConditionInput = {
  itemId?: ModelIDInput | null,
  sellId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelSellDurationConditionInput | null > | null,
  or?: Array< ModelSellDurationConditionInput | null > | null,
  not?: ModelSellDurationConditionInput | null,
};

export type SellDuration = {
  __typename: "SellDuration",
  id: string,
  itemId: string,
  sellId: string,
  ttl: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSellDurationInput = {
  id: string,
  itemId?: string | null,
  sellId?: string | null,
  ttl?: number | null,
};

export type DeleteSellDurationInput = {
  id: string,
};

export type CreatePurchaseInput = {
  id?: string | null,
  orderId?: string | null,
  userId: string,
  itemId: string,
  paymentGatewayName: PurchasePaymentGatewayName,
  bank?: string | null,
  amount?: number | null,
  status: PurchaseStatus,
  virtualAccount?: string | null,
  checkoutUrl?: Array< CheckoutUrlInput | null > | null,
  redirectUrl?: string | null,
  metaPaymentNotification?: string | null,
  metaRequestPayment?: string | null,
  metaResponsePayment?: string | null,
  expiredAt?: string | null,
  executeNFTId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  collectionId?: string | null,
  numberOfMintingId?: string | null,
  action?: string | null,
  metaListing?: string | null,
};

export enum PurchasePaymentGatewayName {
  MIDTRANS = "MIDTRANS",
  SOL = "SOL",
}


export enum PurchaseStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
  EXPIRED = "EXPIRED",
}


export type CheckoutUrlInput = {
  name: string,
  method: string,
  url: string,
};

export type ModelPurchaseConditionInput = {
  orderId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  paymentGatewayName?: ModelPurchasePaymentGatewayNameInput | null,
  bank?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  status?: ModelPurchaseStatusInput | null,
  virtualAccount?: ModelStringInput | null,
  redirectUrl?: ModelStringInput | null,
  metaPaymentNotification?: ModelStringInput | null,
  metaRequestPayment?: ModelStringInput | null,
  metaResponsePayment?: ModelStringInput | null,
  expiredAt?: ModelStringInput | null,
  executeNFTId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  collectionId?: ModelStringInput | null,
  numberOfMintingId?: ModelStringInput | null,
  action?: ModelStringInput | null,
  metaListing?: ModelStringInput | null,
  and?: Array< ModelPurchaseConditionInput | null > | null,
  or?: Array< ModelPurchaseConditionInput | null > | null,
  not?: ModelPurchaseConditionInput | null,
};

export type ModelPurchasePaymentGatewayNameInput = {
  eq?: PurchasePaymentGatewayName | null,
  ne?: PurchasePaymentGatewayName | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPurchaseStatusInput = {
  eq?: PurchaseStatus | null,
  ne?: PurchaseStatus | null,
};

export type Purchase = {
  __typename: "Purchase",
  id: string,
  orderId?: string | null,
  userId: string,
  itemId: string,
  paymentGatewayName: PurchasePaymentGatewayName,
  bank?: string | null,
  amount?: number | null,
  status: PurchaseStatus,
  virtualAccount?: string | null,
  checkoutUrl?:  Array<CheckoutUrl | null > | null,
  redirectUrl?: string | null,
  metaPaymentNotification?: string | null,
  metaRequestPayment?: string | null,
  metaResponsePayment?: string | null,
  expiredAt?: string | null,
  executeNFTId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  collectionId?: string | null,
  numberOfMintingId?: string | null,
  action?: string | null,
  metaListing?: string | null,
};

export type UpdatePurchaseInput = {
  id: string,
  orderId?: string | null,
  userId?: string | null,
  itemId?: string | null,
  paymentGatewayName?: PurchasePaymentGatewayName | null,
  bank?: string | null,
  amount?: number | null,
  status?: PurchaseStatus | null,
  virtualAccount?: string | null,
  checkoutUrl?: Array< CheckoutUrlInput | null > | null,
  redirectUrl?: string | null,
  metaPaymentNotification?: string | null,
  metaRequestPayment?: string | null,
  metaResponsePayment?: string | null,
  expiredAt?: string | null,
  executeNFTId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  collectionId?: string | null,
  numberOfMintingId?: string | null,
  action?: string | null,
  metaListing?: string | null,
};

export type DeletePurchaseInput = {
  id: string,
};

export type CreatePurchaseHoldInput = {
  id?: string | null,
  orderId: string,
  ttl: number,
};

export type ModelPurchaseHoldConditionInput = {
  orderId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelPurchaseHoldConditionInput | null > | null,
  or?: Array< ModelPurchaseHoldConditionInput | null > | null,
  not?: ModelPurchaseHoldConditionInput | null,
};

export type PurchaseHold = {
  __typename: "PurchaseHold",
  id: string,
  orderId: string,
  ttl: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePurchaseHoldInput = {
  id: string,
  orderId?: string | null,
  ttl?: number | null,
};

export type DeletePurchaseHoldInput = {
  id: string,
};

export type CreateWalletInput = {
  id?: string | null,
  userId: string,
  currency?: string | null,
  publicKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelWalletConditionInput = {
  userId?: ModelIDInput | null,
  currency?: ModelStringInput | null,
  publicKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWalletConditionInput | null > | null,
  or?: Array< ModelWalletConditionInput | null > | null,
  not?: ModelWalletConditionInput | null,
};

export type UpdateWalletInput = {
  id: string,
  userId?: string | null,
  currency?: string | null,
  publicKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteWalletInput = {
  id: string,
};

export type CreateBidHistoryInput = {
  id?: string | null,
  itemId: string,
  sellId: string,
  auctionMethod?: AuctionMethodType | null,
  status?: SellStatus | null,
  currency?: PriceCurrency | null,
  bidPrice?: PriceInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelBidHistoryConditionInput = {
  itemId?: ModelIDInput | null,
  sellId?: ModelIDInput | null,
  auctionMethod?: ModelAuctionMethodTypeInput | null,
  status?: ModelSellStatusInput | null,
  currency?: ModelPriceCurrencyInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBidHistoryConditionInput | null > | null,
  or?: Array< ModelBidHistoryConditionInput | null > | null,
  not?: ModelBidHistoryConditionInput | null,
};

export type BidHistory = {
  __typename: "BidHistory",
  id: string,
  itemId: string,
  item?: Item | null,
  sellId: string,
  sell?: Sell | null,
  auctionMethod?: AuctionMethodType | null,
  status?: SellStatus | null,
  currency?: PriceCurrency | null,
  bidPrice?: Price | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateBidHistoryInput = {
  id: string,
  itemId?: string | null,
  sellId?: string | null,
  auctionMethod?: AuctionMethodType | null,
  status?: SellStatus | null,
  currency?: PriceCurrency | null,
  bidPrice?: PriceInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteBidHistoryInput = {
  id: string,
};

export type CreateOfferInput = {
  id?: string | null,
  userId: string,
  itemId: string,
  ownerId: string,
  price?: PriceInput | null,
  deposit?: PriceInput | null,
  currency?: PriceCurrency | null,
  quantity?: number | null,
  duration?: DurationInput | null,
  expiredAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelOfferConditionInput = {
  userId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  currency?: ModelPriceCurrencyInput | null,
  quantity?: ModelIntInput | null,
  expiredAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOfferConditionInput | null > | null,
  or?: Array< ModelOfferConditionInput | null > | null,
  not?: ModelOfferConditionInput | null,
};

export type Offer = {
  __typename: "Offer",
  id: string,
  userId: string,
  user?: UserProfile | null,
  itemId: string,
  item?: Item | null,
  ownerId: string,
  owner?: UserProfile | null,
  price?: Price | null,
  deposit?: Price | null,
  currency?: PriceCurrency | null,
  quantity?: number | null,
  duration?: Duration | null,
  expiredAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateOfferInput = {
  id: string,
  userId?: string | null,
  itemId?: string | null,
  ownerId?: string | null,
  price?: PriceInput | null,
  deposit?: PriceInput | null,
  currency?: PriceCurrency | null,
  quantity?: number | null,
  duration?: DurationInput | null,
  expiredAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOfferInput = {
  id: string,
};

export type CreateUserBalanceInput = {
  id?: string | null,
  userId: string,
  deposit?: PriceInput | null,
  balance?: Array< PriceInput | null > | null,
  currency?: PriceCurrency | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelUserBalanceConditionInput = {
  userId?: ModelIDInput | null,
  currency?: ModelPriceCurrencyInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserBalanceConditionInput | null > | null,
  or?: Array< ModelUserBalanceConditionInput | null > | null,
  not?: ModelUserBalanceConditionInput | null,
};

export type UserBalance = {
  __typename: "UserBalance",
  id: string,
  userId: string,
  user?: UserProfile | null,
  deposit?: Price | null,
  balance?:  Array<Price | null > | null,
  currency?: PriceCurrency | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateUserBalanceInput = {
  id: string,
  userId?: string | null,
  deposit?: PriceInput | null,
  balance?: Array< PriceInput | null > | null,
  currency?: PriceCurrency | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteUserBalanceInput = {
  id: string,
};

export type CreateCandyMachineNFTOneInput = {
  id?: string | null,
  candyMachineId?: string | null,
  collectionId?: string | null,
  mintingType?: MintingType | null,
};

export type ModelCandyMachineNFTOneConditionInput = {
  candyMachineId?: ModelStringInput | null,
  collectionId?: ModelStringInput | null,
  mintingType?: ModelMintingTypeInput | null,
  and?: Array< ModelCandyMachineNFTOneConditionInput | null > | null,
  or?: Array< ModelCandyMachineNFTOneConditionInput | null > | null,
  not?: ModelCandyMachineNFTOneConditionInput | null,
};

export type ModelMintingTypeInput = {
  eq?: MintingType | null,
  ne?: MintingType | null,
};

export type CandyMachineNFTOne = {
  __typename: "CandyMachineNFTOne",
  id: string,
  candyMachineId?: string | null,
  collectionId?: string | null,
  mintingType?: MintingType | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCandyMachineNFTOneInput = {
  id: string,
  candyMachineId?: string | null,
  collectionId?: string | null,
  mintingType?: MintingType | null,
};

export type DeleteCandyMachineNFTOneInput = {
  id: string,
};

export type CreateCollectionStatusTtlInput = {
  id?: string | null,
  collectionId?: string | null,
  status?: StatusTtl | null,
  ttl: number,
};

export enum StatusTtl {
  START = "START",
  END = "END",
}


export type ModelCollectionStatusTtlConditionInput = {
  collectionId?: ModelStringInput | null,
  status?: ModelStatusTtlInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelCollectionStatusTtlConditionInput | null > | null,
  or?: Array< ModelCollectionStatusTtlConditionInput | null > | null,
  not?: ModelCollectionStatusTtlConditionInput | null,
};

export type ModelStatusTtlInput = {
  eq?: StatusTtl | null,
  ne?: StatusTtl | null,
};

export type CollectionStatusTtl = {
  __typename: "CollectionStatusTtl",
  id: string,
  collectionId?: string | null,
  status?: StatusTtl | null,
  ttl: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCollectionStatusTtlInput = {
  id: string,
  collectionId?: string | null,
  status?: StatusTtl | null,
  ttl?: number | null,
};

export type DeleteCollectionStatusTtlInput = {
  id: string,
};

export type RedeemCodeResponse = {
  __typename: "RedeemCodeResponse",
  success: boolean,
  errorMessage?: string | null,
};

export type CreateOrderInput = {
  price: number,
  priceCurrency: PriceCurrency,
  action: string,
  details?: Array< CreateOrderDetailInput | null > | null,
};

export type CreateOrderDetailInput = {
  itemId?: string | null,
  collectionId?: string | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  price:  Array<Price >,
  status: PaidStatus,
  userId: string,
  action: OrderAction,
  details?:  Array<OrderDetail | null > | null,
  createdAt: string,
  updatedAt: string,
};

export enum PaidStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
  EXPIRED = "EXPIRED",
}


export enum OrderAction {
  DIRECT_BUY = "DIRECT_BUY",
  DIRECT_BUY_LAUNCHPAD = "DIRECT_BUY_LAUNCHPAD",
  DIRECT_BUY_UNAUTHORIZED = "DIRECT_BUY_UNAUTHORIZED",
  CREATE_LISTING = "CREATE_LISTING",
  CREATE_LAUNCHPAD_GACHA = "CREATE_LAUNCHPAD_GACHA",
  CREATE_LAUNCHPAD_DIRECT_BUY = "CREATE_LAUNCHPAD_DIRECT_BUY",
  MINTING = "MINTING",
}


export type OrderDetail = {
  __typename: "OrderDetail",
  id: string,
  orderId: string,
  userId: string,
  itemId?: string | null,
  item?: Item | null,
  collectionId?: string | null,
  collection?: Collection | null,
  createdAt: string,
  updatedAt: string,
};

export type CreatePaymentV2Input = {
  orderId: string,
  currency: PriceCurrency,
  paymentType: string,
  phoneNumber?: string | null,
  creditCard?: CreditCardDetailInput | null,
};

export type CreditCardDetailInput = {
  cardNumber: string,
  cardCvv: string,
  cardExpMonth: string,
  cardExpYear: string,
};

export type ResponsePaymentV2 = {
  __typename: "ResponsePaymentV2",
  paymentId: string,
  orderId: string,
  amount: number,
  paymentType: string,
  status: string,
  expiredAt: string,
  createdAt: string,
  updatedAt: string,
  actions:  Array<PaymentAction >,
};

export type PaymentAction = {
  __typename: "PaymentAction",
  name: string,
  method: string,
  url: string,
};

export type PurchaseNFTUnauthorizedInput = {
  itemId: string,
  amount: number,
};

export type ResponsePurchaseNFTUnauthorized = {
  __typename: "ResponsePurchaseNFTUnauthorized",
  orderId: string,
  paymentId: string,
  itemId: string,
  amount: number,
  actions:  Array<PaymentAction >,
  expiredAt: string,
  createdAt: string,
  updatedAt: string,
};

export type InputPublishNFTRedeemCode = {
  orderId: string,
  itemId: string,
  itemAddress: string,
  itemName: string,
  itemImageUrl: string,
  redeemCode: string,
  amount: number,
};

export type ResponsePublishNFTRedeemCode = {
  __typename: "ResponsePublishNFTRedeemCode",
  orderId: string,
  itemId: string,
  itemAddress: string,
  itemName: string,
  itemImageUrl: string,
  redeemCode: string,
  amount: number,
};

export type ResponseRedeemCodeNFT = {
  __typename: "ResponseRedeemCodeNFT",
  itemId?: string | null,
  errorMessage?: string | null,
};

export type InputPublishLaunchpadV2 = {
  collectionId: string,
  amount: number,
};

export type ResponseCreateOrder = {
  __typename: "ResponseCreateOrder",
  orderId: string,
  amount: number,
  expiredAt: string,
  createdAt: string,
  updatedAt: string,
};

export type InputMintingLaunchpadV2 = {
  collectionId: string,
  amount: number,
};

export type InputDirectBuyLaunchpadV2 = {
  itemId: string,
  amount: number,
};

export type InputCreateListingNFTV2 = {
  items: Array< InputCreateListingNFTV2Item >,
};

export type InputCreateListingNFTV2Item = {
  id: string,
  price: number,
  endDate: string,
};

export type InputDirectBuyV2 = {
  amount: number,
  itemId: string,
};

export type ResponseCancelOrder = {
  __typename: "ResponseCancelOrder",
  orderId: string,
  success: boolean,
};

export type CallbackParameterStrore = {
  __typename: "CallbackParameterStrore",
  key?: string | null,
  value?: string | null,
};

export type CallbackCheckItemPreOrder = {
  __typename: "CallbackCheckItemPreOrder",
  alreadyOrdered: boolean,
};

export type CallbackRandomItemAvailable = {
  __typename: "CallbackRandomItemAvailable",
  items?:  Array<Item | null > | null,
  errorMessage?: string | null,
};

export type CallbackItemAvailablePreOrder = {
  __typename: "CallbackItemAvailablePreOrder",
  items?: Array< string | null > | null,
  errorMessage?: string | null,
};

export type CallbackCalculateCollectionSize = {
  __typename: "CallbackCalculateCollectionSize",
  totalFile: number,
  size: string,
};

export type CallbackGetAllItemS3 = {
  __typename: "CallbackGetAllItemS3",
  s3Items?:  Array<s3Item | null > | null,
  errorMessage?: string | null,
};

export type s3Item = {
  __typename: "s3Item",
  url?: string | null,
};

export type CallbackGetCandyMachineInfoByCollectionId = {
  __typename: "CallbackGetCandyMachineInfoByCollectionId",
  itemsAvailable?: number | null,
  itemsLoaded?: number | null,
  itemsMinted?: number | null,
  items?:  Array<CandyMachineItem | null > | null,
};

export type CandyMachineItem = {
  __typename: "CandyMachineItem",
  name?: string | null,
  uri?: string | null,
};

export type CallbackGetCandyMachineInfoV2 = {
  __typename: "CallbackGetCandyMachineInfoV2",
  itemsAvailable: number,
  itemsLoaded: number,
  itemsMinted: number,
  mintingType: MintingType,
};

export type ModelUserProfileFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  profilePictureUrl?: ModelStringInput | null,
  profileBannerUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelUserTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserProfileFilterInput | null > | null,
  or?: Array< ModelUserProfileFilterInput | null > | null,
  not?: ModelUserProfileFilterInput | null,
};

export type ModelUserProfileConnection = {
  __typename: "ModelUserProfileConnection",
  items:  Array<UserProfile | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type SearchableUserProfileFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  profilePictureUrl?: SearchableStringFilterInput | null,
  profileBannerUrl?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  and?: Array< SearchableUserProfileFilterInput | null > | null,
  or?: Array< SearchableUserProfileFilterInput | null > | null,
  not?: SearchableUserProfileFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableUserProfileSortInput = {
  field?: SearchableUserProfileSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUserProfileSortableFields {
  id = "id",
  name = "name",
  email = "email",
  profilePictureUrl = "profilePictureUrl",
  profileBannerUrl = "profileBannerUrl",
  description = "description",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableUserProfileAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableUserProfileAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableUserProfileAggregateField {
  id = "id",
  name = "name",
  email = "email",
  profilePictureUrl = "profilePictureUrl",
  profileBannerUrl = "profileBannerUrl",
  description = "description",
  type = "type",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableUserProfileConnection = {
  __typename: "SearchableUserProfileConnection",
  items:  Array<UserProfile | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelWhitelistFilterInput = {
  id?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  expiredAt?: ModelStringInput | null,
  and?: Array< ModelWhitelistFilterInput | null > | null,
  or?: Array< ModelWhitelistFilterInput | null > | null,
  not?: ModelWhitelistFilterInput | null,
};

export type ModelWhitelistConnection = {
  __typename: "ModelWhitelistConnection",
  items:  Array<Whitelist | null >,
  nextToken?: string | null,
};

export type ModelWhitelistUserFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  whitelistId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelWhitelistUserFilterInput | null > | null,
  or?: Array< ModelWhitelistUserFilterInput | null > | null,
  not?: ModelWhitelistUserFilterInput | null,
};

export type SearchableWhitelistUserFilterInput = {
  id?: SearchableIDFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  whitelistId?: SearchableIDFilterInput | null,
  ttl?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableWhitelistUserFilterInput | null > | null,
  or?: Array< SearchableWhitelistUserFilterInput | null > | null,
  not?: SearchableWhitelistUserFilterInput | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableWhitelistUserSortInput = {
  field?: SearchableWhitelistUserSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableWhitelistUserSortableFields {
  id = "id",
  userId = "userId",
  whitelistId = "whitelistId",
  ttl = "ttl",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableWhitelistUserAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableWhitelistUserAggregateField,
};

export enum SearchableWhitelistUserAggregateField {
  id = "id",
  userId = "userId",
  whitelistId = "whitelistId",
  ttl = "ttl",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableWhitelistUserConnection = {
  __typename: "SearchableWhitelistUserConnection",
  items:  Array<WhitelistUser | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelCollectionFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  thumbnailUrl?: ModelStringInput | null,
  bannerUrl?: ModelStringInput | null,
  metadataUrl?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  address?: ModelStringInput | null,
  status?: ModelCollectionStatusInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  totalItems?: ModelIntInput | null,
  and?: Array< ModelCollectionFilterInput | null > | null,
  or?: Array< ModelCollectionFilterInput | null > | null,
  not?: ModelCollectionFilterInput | null,
};

export type SearchableCollectionFilterInput = {
  id?: SearchableIDFilterInput | null,
  slug?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  thumbnailUrl?: SearchableStringFilterInput | null,
  bannerUrl?: SearchableStringFilterInput | null,
  metadataUrl?: SearchableStringFilterInput | null,
  creatorId?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  address?: SearchableStringFilterInput | null,
  startDate?: SearchableStringFilterInput | null,
  endDate?: SearchableStringFilterInput | null,
  totalItems?: SearchableIntFilterInput | null,
  status?: SearchableStringFilterInput | null,
  and?: Array< SearchableCollectionFilterInput | null > | null,
  or?: Array< SearchableCollectionFilterInput | null > | null,
  not?: SearchableCollectionFilterInput | null,
};

export type SearchableCollectionSortInput = {
  field?: SearchableCollectionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCollectionSortableFields {
  id = "id",
  slug = "slug",
  name = "name",
  description = "description",
  thumbnailUrl = "thumbnailUrl",
  bannerUrl = "bannerUrl",
  metadataUrl = "metadataUrl",
  creatorId = "creatorId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  address = "address",
  startDate = "startDate",
  endDate = "endDate",
  totalItems = "totalItems",
}


export type SearchableCollectionAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableCollectionAggregateField,
};

export enum SearchableCollectionAggregateField {
  id = "id",
  slug = "slug",
  name = "name",
  description = "description",
  thumbnailUrl = "thumbnailUrl",
  bannerUrl = "bannerUrl",
  metadataUrl = "metadataUrl",
  creatorId = "creatorId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  address = "address",
  status = "status",
  startDate = "startDate",
  endDate = "endDate",
  totalItems = "totalItems",
}


export type SearchableCollectionConnection = {
  __typename: "SearchableCollectionConnection",
  items:  Array<Collection | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelExecuteNFTFilterInput = {
  id?: ModelIDInput | null,
  orderId?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  collectionId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelExecuteNFTFilterInput | null > | null,
  or?: Array< ModelExecuteNFTFilterInput | null > | null,
  not?: ModelExecuteNFTFilterInput | null,
};

export type SearchableExecuteNFTFilterInput = {
  id?: SearchableIDFilterInput | null,
  orderId?: SearchableIDFilterInput | null,
  quantity?: SearchableIntFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  collectionId?: SearchableIDFilterInput | null,
  ttl?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableExecuteNFTFilterInput | null > | null,
  or?: Array< SearchableExecuteNFTFilterInput | null > | null,
  not?: SearchableExecuteNFTFilterInput | null,
};

export type SearchableExecuteNFTSortInput = {
  field?: SearchableExecuteNFTSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableExecuteNFTSortableFields {
  id = "id",
  orderId = "orderId",
  quantity = "quantity",
  userId = "userId",
  collectionId = "collectionId",
  ttl = "ttl",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableExecuteNFTAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableExecuteNFTAggregateField,
};

export enum SearchableExecuteNFTAggregateField {
  id = "id",
  orderId = "orderId",
  quantity = "quantity",
  userId = "userId",
  collectionId = "collectionId",
  ttl = "ttl",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableExecuteNFTConnection = {
  __typename: "SearchableExecuteNFTConnection",
  items:  Array<ExecuteNFT | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelItemFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  metadataUrl?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  collectionId?: ModelIDInput | null,
  tradeStateAddress?: ModelStringInput | null,
  recipientAddress?: ModelStringInput | null,
  address?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  holdUserId?: ModelStringInput | null,
  and?: Array< ModelItemFilterInput | null > | null,
  or?: Array< ModelItemFilterInput | null > | null,
  not?: ModelItemFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SearchableItemFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  imageUrl?: SearchableStringFilterInput | null,
  metadataUrl?: SearchableStringFilterInput | null,
  ownerId?: SearchableIDFilterInput | null,
  creatorId?: SearchableIDFilterInput | null,
  collectionId?: SearchableIDFilterInput | null,
  tradeStateAddress?: SearchableStringFilterInput | null,
  recipientAddress?: SearchableStringFilterInput | null,
  address?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  holdUserId?: SearchableStringFilterInput | null,
  and?: Array< SearchableItemFilterInput | null > | null,
  or?: Array< SearchableItemFilterInput | null > | null,
  not?: SearchableItemFilterInput | null,
};

export type SearchableItemSortInput = {
  field?: SearchableItemSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableItemSortableFields {
  id = "id",
  name = "name",
  description = "description",
  imageUrl = "imageUrl",
  metadataUrl = "metadataUrl",
  ownerId = "ownerId",
  creatorId = "creatorId",
  collectionId = "collectionId",
  tradeStateAddress = "tradeStateAddress",
  recipientAddress = "recipientAddress",
  address = "address",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  holdUserId = "holdUserId",
}


export type SearchableItemAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableItemAggregateField,
};

export enum SearchableItemAggregateField {
  id = "id",
  name = "name",
  description = "description",
  imageUrl = "imageUrl",
  metadataUrl = "metadataUrl",
  ownerId = "ownerId",
  creatorId = "creatorId",
  collectionId = "collectionId",
  tradeStateAddress = "tradeStateAddress",
  recipientAddress = "recipientAddress",
  address = "address",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  holdUserId = "holdUserId",
}


export type SearchableItemConnection = {
  __typename: "SearchableItemConnection",
  items:  Array<Item | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelSellFilterInput = {
  id?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  status?: ModelSellStatusInput | null,
  reservedFor?: ModelIDInput | null,
  auctionMethod?: ModelAuctionMethodTypeInput | null,
  sellingTypes?: ModelSellingMethodInput | null,
  currency?: ModelPriceCurrencyInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSellFilterInput | null > | null,
  or?: Array< ModelSellFilterInput | null > | null,
  not?: ModelSellFilterInput | null,
};

export type ModelSellConnection = {
  __typename: "ModelSellConnection",
  items:  Array<Sell | null >,
  nextToken?: string | null,
};

export type SearchableSellFilterInput = {
  id?: SearchableIDFilterInput | null,
  itemId?: SearchableIDFilterInput | null,
  ownerId?: SearchableIDFilterInput | null,
  reservedFor?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  auctionMethod?: SearchableStringFilterInput | null,
  sellingTypes?: SearchableStringFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  and?: Array< SearchableSellFilterInput | null > | null,
  or?: Array< SearchableSellFilterInput | null > | null,
  not?: SearchableSellFilterInput | null,
};

export type SearchableSellSortInput = {
  field?: SearchableSellSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSellSortableFields {
  id = "id",
  itemId = "itemId",
  ownerId = "ownerId",
  reservedFor = "reservedFor",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableSellAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableSellAggregateField,
};

export enum SearchableSellAggregateField {
  id = "id",
  itemId = "itemId",
  ownerId = "ownerId",
  status = "status",
  reservedFor = "reservedFor",
  auctionMethod = "auctionMethod",
  sellingTypes = "sellingTypes",
  currency = "currency",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableSellConnection = {
  __typename: "SearchableSellConnection",
  items:  Array<Sell | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelSellDurationFilterInput = {
  id?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  sellId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelSellDurationFilterInput | null > | null,
  or?: Array< ModelSellDurationFilterInput | null > | null,
  not?: ModelSellDurationFilterInput | null,
};

export type ModelSellDurationConnection = {
  __typename: "ModelSellDurationConnection",
  items:  Array<SellDuration | null >,
  nextToken?: string | null,
};

export type ModelPurchaseFilterInput = {
  id?: ModelIDInput | null,
  orderId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  paymentGatewayName?: ModelPurchasePaymentGatewayNameInput | null,
  bank?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  status?: ModelPurchaseStatusInput | null,
  virtualAccount?: ModelStringInput | null,
  redirectUrl?: ModelStringInput | null,
  metaPaymentNotification?: ModelStringInput | null,
  metaRequestPayment?: ModelStringInput | null,
  metaResponsePayment?: ModelStringInput | null,
  expiredAt?: ModelStringInput | null,
  executeNFTId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  collectionId?: ModelStringInput | null,
  numberOfMintingId?: ModelStringInput | null,
  action?: ModelStringInput | null,
  metaListing?: ModelStringInput | null,
  and?: Array< ModelPurchaseFilterInput | null > | null,
  or?: Array< ModelPurchaseFilterInput | null > | null,
  not?: ModelPurchaseFilterInput | null,
};

export type ModelPurchaseConnection = {
  __typename: "ModelPurchaseConnection",
  items:  Array<Purchase | null >,
  nextToken?: string | null,
};

export type ModelPurchaseHoldFilterInput = {
  id?: ModelIDInput | null,
  orderId?: ModelIDInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelPurchaseHoldFilterInput | null > | null,
  or?: Array< ModelPurchaseHoldFilterInput | null > | null,
  not?: ModelPurchaseHoldFilterInput | null,
};

export type ModelPurchaseHoldConnection = {
  __typename: "ModelPurchaseHoldConnection",
  items:  Array<PurchaseHold | null >,
  nextToken?: string | null,
};

export type ModelWalletFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  currency?: ModelStringInput | null,
  publicKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWalletFilterInput | null > | null,
  or?: Array< ModelWalletFilterInput | null > | null,
  not?: ModelWalletFilterInput | null,
};

export type SearchableWalletFilterInput = {
  id?: SearchableIDFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  publicKey?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableWalletFilterInput | null > | null,
  or?: Array< SearchableWalletFilterInput | null > | null,
  not?: SearchableWalletFilterInput | null,
};

export type SearchableWalletSortInput = {
  field?: SearchableWalletSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableWalletSortableFields {
  id = "id",
  userId = "userId",
  currency = "currency",
  publicKey = "publicKey",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableWalletAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableWalletAggregateField,
};

export enum SearchableWalletAggregateField {
  id = "id",
  userId = "userId",
  currency = "currency",
  publicKey = "publicKey",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableWalletConnection = {
  __typename: "SearchableWalletConnection",
  items:  Array<Wallet | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelBidHistoryFilterInput = {
  id?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  sellId?: ModelIDInput | null,
  auctionMethod?: ModelAuctionMethodTypeInput | null,
  status?: ModelSellStatusInput | null,
  currency?: ModelPriceCurrencyInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBidHistoryFilterInput | null > | null,
  or?: Array< ModelBidHistoryFilterInput | null > | null,
  not?: ModelBidHistoryFilterInput | null,
};

export type ModelBidHistoryConnection = {
  __typename: "ModelBidHistoryConnection",
  items:  Array<BidHistory | null >,
  nextToken?: string | null,
};

export type SearchableBidHistoryFilterInput = {
  id?: SearchableIDFilterInput | null,
  itemId?: SearchableIDFilterInput | null,
  sellId?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  auctionMethod?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  and?: Array< SearchableBidHistoryFilterInput | null > | null,
  or?: Array< SearchableBidHistoryFilterInput | null > | null,
  not?: SearchableBidHistoryFilterInput | null,
};

export type SearchableBidHistorySortInput = {
  field?: SearchableBidHistorySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBidHistorySortableFields {
  id = "id",
  itemId = "itemId",
  sellId = "sellId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableBidHistoryAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableBidHistoryAggregateField,
};

export enum SearchableBidHistoryAggregateField {
  id = "id",
  itemId = "itemId",
  sellId = "sellId",
  auctionMethod = "auctionMethod",
  status = "status",
  currency = "currency",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableBidHistoryConnection = {
  __typename: "SearchableBidHistoryConnection",
  items:  Array<BidHistory | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelOfferFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  currency?: ModelPriceCurrencyInput | null,
  quantity?: ModelIntInput | null,
  expiredAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOfferFilterInput | null > | null,
  or?: Array< ModelOfferFilterInput | null > | null,
  not?: ModelOfferFilterInput | null,
};

export type ModelOfferConnection = {
  __typename: "ModelOfferConnection",
  items:  Array<Offer | null >,
  nextToken?: string | null,
};

export type ModelUserBalanceFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  currency?: ModelPriceCurrencyInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserBalanceFilterInput | null > | null,
  or?: Array< ModelUserBalanceFilterInput | null > | null,
  not?: ModelUserBalanceFilterInput | null,
};

export type ModelUserBalanceConnection = {
  __typename: "ModelUserBalanceConnection",
  items:  Array<UserBalance | null >,
  nextToken?: string | null,
};

export type ModelCandyMachineNFTOneFilterInput = {
  id?: ModelIDInput | null,
  candyMachineId?: ModelStringInput | null,
  collectionId?: ModelStringInput | null,
  mintingType?: ModelMintingTypeInput | null,
  and?: Array< ModelCandyMachineNFTOneFilterInput | null > | null,
  or?: Array< ModelCandyMachineNFTOneFilterInput | null > | null,
  not?: ModelCandyMachineNFTOneFilterInput | null,
};

export type ModelCandyMachineNFTOneConnection = {
  __typename: "ModelCandyMachineNFTOneConnection",
  items:  Array<CandyMachineNFTOne | null >,
  nextToken?: string | null,
};

export type SearchableCandyMachineNFTOneFilterInput = {
  id?: SearchableIDFilterInput | null,
  candyMachineId?: SearchableStringFilterInput | null,
  collectionId?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  mintingType?: SearchableStringFilterInput | null,
  and?: Array< SearchableCandyMachineNFTOneFilterInput | null > | null,
  or?: Array< SearchableCandyMachineNFTOneFilterInput | null > | null,
  not?: SearchableCandyMachineNFTOneFilterInput | null,
};

export type SearchableCandyMachineNFTOneSortInput = {
  field?: SearchableCandyMachineNFTOneSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCandyMachineNFTOneSortableFields {
  id = "id",
  candyMachineId = "candyMachineId",
  collectionId = "collectionId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableCandyMachineNFTOneAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableCandyMachineNFTOneAggregateField,
};

export enum SearchableCandyMachineNFTOneAggregateField {
  id = "id",
  candyMachineId = "candyMachineId",
  collectionId = "collectionId",
  mintingType = "mintingType",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableCandyMachineNFTOneConnection = {
  __typename: "SearchableCandyMachineNFTOneConnection",
  items:  Array<CandyMachineNFTOne | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelCollectionStatusTtlFilterInput = {
  id?: ModelIDInput | null,
  collectionId?: ModelStringInput | null,
  status?: ModelStatusTtlInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelCollectionStatusTtlFilterInput | null > | null,
  or?: Array< ModelCollectionStatusTtlFilterInput | null > | null,
  not?: ModelCollectionStatusTtlFilterInput | null,
};

export type ModelCollectionStatusTtlConnection = {
  __typename: "ModelCollectionStatusTtlConnection",
  items:  Array<CollectionStatusTtl | null >,
  nextToken?: string | null,
};

export type ModelRemindLaunchpadFilterInput = {
  id?: ModelIDInput | null,
  collectionId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelRemindLaunchpadFilterInput | null > | null,
  or?: Array< ModelRemindLaunchpadFilterInput | null > | null,
  not?: ModelRemindLaunchpadFilterInput | null,
};

export type ModelRemindLaunchpadConnection = {
  __typename: "ModelRemindLaunchpadConnection",
  items:  Array<RemindLaunchpad | null >,
  nextToken?: string | null,
};

export type CandyMachineStatus = {
  __typename: "CandyMachineStatus",
  id: string,
  collectionId: string,
  status: string,
  startDate: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCandyMachineStatusFilterInput = {
  id?: ModelIDInput | null,
  collectionId?: ModelStringInput | null,
  status?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCandyMachineStatusFilterInput | null > | null,
  or?: Array< ModelCandyMachineStatusFilterInput | null > | null,
  not?: ModelCandyMachineStatusFilterInput | null,
};

export type ModelCandyMachineStatusConnection = {
  __typename: "ModelCandyMachineStatusConnection",
  items:  Array<CandyMachineStatus | null >,
  nextToken?: string | null,
};

export type NumberOfMinting = {
  __typename: "NumberOfMinting",
  id: string,
  collectionId: string,
  status?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelNumberOfMintingFilterInput = {
  id?: ModelIDInput | null,
  collectionId?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelNumberOfMintingFilterInput | null > | null,
  or?: Array< ModelNumberOfMintingFilterInput | null > | null,
  not?: ModelNumberOfMintingFilterInput | null,
};

export type ModelNumberOfMintingConnection = {
  __typename: "ModelNumberOfMintingConnection",
  items:  Array<NumberOfMinting | null >,
  nextToken?: string | null,
};

export type ItemListingTtl = {
  __typename: "ItemListingTtl",
  id?: string | null,
  itemId?: string | null,
  currency?: string | null,
  price?: number | null,
  status?: StatusTtl | null,
  expiredDate?: string | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelItemListingTtlFilterInput = {
  id?: ModelIDInput | null,
  itemId?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  status?: ModelStatusTtlInput | null,
  expiredDate?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelItemListingTtlFilterInput | null > | null,
  or?: Array< ModelItemListingTtlFilterInput | null > | null,
  not?: ModelItemListingTtlFilterInput | null,
};

export type ModelItemListingTtlConnection = {
  __typename: "ModelItemListingTtlConnection",
  items:  Array<ItemListingTtl | null >,
  nextToken?: string | null,
};

export type RedeemCode = {
  __typename: "RedeemCode",
  id: string,
  code: string,
  amount: number,
  isActive: string,
  action: string,
  createdAt: string,
  updatedAt: string,
};

export type ResponseGetAppConfig = {
  __typename: "ResponseGetAppConfig",
  value: string,
};

export type ResponseGetOrderDetail = {
  __typename: "ResponseGetOrderDetail",
  orderId: string,
  userId: string,
  itemDetails:  Array<ItemOrderDetail >,
  tax:  Array<ItemTaxOrderDetail >,
  status: string,
  action: string,
  totalIDR: number,
  totalSOL: number,
  expiredAt: string,
  createdAt: string,
  updatedAt: string,
};

export type ItemOrderDetail = {
  __typename: "ItemOrderDetail",
  id: string,
  name: string,
  imageUrl: string,
  price:  Array<ItemPriceOrderDetail >,
};

export type ItemPriceOrderDetail = {
  __typename: "ItemPriceOrderDetail",
  amount: number,
  currency: PriceCurrency,
};

export type ItemTaxOrderDetail = {
  __typename: "ItemTaxOrderDetail",
  taxName: string,
  amount: number,
  currency: PriceCurrency,
};

export type ResponseGetPaymentDetail = {
  __typename: "ResponseGetPaymentDetail",
  paymentId: string,
  orderId: string,
  userId: string,
  amount: number,
  currency: PriceCurrency,
  paymentType: string,
  status: string,
  expiredAt: string,
  createdAt: string,
  updatedAt: string,
  orderType: string,
  itemDetails:  Array<ItemOrderDetail >,
  tax:  Array<ItemTaxOrderDetail >,
  actions:  Array<PaymentAction >,
};

export type ResponseGetRateSolanaIDR = {
  __typename: "ResponseGetRateSolanaIDR",
  value: number,
};

export type ModelSubscriptionUserProfileFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  profilePictureUrl?: ModelSubscriptionStringInput | null,
  profileBannerUrl?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserProfileFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserProfileFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionWhitelistFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  expiredAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWhitelistFilterInput | null > | null,
  or?: Array< ModelSubscriptionWhitelistFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionWhitelistUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  whitelistId?: ModelSubscriptionIDInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionWhitelistUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionWhitelistUserFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionCollectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  thumbnailUrl?: ModelSubscriptionStringInput | null,
  bannerUrl?: ModelSubscriptionStringInput | null,
  metadataUrl?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  totalItems?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionCollectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionCollectionFilterInput | null > | null,
};

export type ModelSubscriptionExecuteNFTFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  orderId?: ModelSubscriptionIDInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  userId?: ModelSubscriptionIDInput | null,
  collectionId?: ModelSubscriptionIDInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionExecuteNFTFilterInput | null > | null,
  or?: Array< ModelSubscriptionExecuteNFTFilterInput | null > | null,
};

export type ModelSubscriptionItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  metadataUrl?: ModelSubscriptionStringInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  collectionId?: ModelSubscriptionIDInput | null,
  tradeStateAddress?: ModelSubscriptionStringInput | null,
  recipientAddress?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  holdUserId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionItemFilterInput | null > | null,
};

export type ModelSubscriptionSellFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  itemId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  reservedFor?: ModelSubscriptionIDInput | null,
  auctionMethod?: ModelSubscriptionStringInput | null,
  sellingTypes?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSellFilterInput | null > | null,
  or?: Array< ModelSubscriptionSellFilterInput | null > | null,
};

export type ModelSubscriptionSellDurationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  itemId?: ModelSubscriptionIDInput | null,
  sellId?: ModelSubscriptionIDInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionSellDurationFilterInput | null > | null,
  or?: Array< ModelSubscriptionSellDurationFilterInput | null > | null,
};

export type ModelSubscriptionPurchaseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  orderId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  itemId?: ModelSubscriptionIDInput | null,
  paymentGatewayName?: ModelSubscriptionStringInput | null,
  bank?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  virtualAccount?: ModelSubscriptionStringInput | null,
  redirectUrl?: ModelSubscriptionStringInput | null,
  metaPaymentNotification?: ModelSubscriptionStringInput | null,
  metaRequestPayment?: ModelSubscriptionStringInput | null,
  metaResponsePayment?: ModelSubscriptionStringInput | null,
  expiredAt?: ModelSubscriptionStringInput | null,
  executeNFTId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  collectionId?: ModelSubscriptionStringInput | null,
  numberOfMintingId?: ModelSubscriptionStringInput | null,
  action?: ModelSubscriptionStringInput | null,
  metaListing?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPurchaseFilterInput | null > | null,
  or?: Array< ModelSubscriptionPurchaseFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionPurchaseHoldFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  orderId?: ModelSubscriptionIDInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionPurchaseHoldFilterInput | null > | null,
  or?: Array< ModelSubscriptionPurchaseHoldFilterInput | null > | null,
};

export type ModelSubscriptionWalletFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  currency?: ModelSubscriptionStringInput | null,
  publicKey?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWalletFilterInput | null > | null,
  or?: Array< ModelSubscriptionWalletFilterInput | null > | null,
};

export type ModelSubscriptionBidHistoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  itemId?: ModelSubscriptionIDInput | null,
  sellId?: ModelSubscriptionIDInput | null,
  auctionMethod?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBidHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionBidHistoryFilterInput | null > | null,
};

export type ModelSubscriptionOfferFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  itemId?: ModelSubscriptionIDInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  currency?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  expiredAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOfferFilterInput | null > | null,
  or?: Array< ModelSubscriptionOfferFilterInput | null > | null,
};

export type ModelSubscriptionUserBalanceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  currency?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserBalanceFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserBalanceFilterInput | null > | null,
};

export type ModelSubscriptionCandyMachineNFTOneFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  candyMachineId?: ModelSubscriptionStringInput | null,
  collectionId?: ModelSubscriptionStringInput | null,
  mintingType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCandyMachineNFTOneFilterInput | null > | null,
  or?: Array< ModelSubscriptionCandyMachineNFTOneFilterInput | null > | null,
};

export type ModelSubscriptionCollectionStatusTtlFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  collectionId?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionCollectionStatusTtlFilterInput | null > | null,
  or?: Array< ModelSubscriptionCollectionStatusTtlFilterInput | null > | null,
};

export type SignUpMutationVariables = {
  name: string,
  email: string,
  password: string,
};

export type SignUpMutation = {
  signUp?:  {
    __typename: "MutationCallbackSignUp",
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type CreatePaymentMutationVariables = {
  input?: CreatePaymentInput | null,
};

export type CreatePaymentMutation = {
  createPayment:  {
    __typename: "MutationCallbackPayment",
    purchaseId: string,
    amount: number,
    success: boolean,
    bank?: PaymentBank | null,
    virtualAccount?: string | null,
    checkoutUrl?:  Array< {
      __typename: "CheckoutUrl",
      name: string,
      method: string,
      url: string,
    } | null > | null,
    expiredAt?: string | null,
    errorMessage?: string | null,
  },
};

export type CreatePaymentSnapMutationVariables = {
  input?: CreateSnapPaymentInput | null,
};

export type CreatePaymentSnapMutation = {
  createPaymentSnap:  {
    __typename: "MutationCallbackPaymentSnap",
    token?: string | null,
    success?: boolean | null,
    redirectUrl?: string | null,
    expiredAt?: string | null,
    errorMessage?: string | null,
  },
};

export type CreateSellFixedMutationVariables = {
  itemId: string,
  price: number,
  status: SellStatus,
  currency: PriceCurrency,
  startDate: string,
  endDate: string,
  reservedFor?: string | null,
};

export type CreateSellFixedMutation = {
  createSellFixed:  {
    __typename: "MutationCallbackSell",
    itemId: string,
    errorMessage?: string | null,
  },
};

export type CreateSellAuctionMutationVariables = {
  itemId: string,
  method: AuctionMethodType,
  price: number,
  status: SellStatus,
  currency: PriceCurrency,
  endingPrice?: number | null,
  reservedPrice?: number | null,
  startDate: string,
  endDate: string,
};

export type CreateSellAuctionMutation = {
  createSellAuction:  {
    __typename: "MutationCallbackSell",
    itemId: string,
    errorMessage?: string | null,
  },
};

export type CancelSellsMutationVariables = {
  sellId?: Array< string > | null,
};

export type CancelSellsMutation = {
  cancelSells:  {
    __typename: "CancelStatus",
    success?: boolean | null,
    errorMessage?: string | null,
  },
};

export type UploadNftMutationVariables = {
  input?: SingleItemUploadInput | null,
};

export type UploadNftMutation = {
  uploadNft?:  {
    __typename: "MutationCallbackUploadNft",
    data?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type CreateOfferItemMutationVariables = {
  itemId: string,
  price: number,
  currency: PriceCurrency,
  quantity: number,
  deposit?: number | null,
  startDate: string,
  endDate: string,
};

export type CreateOfferItemMutation = {
  createOfferItem:  {
    __typename: "MutationCallbackOffer",
    itemId: string,
    errorMessage?: string | null,
  },
};

export type CandyMachineMutationVariables = {
  collectionId?: string | null,
  creatorId?: string | null,
};

export type CandyMachineMutation = {
  candyMachine:  {
    __typename: "MutationCallbackCandyMachine",
    status?: string | null,
    errorMessage?: string | null,
  },
};

export type CreateListingMutationVariables = {
  userId: string,
  price?: number | null,
  limit: number,
};

export type CreateListingMutation = {
  createListing:  {
    __typename: "MutationCallbackListing",
    userId: string,
    status?: string | null,
    errorMessage?: string | null,
  },
};

export type CreateWhitelistDateMutationVariables = {
  startDate?: string | null,
  endDate?: string | null,
  currency: PriceCurrency,
  price: number,
};

export type CreateWhitelistDateMutation = {
  createWhitelistDate:  {
    __typename: "MutationCallbackWhitelist",
    success?: boolean | null,
    errorMessage?: string | null,
  },
};

export type CreateWhitelistUserStreamMutationVariables = {
  userId?: string | null,
  whitelistId?: string | null,
  price?: number | null,
  currency?: PriceCurrency | null,
};

export type CreateWhitelistUserStreamMutation = {
  createWhitelistUserStream:  {
    __typename: "MutationCallbackWhitelistUser",
    success?: boolean | null,
    errorMessage?: string | null,
  },
};

export type MintingCandyMutationVariables = {
  candyMachineAddress?: string | null,
  userId?: string | null,
  numberOfMinting?: number | null,
};

export type MintingCandyMutation = {
  mintingCandy?:  {
    __typename: "MutationCallbackMintingCandy",
    status?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type CreateListingSingleItemMutationVariables = {
  currency: string,
  itemId: string,
  price: number,
  endDate: string,
};

export type CreateListingSingleItemMutation = {
  createListingSingleItem?:  {
    __typename: "MutationCallbackListingSingleItem",
    status?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type CreateBuyNFTMutationVariables = {
  itemId: string,
  amount: number,
};

export type CreateBuyNFTMutation = {
  createBuyNFT?:  {
    __typename: "MutationCallbackBuyNFT",
    itemId: string,
    address: string,
    status: boolean,
    redirectUrl?: string | null,
    token?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type CreateCollectionByCollectionIdMutationVariables = {
  collectionId: string,
};

export type CreateCollectionByCollectionIdMutation = {
  createCollectionByCollectionId?:  {
    __typename: "MutationCallbackMintingCandy",
    status?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type GeneratePriceMutationVariables = {
  collectionId?: string | null,
  price?: number | null,
};

export type GeneratePriceMutation = {
  generatePrice?:  {
    __typename: "MutationCallbackGeneral",
    status?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type CreateExecuteBuyMutationVariables = {
  currency: PriceCurrency,
  amount: number,
  collection: Array< CollectionExecute | null >,
};

export type CreateExecuteBuyMutation = {
  createExecuteBuy?:  {
    __typename: "MutationCallbackExecuteNFT",
    success?: boolean | null,
    redirectUrl?: string | null,
    token?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type GenerateItemMutationVariables = {
  name: string,
  description: string,
  url: string,
  creatorId: string,
  collectionId: string,
  startNo: number,
  endNo: number,
};

export type GenerateItemMutation = {
  generateItem?:  {
    __typename: "MutationCallbackGeneral",
    status?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type CreateRemindLaunchpadMutationVariables = {
  collectionId: string,
};

export type CreateRemindLaunchpadMutation = {
  createRemindLaunchpad:  {
    __typename: "RemindLaunchpad",
    id: string,
    collectionId: string,
    userId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  },
};

export type ChangeImageUrlJsonFileMutationVariables = {
  prefix?: string | null,
};

export type ChangeImageUrlJsonFileMutation = {
  changeImageUrlJsonFile?:  {
    __typename: "MutationCallbackGeneral",
    status?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type GenerateItemByS3PrefixMutationVariables = {
  prefixJson?: string | null,
};

export type GenerateItemByS3PrefixMutation = {
  generateItemByS3Prefix?:  {
    __typename: "MutationCallbackGeneralWithUserPools",
    status?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type LaunchpadMintingMutationVariables = {
  collectionId: string,
  ccurrency: PriceCurrency,
  amount: number,
};

export type LaunchpadMintingMutation = {
  launchpadMinting?:  {
    __typename: "MutationCallbackExecuteNFT",
    success?: boolean | null,
    redirectUrl?: string | null,
    token?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type DeleteFileByCreatorMutationVariables = {
  key: string,
};

export type DeleteFileByCreatorMutation = {
  deleteFileByCreator:  {
    __typename: "MutationCallbackDeleteFileByCreator",
    success: boolean,
  },
};

export type ListingItemsMutationVariables = {
  purchaseCurrency: PriceCurrency,
  amount: number,
  data: Array< ItemListing | null >,
};

export type ListingItemsMutation = {
  listingItems?:  {
    __typename: "MutationCallbackExecuteNFT",
    success?: boolean | null,
    redirectUrl?: string | null,
    token?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type CancelListingMutationVariables = {
  itemId: string,
};

export type CancelListingMutation = {
  cancelListing?:  {
    __typename: "MutationCallbackGeneralWithUserPools",
    status?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type CreateCollectionV2MutationVariables = {
  input: InputCreateCollectionV2,
};

export type CreateCollectionV2Mutation = {
  createCollectionV2:  {
    __typename: "MutationCallbackCreateCollectionV2",
    collection?:  {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null,
    errorMessage?: string | null,
  },
};

export type CalculateTotalItemCollectionMutationVariables = {
  collectionId: string,
};

export type CalculateTotalItemCollectionMutation = {
  calculateTotalItemCollection:  {
    __typename: "ResponseAsyncStepFunction",
    success: boolean,
  },
};

export type PublishLaunchpadMutationVariables = {
  collectionId: string,
  amount: number,
  currency: PriceCurrency,
};

export type PublishLaunchpadMutation = {
  publishLaunchpad:  {
    __typename: "ResponseBasePayment",
    success: boolean,
    redirectUrl?: string | null,
    token?: string | null,
    errorMessage?: string | null,
  },
};

export type UpdateTotalItemCollectionMutationVariables = {
  collectionId: string,
  totalItem: number,
};

export type UpdateTotalItemCollectionMutation = {
  updateTotalItemCollection?:  {
    __typename: "MutationCallbackUpdateTotalItemCollection",
    collectionId: string,
    totalItems: number,
  } | null,
};

export type BuyDirectFromLaunchpadMutationVariables = {
  itemId: string,
  amount: number,
  currency: PriceCurrency,
};

export type BuyDirectFromLaunchpadMutation = {
  buyDirectFromLaunchpad:  {
    __typename: "ResponseBasePayment",
    success: boolean,
    redirectUrl?: string | null,
    token?: string | null,
    errorMessage?: string | null,
  },
};

export type SolTransferMutationVariables = {
  amount: number,
  to: string,
};

export type SolTransferMutation = {
  solTransfer?:  {
    __typename: "MutationCallbackSolTransfer",
    signature?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type DirectBuyUnauthorizedMutationVariables = {
  itemId: string,
  amount: number,
  currency: PriceCurrency,
};

export type DirectBuyUnauthorizedMutation = {
  directBuyUnauthorized:  {
    __typename: "ResponseBasePayment",
    success: boolean,
    redirectUrl?: string | null,
    token?: string | null,
    errorMessage?: string | null,
  },
};

export type CreateUserProfileMutationVariables = {
  input: CreateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type CreateUserProfileMutation = {
  createUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    name: string,
    email?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    description?: string | null,
    type: UserType,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    wallets?:  {
      __typename: "ModelWalletConnection",
      items:  Array< {
        __typename: "Wallet",
        id: string,
        userId: string,
        currency?: string | null,
        publicKey?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    whitelistUser?:  {
      __typename: "ModelWhitelistUserConnection",
      items:  Array< {
        __typename: "WhitelistUser",
        id: string,
        userId: string,
        whitelistId: string,
        ttl: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    executeNFT?:  {
      __typename: "ModelExecuteNFTConnection",
      items:  Array< {
        __typename: "ExecuteNFT",
        id: string,
        orderId: string,
        quantity?: number | null,
        userId: string,
        collectionId: string,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type UpdateUserProfileMutation = {
  updateUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    name: string,
    email?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    description?: string | null,
    type: UserType,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    wallets?:  {
      __typename: "ModelWalletConnection",
      items:  Array< {
        __typename: "Wallet",
        id: string,
        userId: string,
        currency?: string | null,
        publicKey?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    whitelistUser?:  {
      __typename: "ModelWhitelistUserConnection",
      items:  Array< {
        __typename: "WhitelistUser",
        id: string,
        userId: string,
        whitelistId: string,
        ttl: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    executeNFT?:  {
      __typename: "ModelExecuteNFTConnection",
      items:  Array< {
        __typename: "ExecuteNFT",
        id: string,
        orderId: string,
        quantity?: number | null,
        userId: string,
        collectionId: string,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserProfileMutationVariables = {
  input: DeleteUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type DeleteUserProfileMutation = {
  deleteUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    name: string,
    email?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    description?: string | null,
    type: UserType,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    wallets?:  {
      __typename: "ModelWalletConnection",
      items:  Array< {
        __typename: "Wallet",
        id: string,
        userId: string,
        currency?: string | null,
        publicKey?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    whitelistUser?:  {
      __typename: "ModelWhitelistUserConnection",
      items:  Array< {
        __typename: "WhitelistUser",
        id: string,
        userId: string,
        whitelistId: string,
        ttl: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    executeNFT?:  {
      __typename: "ModelExecuteNFTConnection",
      items:  Array< {
        __typename: "ExecuteNFT",
        id: string,
        orderId: string,
        quantity?: number | null,
        userId: string,
        collectionId: string,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateWhitelistMutationVariables = {
  input: CreateWhitelistInput,
  condition?: ModelWhitelistConditionInput | null,
};

export type CreateWhitelistMutation = {
  createWhitelist?:  {
    __typename: "Whitelist",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    expiredAt?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWhitelistMutationVariables = {
  input: UpdateWhitelistInput,
  condition?: ModelWhitelistConditionInput | null,
};

export type UpdateWhitelistMutation = {
  updateWhitelist?:  {
    __typename: "Whitelist",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    expiredAt?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWhitelistMutationVariables = {
  input: DeleteWhitelistInput,
  condition?: ModelWhitelistConditionInput | null,
};

export type DeleteWhitelistMutation = {
  deleteWhitelist?:  {
    __typename: "Whitelist",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    expiredAt?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWhitelistUserMutationVariables = {
  input: CreateWhitelistUserInput,
  condition?: ModelWhitelistUserConditionInput | null,
};

export type CreateWhitelistUserMutation = {
  createWhitelistUser?:  {
    __typename: "WhitelistUser",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    whitelistId: string,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWhitelistUserMutationVariables = {
  input: UpdateWhitelistUserInput,
  condition?: ModelWhitelistUserConditionInput | null,
};

export type UpdateWhitelistUserMutation = {
  updateWhitelistUser?:  {
    __typename: "WhitelistUser",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    whitelistId: string,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWhitelistUserMutationVariables = {
  input: DeleteWhitelistUserInput,
  condition?: ModelWhitelistUserConditionInput | null,
};

export type DeleteWhitelistUserMutation = {
  deleteWhitelistUser?:  {
    __typename: "WhitelistUser",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    whitelistId: string,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCollectionMutationVariables = {
  input: CreateCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type CreateCollectionMutation = {
  createCollection?:  {
    __typename: "Collection",
    id: string,
    slug?: string | null,
    name: string,
    description?: string | null,
    thumbnailUrl?: string | null,
    bannerUrl?: string | null,
    metadataUrl?: string | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    address?: string | null,
    status?: CollectionStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    totalItems?: number | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
  } | null,
};

export type UpdateCollectionMutationVariables = {
  input: UpdateCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type UpdateCollectionMutation = {
  updateCollection?:  {
    __typename: "Collection",
    id: string,
    slug?: string | null,
    name: string,
    description?: string | null,
    thumbnailUrl?: string | null,
    bannerUrl?: string | null,
    metadataUrl?: string | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    address?: string | null,
    status?: CollectionStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    totalItems?: number | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
  } | null,
};

export type DeleteCollectionMutationVariables = {
  input: DeleteCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type DeleteCollectionMutation = {
  deleteCollection?:  {
    __typename: "Collection",
    id: string,
    slug?: string | null,
    name: string,
    description?: string | null,
    thumbnailUrl?: string | null,
    bannerUrl?: string | null,
    metadataUrl?: string | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    address?: string | null,
    status?: CollectionStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    totalItems?: number | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
  } | null,
};

export type CreateExecuteNFTMutationVariables = {
  input: CreateExecuteNFTInput,
  condition?: ModelExecuteNFTConditionInput | null,
};

export type CreateExecuteNFTMutation = {
  createExecuteNFT?:  {
    __typename: "ExecuteNFT",
    id: string,
    orderId: string,
    quantity?: number | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateExecuteNFTMutationVariables = {
  input: UpdateExecuteNFTInput,
  condition?: ModelExecuteNFTConditionInput | null,
};

export type UpdateExecuteNFTMutation = {
  updateExecuteNFT?:  {
    __typename: "ExecuteNFT",
    id: string,
    orderId: string,
    quantity?: number | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteExecuteNFTMutationVariables = {
  input: DeleteExecuteNFTInput,
  condition?: ModelExecuteNFTConditionInput | null,
};

export type DeleteExecuteNFTMutation = {
  deleteExecuteNFT?:  {
    __typename: "ExecuteNFT",
    id: string,
    orderId: string,
    quantity?: number | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateItemMutationVariables = {
  input: CreateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type CreateItemMutation = {
  createItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    description?: string | null,
    imageUrl: string,
    metadataUrl: string,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null,
    tradeStateAddress?: string | null,
    recipientAddress?: string | null,
    address?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holdUserId?: string | null,
  } | null,
};

export type UpdateItemMutationVariables = {
  input: UpdateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type UpdateItemMutation = {
  updateItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    description?: string | null,
    imageUrl: string,
    metadataUrl: string,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null,
    tradeStateAddress?: string | null,
    recipientAddress?: string | null,
    address?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holdUserId?: string | null,
  } | null,
};

export type DeleteItemMutationVariables = {
  input: DeleteItemInput,
  condition?: ModelItemConditionInput | null,
};

export type DeleteItemMutation = {
  deleteItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    description?: string | null,
    imageUrl: string,
    metadataUrl: string,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null,
    tradeStateAddress?: string | null,
    recipientAddress?: string | null,
    address?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holdUserId?: string | null,
  } | null,
};

export type CreateSellMutationVariables = {
  input: CreateSellInput,
  condition?: ModelSellConditionInput | null,
};

export type CreateSellMutation = {
  createSell?:  {
    __typename: "Sell",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    status?: SellStatus | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    reservedFor?: string | null,
    sellDuration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    sellingTypes?: SellingMethod | null,
    currency?: PriceCurrency | null,
    reservedPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    endingPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateSellMutationVariables = {
  input: UpdateSellInput,
  condition?: ModelSellConditionInput | null,
};

export type UpdateSellMutation = {
  updateSell?:  {
    __typename: "Sell",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    status?: SellStatus | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    reservedFor?: string | null,
    sellDuration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    sellingTypes?: SellingMethod | null,
    currency?: PriceCurrency | null,
    reservedPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    endingPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteSellMutationVariables = {
  input: DeleteSellInput,
  condition?: ModelSellConditionInput | null,
};

export type DeleteSellMutation = {
  deleteSell?:  {
    __typename: "Sell",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    status?: SellStatus | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    reservedFor?: string | null,
    sellDuration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    sellingTypes?: SellingMethod | null,
    currency?: PriceCurrency | null,
    reservedPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    endingPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateSellDurationMutationVariables = {
  input: CreateSellDurationInput,
  condition?: ModelSellDurationConditionInput | null,
};

export type CreateSellDurationMutation = {
  createSellDuration?:  {
    __typename: "SellDuration",
    id: string,
    itemId: string,
    sellId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSellDurationMutationVariables = {
  input: UpdateSellDurationInput,
  condition?: ModelSellDurationConditionInput | null,
};

export type UpdateSellDurationMutation = {
  updateSellDuration?:  {
    __typename: "SellDuration",
    id: string,
    itemId: string,
    sellId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSellDurationMutationVariables = {
  input: DeleteSellDurationInput,
  condition?: ModelSellDurationConditionInput | null,
};

export type DeleteSellDurationMutation = {
  deleteSellDuration?:  {
    __typename: "SellDuration",
    id: string,
    itemId: string,
    sellId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePurchaseMutationVariables = {
  input: CreatePurchaseInput,
  condition?: ModelPurchaseConditionInput | null,
};

export type CreatePurchaseMutation = {
  createPurchase?:  {
    __typename: "Purchase",
    id: string,
    orderId?: string | null,
    userId: string,
    itemId: string,
    paymentGatewayName: PurchasePaymentGatewayName,
    bank?: string | null,
    amount?: number | null,
    status: PurchaseStatus,
    virtualAccount?: string | null,
    checkoutUrl?:  Array< {
      __typename: "CheckoutUrl",
      name: string,
      method: string,
      url: string,
    } | null > | null,
    redirectUrl?: string | null,
    metaPaymentNotification?: string | null,
    metaRequestPayment?: string | null,
    metaResponsePayment?: string | null,
    expiredAt?: string | null,
    executeNFTId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    collectionId?: string | null,
    numberOfMintingId?: string | null,
    action?: string | null,
    metaListing?: string | null,
  } | null,
};

export type UpdatePurchaseMutationVariables = {
  input: UpdatePurchaseInput,
  condition?: ModelPurchaseConditionInput | null,
};

export type UpdatePurchaseMutation = {
  updatePurchase?:  {
    __typename: "Purchase",
    id: string,
    orderId?: string | null,
    userId: string,
    itemId: string,
    paymentGatewayName: PurchasePaymentGatewayName,
    bank?: string | null,
    amount?: number | null,
    status: PurchaseStatus,
    virtualAccount?: string | null,
    checkoutUrl?:  Array< {
      __typename: "CheckoutUrl",
      name: string,
      method: string,
      url: string,
    } | null > | null,
    redirectUrl?: string | null,
    metaPaymentNotification?: string | null,
    metaRequestPayment?: string | null,
    metaResponsePayment?: string | null,
    expiredAt?: string | null,
    executeNFTId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    collectionId?: string | null,
    numberOfMintingId?: string | null,
    action?: string | null,
    metaListing?: string | null,
  } | null,
};

export type DeletePurchaseMutationVariables = {
  input: DeletePurchaseInput,
  condition?: ModelPurchaseConditionInput | null,
};

export type DeletePurchaseMutation = {
  deletePurchase?:  {
    __typename: "Purchase",
    id: string,
    orderId?: string | null,
    userId: string,
    itemId: string,
    paymentGatewayName: PurchasePaymentGatewayName,
    bank?: string | null,
    amount?: number | null,
    status: PurchaseStatus,
    virtualAccount?: string | null,
    checkoutUrl?:  Array< {
      __typename: "CheckoutUrl",
      name: string,
      method: string,
      url: string,
    } | null > | null,
    redirectUrl?: string | null,
    metaPaymentNotification?: string | null,
    metaRequestPayment?: string | null,
    metaResponsePayment?: string | null,
    expiredAt?: string | null,
    executeNFTId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    collectionId?: string | null,
    numberOfMintingId?: string | null,
    action?: string | null,
    metaListing?: string | null,
  } | null,
};

export type CreatePurchaseHoldMutationVariables = {
  input: CreatePurchaseHoldInput,
  condition?: ModelPurchaseHoldConditionInput | null,
};

export type CreatePurchaseHoldMutation = {
  createPurchaseHold?:  {
    __typename: "PurchaseHold",
    id: string,
    orderId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePurchaseHoldMutationVariables = {
  input: UpdatePurchaseHoldInput,
  condition?: ModelPurchaseHoldConditionInput | null,
};

export type UpdatePurchaseHoldMutation = {
  updatePurchaseHold?:  {
    __typename: "PurchaseHold",
    id: string,
    orderId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePurchaseHoldMutationVariables = {
  input: DeletePurchaseHoldInput,
  condition?: ModelPurchaseHoldConditionInput | null,
};

export type DeletePurchaseHoldMutation = {
  deletePurchaseHold?:  {
    __typename: "PurchaseHold",
    id: string,
    orderId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWalletMutationVariables = {
  input: CreateWalletInput,
  condition?: ModelWalletConditionInput | null,
};

export type CreateWalletMutation = {
  createWallet?:  {
    __typename: "Wallet",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    currency?: string | null,
    publicKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateWalletMutationVariables = {
  input: UpdateWalletInput,
  condition?: ModelWalletConditionInput | null,
};

export type UpdateWalletMutation = {
  updateWallet?:  {
    __typename: "Wallet",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    currency?: string | null,
    publicKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteWalletMutationVariables = {
  input: DeleteWalletInput,
  condition?: ModelWalletConditionInput | null,
};

export type DeleteWalletMutation = {
  deleteWallet?:  {
    __typename: "Wallet",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    currency?: string | null,
    publicKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateBidHistoryMutationVariables = {
  input: CreateBidHistoryInput,
  condition?: ModelBidHistoryConditionInput | null,
};

export type CreateBidHistoryMutation = {
  createBidHistory?:  {
    __typename: "BidHistory",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    sellId: string,
    sell?:  {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    status?: SellStatus | null,
    currency?: PriceCurrency | null,
    bidPrice?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateBidHistoryMutationVariables = {
  input: UpdateBidHistoryInput,
  condition?: ModelBidHistoryConditionInput | null,
};

export type UpdateBidHistoryMutation = {
  updateBidHistory?:  {
    __typename: "BidHistory",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    sellId: string,
    sell?:  {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    status?: SellStatus | null,
    currency?: PriceCurrency | null,
    bidPrice?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteBidHistoryMutationVariables = {
  input: DeleteBidHistoryInput,
  condition?: ModelBidHistoryConditionInput | null,
};

export type DeleteBidHistoryMutation = {
  deleteBidHistory?:  {
    __typename: "BidHistory",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    sellId: string,
    sell?:  {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    status?: SellStatus | null,
    currency?: PriceCurrency | null,
    bidPrice?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateOfferMutationVariables = {
  input: CreateOfferInput,
  condition?: ModelOfferConditionInput | null,
};

export type CreateOfferMutation = {
  createOffer?:  {
    __typename: "Offer",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    currency?: PriceCurrency | null,
    quantity?: number | null,
    duration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    expiredAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateOfferMutationVariables = {
  input: UpdateOfferInput,
  condition?: ModelOfferConditionInput | null,
};

export type UpdateOfferMutation = {
  updateOffer?:  {
    __typename: "Offer",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    currency?: PriceCurrency | null,
    quantity?: number | null,
    duration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    expiredAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteOfferMutationVariables = {
  input: DeleteOfferInput,
  condition?: ModelOfferConditionInput | null,
};

export type DeleteOfferMutation = {
  deleteOffer?:  {
    __typename: "Offer",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    currency?: PriceCurrency | null,
    quantity?: number | null,
    duration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    expiredAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateUserBalanceMutationVariables = {
  input: CreateUserBalanceInput,
  condition?: ModelUserBalanceConditionInput | null,
};

export type CreateUserBalanceMutation = {
  createUserBalance?:  {
    __typename: "UserBalance",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    balance?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    currency?: PriceCurrency | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateUserBalanceMutationVariables = {
  input: UpdateUserBalanceInput,
  condition?: ModelUserBalanceConditionInput | null,
};

export type UpdateUserBalanceMutation = {
  updateUserBalance?:  {
    __typename: "UserBalance",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    balance?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    currency?: PriceCurrency | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteUserBalanceMutationVariables = {
  input: DeleteUserBalanceInput,
  condition?: ModelUserBalanceConditionInput | null,
};

export type DeleteUserBalanceMutation = {
  deleteUserBalance?:  {
    __typename: "UserBalance",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    balance?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    currency?: PriceCurrency | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateCandyMachineNFTOneMutationVariables = {
  input: CreateCandyMachineNFTOneInput,
  condition?: ModelCandyMachineNFTOneConditionInput | null,
};

export type CreateCandyMachineNFTOneMutation = {
  createCandyMachineNFTOne?:  {
    __typename: "CandyMachineNFTOne",
    id: string,
    candyMachineId?: string | null,
    collectionId?: string | null,
    mintingType?: MintingType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCandyMachineNFTOneMutationVariables = {
  input: UpdateCandyMachineNFTOneInput,
  condition?: ModelCandyMachineNFTOneConditionInput | null,
};

export type UpdateCandyMachineNFTOneMutation = {
  updateCandyMachineNFTOne?:  {
    __typename: "CandyMachineNFTOne",
    id: string,
    candyMachineId?: string | null,
    collectionId?: string | null,
    mintingType?: MintingType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCandyMachineNFTOneMutationVariables = {
  input: DeleteCandyMachineNFTOneInput,
  condition?: ModelCandyMachineNFTOneConditionInput | null,
};

export type DeleteCandyMachineNFTOneMutation = {
  deleteCandyMachineNFTOne?:  {
    __typename: "CandyMachineNFTOne",
    id: string,
    candyMachineId?: string | null,
    collectionId?: string | null,
    mintingType?: MintingType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCollectionStatusTtlMutationVariables = {
  input: CreateCollectionStatusTtlInput,
  condition?: ModelCollectionStatusTtlConditionInput | null,
};

export type CreateCollectionStatusTtlMutation = {
  createCollectionStatusTtl?:  {
    __typename: "CollectionStatusTtl",
    id: string,
    collectionId?: string | null,
    status?: StatusTtl | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCollectionStatusTtlMutationVariables = {
  input: UpdateCollectionStatusTtlInput,
  condition?: ModelCollectionStatusTtlConditionInput | null,
};

export type UpdateCollectionStatusTtlMutation = {
  updateCollectionStatusTtl?:  {
    __typename: "CollectionStatusTtl",
    id: string,
    collectionId?: string | null,
    status?: StatusTtl | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCollectionStatusTtlMutationVariables = {
  input: DeleteCollectionStatusTtlInput,
  condition?: ModelCollectionStatusTtlConditionInput | null,
};

export type DeleteCollectionStatusTtlMutation = {
  deleteCollectionStatusTtl?:  {
    __typename: "CollectionStatusTtl",
    id: string,
    collectionId?: string | null,
    status?: StatusTtl | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type RedeemCodeMutationVariables = {
  code: string,
};

export type RedeemCodeMutation = {
  redeemCode:  {
    __typename: "RedeemCodeResponse",
    success: boolean,
    errorMessage?: string | null,
  },
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
};

export type CreateOrderMutation = {
  createOrder:  {
    __typename: "Order",
    id: string,
    price:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } >,
    status: PaidStatus,
    userId: string,
    action: OrderAction,
    details?:  Array< {
      __typename: "OrderDetail",
      id: string,
      orderId: string,
      userId: string,
      itemId?: string | null,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      collectionId?: string | null,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  },
};

export type CreatePaymentV2MutationVariables = {
  input: CreatePaymentV2Input,
};

export type CreatePaymentV2Mutation = {
  createPaymentV2:  {
    __typename: "ResponsePaymentV2",
    paymentId: string,
    orderId: string,
    amount: number,
    paymentType: string,
    status: string,
    expiredAt: string,
    createdAt: string,
    updatedAt: string,
    actions:  Array< {
      __typename: "PaymentAction",
      name: string,
      method: string,
      url: string,
    } >,
  },
};

export type PurchaseNFTUnauthorizedMutationVariables = {
  input: PurchaseNFTUnauthorizedInput,
};

export type PurchaseNFTUnauthorizedMutation = {
  purchaseNFTUnauthorized:  {
    __typename: "ResponsePurchaseNFTUnauthorized",
    orderId: string,
    paymentId: string,
    itemId: string,
    amount: number,
    actions:  Array< {
      __typename: "PaymentAction",
      name: string,
      method: string,
      url: string,
    } >,
    expiredAt: string,
    createdAt: string,
    updatedAt: string,
  },
};

export type PublishNFTRedeemCodeMutationVariables = {
  input: InputPublishNFTRedeemCode,
};

export type PublishNFTRedeemCodeMutation = {
  publishNFTRedeemCode:  {
    __typename: "ResponsePublishNFTRedeemCode",
    orderId: string,
    itemId: string,
    itemAddress: string,
    itemName: string,
    itemImageUrl: string,
    redeemCode: string,
    amount: number,
  },
};

export type RedeemCodeNFTMutationVariables = {
  code: string,
};

export type RedeemCodeNFTMutation = {
  redeemCodeNFT?:  {
    __typename: "ResponseRedeemCodeNFT",
    itemId?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type PublishLaundhpadV2MutationVariables = {
  input: InputPublishLaunchpadV2,
};

export type PublishLaundhpadV2Mutation = {
  publishLaundhpadV2:  {
    __typename: "ResponseCreateOrder",
    orderId: string,
    amount: number,
    expiredAt: string,
    createdAt: string,
    updatedAt: string,
  },
};

export type MintingLaunchpadV2MutationVariables = {
  input: InputMintingLaunchpadV2,
};

export type MintingLaunchpadV2Mutation = {
  mintingLaunchpadV2:  {
    __typename: "ResponseCreateOrder",
    orderId: string,
    amount: number,
    expiredAt: string,
    createdAt: string,
    updatedAt: string,
  },
};

export type DirectBuyLaunchpadV2MutationVariables = {
  input: InputDirectBuyLaunchpadV2,
};

export type DirectBuyLaunchpadV2Mutation = {
  directBuyLaunchpadV2:  {
    __typename: "ResponseCreateOrder",
    orderId: string,
    amount: number,
    expiredAt: string,
    createdAt: string,
    updatedAt: string,
  },
};

export type CreateListingNFTV2MutationVariables = {
  input: InputCreateListingNFTV2,
};

export type CreateListingNFTV2Mutation = {
  createListingNFTV2:  {
    __typename: "ResponseCreateOrder",
    orderId: string,
    amount: number,
    expiredAt: string,
    createdAt: string,
    updatedAt: string,
  },
};

export type DirectBuyV2MutationVariables = {
  input: InputDirectBuyV2,
};

export type DirectBuyV2Mutation = {
  directBuyV2:  {
    __typename: "ResponseCreateOrder",
    orderId: string,
    amount: number,
    expiredAt: string,
    createdAt: string,
    updatedAt: string,
  },
};

export type CancelOrderMutationVariables = {
  orderId: string,
};

export type CancelOrderMutation = {
  cancelOrder:  {
    __typename: "ResponseCancelOrder",
    orderId: string,
    success: boolean,
  },
};

export type ParameterStoreQueryVariables = {
  key: string,
};

export type ParameterStoreQuery = {
  parameterStore?:  {
    __typename: "CallbackParameterStrore",
    key?: string | null,
    value?: string | null,
  } | null,
};

export type CheckItemPreOrderQueryVariables = {
  itemId: string,
};

export type CheckItemPreOrderQuery = {
  checkItemPreOrder:  {
    __typename: "CallbackCheckItemPreOrder",
    alreadyOrdered: boolean,
  },
};

export type ListRandomAvailableItemQueryVariables = {
  noReturn: number,
};

export type ListRandomAvailableItemQuery = {
  listRandomAvailableItem:  {
    __typename: "CallbackRandomItemAvailable",
    items?:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null > | null,
    errorMessage?: string | null,
  },
};

export type ListItemAvailablePreOrderQueryVariables = {
};

export type ListItemAvailablePreOrderQuery = {
  listItemAvailablePreOrder:  {
    __typename: "CallbackItemAvailablePreOrder",
    items?: Array< string | null > | null,
    errorMessage?: string | null,
  },
};

export type CalculateCollectionSizeQueryVariables = {
  collectionId: string,
  type: string,
};

export type CalculateCollectionSizeQuery = {
  calculateCollectionSize:  {
    __typename: "CallbackCalculateCollectionSize",
    totalFile: number,
    size: string,
  },
};

export type GetAllItemS3QueryVariables = {
  prefix?: string | null,
};

export type GetAllItemS3Query = {
  getAllItemS3?:  {
    __typename: "CallbackGetAllItemS3",
    s3Items?:  Array< {
      __typename: "s3Item",
      url?: string | null,
    } | null > | null,
    errorMessage?: string | null,
  } | null,
};

export type GetCandyMachineInfoByCollectionIdQueryVariables = {
  collectionId?: string | null,
};

export type GetCandyMachineInfoByCollectionIdQuery = {
  getCandyMachineInfoByCollectionId?:  {
    __typename: "CallbackGetCandyMachineInfoByCollectionId",
    itemsAvailable?: number | null,
    itemsLoaded?: number | null,
    itemsMinted?: number | null,
    items?:  Array< {
      __typename: "CandyMachineItem",
      name?: string | null,
      uri?: string | null,
    } | null > | null,
  } | null,
};

export type GetCandyMachineInfoV2QueryVariables = {
  collectionId: string,
};

export type GetCandyMachineInfoV2Query = {
  getCandyMachineInfoV2:  {
    __typename: "CallbackGetCandyMachineInfoV2",
    itemsAvailable: number,
    itemsLoaded: number,
    itemsMinted: number,
    mintingType: MintingType,
  },
};

export type GetUserProfileQueryVariables = {
  id: string,
};

export type GetUserProfileQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    name: string,
    email?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    description?: string | null,
    type: UserType,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    wallets?:  {
      __typename: "ModelWalletConnection",
      items:  Array< {
        __typename: "Wallet",
        id: string,
        userId: string,
        currency?: string | null,
        publicKey?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    whitelistUser?:  {
      __typename: "ModelWhitelistUserConnection",
      items:  Array< {
        __typename: "WhitelistUser",
        id: string,
        userId: string,
        whitelistId: string,
        ttl: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    executeNFT?:  {
      __typename: "ModelExecuteNFTConnection",
      items:  Array< {
        __typename: "ExecuteNFT",
        id: string,
        orderId: string,
        quantity?: number | null,
        userId: string,
        collectionId: string,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUserProfilesQueryVariables = {
  filter?: ModelUserProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserProfilesQuery = {
  listUserProfiles?:  {
    __typename: "ModelUserProfileConnection",
    items:  Array< {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserProfileByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserProfileByNameQuery = {
  userProfileByName?:  {
    __typename: "ModelUserProfileConnection",
    items:  Array< {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserProfileByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserProfileByEmailQuery = {
  userProfileByEmail?:  {
    __typename: "ModelUserProfileConnection",
    items:  Array< {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserProfileByTypeQueryVariables = {
  type: UserType,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserProfileByTypeQuery = {
  userProfileByType?:  {
    __typename: "ModelUserProfileConnection",
    items:  Array< {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchUserProfilesQueryVariables = {
  filter?: SearchableUserProfileFilterInput | null,
  sort?: Array< SearchableUserProfileSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableUserProfileAggregationInput | null > | null,
};

export type SearchUserProfilesQuery = {
  searchUserProfiles?:  {
    __typename: "SearchableUserProfileConnection",
    items:  Array< {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetWhitelistQueryVariables = {
  id: string,
};

export type GetWhitelistQuery = {
  getWhitelist?:  {
    __typename: "Whitelist",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    expiredAt?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWhitelistsQueryVariables = {
  filter?: ModelWhitelistFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWhitelistsQuery = {
  listWhitelists?:  {
    __typename: "ModelWhitelistConnection",
    items:  Array< {
      __typename: "Whitelist",
      id: string,
      startDate?: string | null,
      endDate?: string | null,
      expiredAt?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWhitelistUserQueryVariables = {
  id: string,
};

export type GetWhitelistUserQuery = {
  getWhitelistUser?:  {
    __typename: "WhitelistUser",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    whitelistId: string,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWhitelistUsersQueryVariables = {
  filter?: ModelWhitelistUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWhitelistUsersQuery = {
  listWhitelistUsers?:  {
    __typename: "ModelWhitelistUserConnection",
    items:  Array< {
      __typename: "WhitelistUser",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      whitelistId: string,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WhitelistUserByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWhitelistUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WhitelistUserByUserIdQuery = {
  whitelistUserByUserId?:  {
    __typename: "ModelWhitelistUserConnection",
    items:  Array< {
      __typename: "WhitelistUser",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      whitelistId: string,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WhitelistUserByWhitelistIdQueryVariables = {
  whitelistId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWhitelistUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WhitelistUserByWhitelistIdQuery = {
  whitelistUserByWhitelistId?:  {
    __typename: "ModelWhitelistUserConnection",
    items:  Array< {
      __typename: "WhitelistUser",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      whitelistId: string,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchWhitelistUsersQueryVariables = {
  filter?: SearchableWhitelistUserFilterInput | null,
  sort?: Array< SearchableWhitelistUserSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableWhitelistUserAggregationInput | null > | null,
};

export type SearchWhitelistUsersQuery = {
  searchWhitelistUsers?:  {
    __typename: "SearchableWhitelistUserConnection",
    items:  Array< {
      __typename: "WhitelistUser",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      whitelistId: string,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCollectionQueryVariables = {
  id: string,
};

export type GetCollectionQuery = {
  getCollection?:  {
    __typename: "Collection",
    id: string,
    slug?: string | null,
    name: string,
    description?: string | null,
    thumbnailUrl?: string | null,
    bannerUrl?: string | null,
    metadataUrl?: string | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    address?: string | null,
    status?: CollectionStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    totalItems?: number | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
  } | null,
};

export type ListCollectionsQueryVariables = {
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionsQuery = {
  listCollections?:  {
    __typename: "ModelCollectionConnection",
    items:  Array< {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CollectionBySlugQueryVariables = {
  slug: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CollectionBySlugQuery = {
  collectionBySlug?:  {
    __typename: "ModelCollectionConnection",
    items:  Array< {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CollectionByCreatorQueryVariables = {
  creatorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CollectionByCreatorQuery = {
  collectionByCreator?:  {
    __typename: "ModelCollectionConnection",
    items:  Array< {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CollectionByStatusQueryVariables = {
  status: CollectionStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CollectionByStatusQuery = {
  collectionByStatus?:  {
    __typename: "ModelCollectionConnection",
    items:  Array< {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCollectionsQueryVariables = {
  filter?: SearchableCollectionFilterInput | null,
  sort?: Array< SearchableCollectionSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableCollectionAggregationInput | null > | null,
};

export type SearchCollectionsQuery = {
  searchCollections?:  {
    __typename: "SearchableCollectionConnection",
    items:  Array< {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetExecuteNFTQueryVariables = {
  id: string,
};

export type GetExecuteNFTQuery = {
  getExecuteNFT?:  {
    __typename: "ExecuteNFT",
    id: string,
    orderId: string,
    quantity?: number | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListExecuteNFTSQueryVariables = {
  filter?: ModelExecuteNFTFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExecuteNFTSQuery = {
  listExecuteNFTS?:  {
    __typename: "ModelExecuteNFTConnection",
    items:  Array< {
      __typename: "ExecuteNFT",
      id: string,
      orderId: string,
      quantity?: number | null,
      price?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExecuteNFTByOrderIdQueryVariables = {
  orderId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExecuteNFTFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExecuteNFTByOrderIdQuery = {
  executeNFTByOrderId?:  {
    __typename: "ModelExecuteNFTConnection",
    items:  Array< {
      __typename: "ExecuteNFT",
      id: string,
      orderId: string,
      quantity?: number | null,
      price?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExecuteNFTByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExecuteNFTFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExecuteNFTByUserIdQuery = {
  executeNFTByUserId?:  {
    __typename: "ModelExecuteNFTConnection",
    items:  Array< {
      __typename: "ExecuteNFT",
      id: string,
      orderId: string,
      quantity?: number | null,
      price?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExecuteNFTByCollectionQueryVariables = {
  collectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExecuteNFTFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExecuteNFTByCollectionQuery = {
  executeNFTByCollection?:  {
    __typename: "ModelExecuteNFTConnection",
    items:  Array< {
      __typename: "ExecuteNFT",
      id: string,
      orderId: string,
      quantity?: number | null,
      price?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchExecuteNFTSQueryVariables = {
  filter?: SearchableExecuteNFTFilterInput | null,
  sort?: Array< SearchableExecuteNFTSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableExecuteNFTAggregationInput | null > | null,
};

export type SearchExecuteNFTSQuery = {
  searchExecuteNFTS?:  {
    __typename: "SearchableExecuteNFTConnection",
    items:  Array< {
      __typename: "ExecuteNFT",
      id: string,
      orderId: string,
      quantity?: number | null,
      price?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetItemQueryVariables = {
  id: string,
};

export type GetItemQuery = {
  getItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    description?: string | null,
    imageUrl: string,
    metadataUrl: string,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null,
    tradeStateAddress?: string | null,
    recipientAddress?: string | null,
    address?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holdUserId?: string | null,
  } | null,
};

export type ListItemsQueryVariables = {
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemsQuery = {
  listItems?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemByOwnerQueryVariables = {
  ownerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemByOwnerQuery = {
  itemByOwner?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemByCreatorQueryVariables = {
  creatorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemByCreatorQuery = {
  itemByCreator?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemByCollectionQueryVariables = {
  collectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemByCollectionQuery = {
  itemByCollection?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemByCollectionAndHoldUserIdQueryVariables = {
  collectionId: string,
  holdUserId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemByCollectionAndHoldUserIdQuery = {
  itemByCollectionAndHoldUserId?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemByAddressQueryVariables = {
  address: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemByAddressQuery = {
  itemByAddress?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemByHoldUserIdQueryVariables = {
  holdUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemByHoldUserIdQuery = {
  itemByHoldUserId?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchItemsQueryVariables = {
  filter?: SearchableItemFilterInput | null,
  sort?: Array< SearchableItemSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableItemAggregationInput | null > | null,
};

export type SearchItemsQuery = {
  searchItems?:  {
    __typename: "SearchableItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetSellQueryVariables = {
  id: string,
};

export type GetSellQuery = {
  getSell?:  {
    __typename: "Sell",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    status?: SellStatus | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    reservedFor?: string | null,
    sellDuration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    sellingTypes?: SellingMethod | null,
    currency?: PriceCurrency | null,
    reservedPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    endingPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListSellsQueryVariables = {
  filter?: ModelSellFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSellsQuery = {
  listSells?:  {
    __typename: "ModelSellConnection",
    items:  Array< {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SellByItemQueryVariables = {
  itemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSellFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SellByItemQuery = {
  sellByItem?:  {
    __typename: "ModelSellConnection",
    items:  Array< {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SellByItemByStatusQueryVariables = {
  itemId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSellFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SellByItemByStatusQuery = {
  sellByItemByStatus?:  {
    __typename: "ModelSellConnection",
    items:  Array< {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SellByOwnerQueryVariables = {
  ownerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSellFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SellByOwnerQuery = {
  sellByOwner?:  {
    __typename: "ModelSellConnection",
    items:  Array< {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchSellsQueryVariables = {
  filter?: SearchableSellFilterInput | null,
  sort?: Array< SearchableSellSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableSellAggregationInput | null > | null,
};

export type SearchSellsQuery = {
  searchSells?:  {
    __typename: "SearchableSellConnection",
    items:  Array< {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetSellDurationQueryVariables = {
  id: string,
};

export type GetSellDurationQuery = {
  getSellDuration?:  {
    __typename: "SellDuration",
    id: string,
    itemId: string,
    sellId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSellDurationsQueryVariables = {
  filter?: ModelSellDurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSellDurationsQuery = {
  listSellDurations?:  {
    __typename: "ModelSellDurationConnection",
    items:  Array< {
      __typename: "SellDuration",
      id: string,
      itemId: string,
      sellId: string,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SellDurationByItemIdQueryVariables = {
  itemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSellDurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SellDurationByItemIdQuery = {
  sellDurationByItemId?:  {
    __typename: "ModelSellDurationConnection",
    items:  Array< {
      __typename: "SellDuration",
      id: string,
      itemId: string,
      sellId: string,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SellDurationBySellIdQueryVariables = {
  sellId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSellDurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SellDurationBySellIdQuery = {
  sellDurationBySellId?:  {
    __typename: "ModelSellDurationConnection",
    items:  Array< {
      __typename: "SellDuration",
      id: string,
      itemId: string,
      sellId: string,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPurchaseQueryVariables = {
  id: string,
};

export type GetPurchaseQuery = {
  getPurchase?:  {
    __typename: "Purchase",
    id: string,
    orderId?: string | null,
    userId: string,
    itemId: string,
    paymentGatewayName: PurchasePaymentGatewayName,
    bank?: string | null,
    amount?: number | null,
    status: PurchaseStatus,
    virtualAccount?: string | null,
    checkoutUrl?:  Array< {
      __typename: "CheckoutUrl",
      name: string,
      method: string,
      url: string,
    } | null > | null,
    redirectUrl?: string | null,
    metaPaymentNotification?: string | null,
    metaRequestPayment?: string | null,
    metaResponsePayment?: string | null,
    expiredAt?: string | null,
    executeNFTId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    collectionId?: string | null,
    numberOfMintingId?: string | null,
    action?: string | null,
    metaListing?: string | null,
  } | null,
};

export type ListPurchasesQueryVariables = {
  filter?: ModelPurchaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPurchasesQuery = {
  listPurchases?:  {
    __typename: "ModelPurchaseConnection",
    items:  Array< {
      __typename: "Purchase",
      id: string,
      orderId?: string | null,
      userId: string,
      itemId: string,
      paymentGatewayName: PurchasePaymentGatewayName,
      bank?: string | null,
      amount?: number | null,
      status: PurchaseStatus,
      virtualAccount?: string | null,
      checkoutUrl?:  Array< {
        __typename: "CheckoutUrl",
        name: string,
        method: string,
        url: string,
      } | null > | null,
      redirectUrl?: string | null,
      metaPaymentNotification?: string | null,
      metaRequestPayment?: string | null,
      metaResponsePayment?: string | null,
      expiredAt?: string | null,
      executeNFTId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      collectionId?: string | null,
      numberOfMintingId?: string | null,
      action?: string | null,
      metaListing?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PurchaseByOrderIdQueryVariables = {
  orderId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPurchaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PurchaseByOrderIdQuery = {
  purchaseByOrderId?:  {
    __typename: "ModelPurchaseConnection",
    items:  Array< {
      __typename: "Purchase",
      id: string,
      orderId?: string | null,
      userId: string,
      itemId: string,
      paymentGatewayName: PurchasePaymentGatewayName,
      bank?: string | null,
      amount?: number | null,
      status: PurchaseStatus,
      virtualAccount?: string | null,
      checkoutUrl?:  Array< {
        __typename: "CheckoutUrl",
        name: string,
        method: string,
        url: string,
      } | null > | null,
      redirectUrl?: string | null,
      metaPaymentNotification?: string | null,
      metaRequestPayment?: string | null,
      metaResponsePayment?: string | null,
      expiredAt?: string | null,
      executeNFTId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      collectionId?: string | null,
      numberOfMintingId?: string | null,
      action?: string | null,
      metaListing?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PurchaseByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPurchaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PurchaseByUserIdQuery = {
  purchaseByUserId?:  {
    __typename: "ModelPurchaseConnection",
    items:  Array< {
      __typename: "Purchase",
      id: string,
      orderId?: string | null,
      userId: string,
      itemId: string,
      paymentGatewayName: PurchasePaymentGatewayName,
      bank?: string | null,
      amount?: number | null,
      status: PurchaseStatus,
      virtualAccount?: string | null,
      checkoutUrl?:  Array< {
        __typename: "CheckoutUrl",
        name: string,
        method: string,
        url: string,
      } | null > | null,
      redirectUrl?: string | null,
      metaPaymentNotification?: string | null,
      metaRequestPayment?: string | null,
      metaResponsePayment?: string | null,
      expiredAt?: string | null,
      executeNFTId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      collectionId?: string | null,
      numberOfMintingId?: string | null,
      action?: string | null,
      metaListing?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PurchaseByItemIdQueryVariables = {
  itemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPurchaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PurchaseByItemIdQuery = {
  purchaseByItemId?:  {
    __typename: "ModelPurchaseConnection",
    items:  Array< {
      __typename: "Purchase",
      id: string,
      orderId?: string | null,
      userId: string,
      itemId: string,
      paymentGatewayName: PurchasePaymentGatewayName,
      bank?: string | null,
      amount?: number | null,
      status: PurchaseStatus,
      virtualAccount?: string | null,
      checkoutUrl?:  Array< {
        __typename: "CheckoutUrl",
        name: string,
        method: string,
        url: string,
      } | null > | null,
      redirectUrl?: string | null,
      metaPaymentNotification?: string | null,
      metaRequestPayment?: string | null,
      metaResponsePayment?: string | null,
      expiredAt?: string | null,
      executeNFTId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      collectionId?: string | null,
      numberOfMintingId?: string | null,
      action?: string | null,
      metaListing?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PurchaseByItemIdByExpiredAtQueryVariables = {
  itemId: string,
  expiredAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPurchaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PurchaseByItemIdByExpiredAtQuery = {
  purchaseByItemIdByExpiredAt?:  {
    __typename: "ModelPurchaseConnection",
    items:  Array< {
      __typename: "Purchase",
      id: string,
      orderId?: string | null,
      userId: string,
      itemId: string,
      paymentGatewayName: PurchasePaymentGatewayName,
      bank?: string | null,
      amount?: number | null,
      status: PurchaseStatus,
      virtualAccount?: string | null,
      checkoutUrl?:  Array< {
        __typename: "CheckoutUrl",
        name: string,
        method: string,
        url: string,
      } | null > | null,
      redirectUrl?: string | null,
      metaPaymentNotification?: string | null,
      metaRequestPayment?: string | null,
      metaResponsePayment?: string | null,
      expiredAt?: string | null,
      executeNFTId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      collectionId?: string | null,
      numberOfMintingId?: string | null,
      action?: string | null,
      metaListing?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PurchaseByItemIdByStatusQueryVariables = {
  itemId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPurchaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PurchaseByItemIdByStatusQuery = {
  purchaseByItemIdByStatus?:  {
    __typename: "ModelPurchaseConnection",
    items:  Array< {
      __typename: "Purchase",
      id: string,
      orderId?: string | null,
      userId: string,
      itemId: string,
      paymentGatewayName: PurchasePaymentGatewayName,
      bank?: string | null,
      amount?: number | null,
      status: PurchaseStatus,
      virtualAccount?: string | null,
      checkoutUrl?:  Array< {
        __typename: "CheckoutUrl",
        name: string,
        method: string,
        url: string,
      } | null > | null,
      redirectUrl?: string | null,
      metaPaymentNotification?: string | null,
      metaRequestPayment?: string | null,
      metaResponsePayment?: string | null,
      expiredAt?: string | null,
      executeNFTId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      collectionId?: string | null,
      numberOfMintingId?: string | null,
      action?: string | null,
      metaListing?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PurchaseByPaymentGatewayQueryVariables = {
  paymentGatewayName: PurchasePaymentGatewayName,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPurchaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PurchaseByPaymentGatewayQuery = {
  purchaseByPaymentGateway?:  {
    __typename: "ModelPurchaseConnection",
    items:  Array< {
      __typename: "Purchase",
      id: string,
      orderId?: string | null,
      userId: string,
      itemId: string,
      paymentGatewayName: PurchasePaymentGatewayName,
      bank?: string | null,
      amount?: number | null,
      status: PurchaseStatus,
      virtualAccount?: string | null,
      checkoutUrl?:  Array< {
        __typename: "CheckoutUrl",
        name: string,
        method: string,
        url: string,
      } | null > | null,
      redirectUrl?: string | null,
      metaPaymentNotification?: string | null,
      metaRequestPayment?: string | null,
      metaResponsePayment?: string | null,
      expiredAt?: string | null,
      executeNFTId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      collectionId?: string | null,
      numberOfMintingId?: string | null,
      action?: string | null,
      metaListing?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PurchaseByCollectionIdQueryVariables = {
  collectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPurchaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PurchaseByCollectionIdQuery = {
  purchaseByCollectionId?:  {
    __typename: "ModelPurchaseConnection",
    items:  Array< {
      __typename: "Purchase",
      id: string,
      orderId?: string | null,
      userId: string,
      itemId: string,
      paymentGatewayName: PurchasePaymentGatewayName,
      bank?: string | null,
      amount?: number | null,
      status: PurchaseStatus,
      virtualAccount?: string | null,
      checkoutUrl?:  Array< {
        __typename: "CheckoutUrl",
        name: string,
        method: string,
        url: string,
      } | null > | null,
      redirectUrl?: string | null,
      metaPaymentNotification?: string | null,
      metaRequestPayment?: string | null,
      metaResponsePayment?: string | null,
      expiredAt?: string | null,
      executeNFTId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      collectionId?: string | null,
      numberOfMintingId?: string | null,
      action?: string | null,
      metaListing?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPurchaseHoldQueryVariables = {
  id: string,
};

export type GetPurchaseHoldQuery = {
  getPurchaseHold?:  {
    __typename: "PurchaseHold",
    id: string,
    orderId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPurchaseHoldsQueryVariables = {
  filter?: ModelPurchaseHoldFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPurchaseHoldsQuery = {
  listPurchaseHolds?:  {
    __typename: "ModelPurchaseHoldConnection",
    items:  Array< {
      __typename: "PurchaseHold",
      id: string,
      orderId: string,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PurchaseHoldByOrderIdQueryVariables = {
  orderId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPurchaseHoldFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PurchaseHoldByOrderIdQuery = {
  purchaseHoldByOrderId?:  {
    __typename: "ModelPurchaseHoldConnection",
    items:  Array< {
      __typename: "PurchaseHold",
      id: string,
      orderId: string,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWalletQueryVariables = {
  id: string,
};

export type GetWalletQuery = {
  getWallet?:  {
    __typename: "Wallet",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    currency?: string | null,
    publicKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListWalletsQueryVariables = {
  filter?: ModelWalletFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWalletsQuery = {
  listWallets?:  {
    __typename: "ModelWalletConnection",
    items:  Array< {
      __typename: "Wallet",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      currency?: string | null,
      publicKey?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WalletByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWalletFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WalletByUserQuery = {
  walletByUser?:  {
    __typename: "ModelWalletConnection",
    items:  Array< {
      __typename: "Wallet",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      currency?: string | null,
      publicKey?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WalletByPublicKeyQueryVariables = {
  publicKey: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWalletFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WalletByPublicKeyQuery = {
  walletByPublicKey?:  {
    __typename: "ModelWalletConnection",
    items:  Array< {
      __typename: "Wallet",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      currency?: string | null,
      publicKey?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchWalletsQueryVariables = {
  filter?: SearchableWalletFilterInput | null,
  sort?: Array< SearchableWalletSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableWalletAggregationInput | null > | null,
};

export type SearchWalletsQuery = {
  searchWallets?:  {
    __typename: "SearchableWalletConnection",
    items:  Array< {
      __typename: "Wallet",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      currency?: string | null,
      publicKey?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetBidHistoryQueryVariables = {
  id: string,
};

export type GetBidHistoryQuery = {
  getBidHistory?:  {
    __typename: "BidHistory",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    sellId: string,
    sell?:  {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    status?: SellStatus | null,
    currency?: PriceCurrency | null,
    bidPrice?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListBidHistoriesQueryVariables = {
  filter?: ModelBidHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBidHistoriesQuery = {
  listBidHistories?:  {
    __typename: "ModelBidHistoryConnection",
    items:  Array< {
      __typename: "BidHistory",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      sellId: string,
      sell?:  {
        __typename: "Sell",
        id: string,
        itemId: string,
        ownerId: string,
        status?: SellStatus | null,
        reservedFor?: string | null,
        auctionMethod?: AuctionMethodType | null,
        sellingTypes?: SellingMethod | null,
        currency?: PriceCurrency | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      status?: SellStatus | null,
      currency?: PriceCurrency | null,
      bidPrice?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BidByItemQueryVariables = {
  itemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBidHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BidByItemQuery = {
  bidByItem?:  {
    __typename: "ModelBidHistoryConnection",
    items:  Array< {
      __typename: "BidHistory",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      sellId: string,
      sell?:  {
        __typename: "Sell",
        id: string,
        itemId: string,
        ownerId: string,
        status?: SellStatus | null,
        reservedFor?: string | null,
        auctionMethod?: AuctionMethodType | null,
        sellingTypes?: SellingMethod | null,
        currency?: PriceCurrency | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      status?: SellStatus | null,
      currency?: PriceCurrency | null,
      bidPrice?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BidBySellQueryVariables = {
  sellId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBidHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BidBySellQuery = {
  bidBySell?:  {
    __typename: "ModelBidHistoryConnection",
    items:  Array< {
      __typename: "BidHistory",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      sellId: string,
      sell?:  {
        __typename: "Sell",
        id: string,
        itemId: string,
        ownerId: string,
        status?: SellStatus | null,
        reservedFor?: string | null,
        auctionMethod?: AuctionMethodType | null,
        sellingTypes?: SellingMethod | null,
        currency?: PriceCurrency | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      status?: SellStatus | null,
      currency?: PriceCurrency | null,
      bidPrice?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBidHistoriesQueryVariables = {
  filter?: SearchableBidHistoryFilterInput | null,
  sort?: Array< SearchableBidHistorySortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableBidHistoryAggregationInput | null > | null,
};

export type SearchBidHistoriesQuery = {
  searchBidHistories?:  {
    __typename: "SearchableBidHistoryConnection",
    items:  Array< {
      __typename: "BidHistory",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      sellId: string,
      sell?:  {
        __typename: "Sell",
        id: string,
        itemId: string,
        ownerId: string,
        status?: SellStatus | null,
        reservedFor?: string | null,
        auctionMethod?: AuctionMethodType | null,
        sellingTypes?: SellingMethod | null,
        currency?: PriceCurrency | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      status?: SellStatus | null,
      currency?: PriceCurrency | null,
      bidPrice?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetOfferQueryVariables = {
  id: string,
};

export type GetOfferQuery = {
  getOffer?:  {
    __typename: "Offer",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    currency?: PriceCurrency | null,
    quantity?: number | null,
    duration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    expiredAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListOffersQueryVariables = {
  filter?: ModelOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOffersQuery = {
  listOffers?:  {
    __typename: "ModelOfferConnection",
    items:  Array< {
      __typename: "Offer",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      price?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      deposit?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      currency?: PriceCurrency | null,
      quantity?: number | null,
      duration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      expiredAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OfferByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OfferByUserQuery = {
  offerByUser?:  {
    __typename: "ModelOfferConnection",
    items:  Array< {
      __typename: "Offer",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      price?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      deposit?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      currency?: PriceCurrency | null,
      quantity?: number | null,
      duration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      expiredAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OfferByItemIdQueryVariables = {
  itemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OfferByItemIdQuery = {
  offerByItemId?:  {
    __typename: "ModelOfferConnection",
    items:  Array< {
      __typename: "Offer",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      price?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      deposit?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      currency?: PriceCurrency | null,
      quantity?: number | null,
      duration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      expiredAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OfferByOwnerQueryVariables = {
  ownerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OfferByOwnerQuery = {
  offerByOwner?:  {
    __typename: "ModelOfferConnection",
    items:  Array< {
      __typename: "Offer",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      price?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      deposit?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      currency?: PriceCurrency | null,
      quantity?: number | null,
      duration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      expiredAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserBalanceQueryVariables = {
  id: string,
};

export type GetUserBalanceQuery = {
  getUserBalance?:  {
    __typename: "UserBalance",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    balance?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    currency?: PriceCurrency | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListUserBalancesQueryVariables = {
  filter?: ModelUserBalanceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserBalancesQuery = {
  listUserBalances?:  {
    __typename: "ModelUserBalanceConnection",
    items:  Array< {
      __typename: "UserBalance",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      deposit?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      balance?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      currency?: PriceCurrency | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BalanceByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserBalanceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BalanceByUserIdQuery = {
  balanceByUserId?:  {
    __typename: "ModelUserBalanceConnection",
    items:  Array< {
      __typename: "UserBalance",
      id: string,
      userId: string,
      user?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      deposit?:  {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null,
      balance?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      currency?: PriceCurrency | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCandyMachineNFTOneQueryVariables = {
  id: string,
};

export type GetCandyMachineNFTOneQuery = {
  getCandyMachineNFTOne?:  {
    __typename: "CandyMachineNFTOne",
    id: string,
    candyMachineId?: string | null,
    collectionId?: string | null,
    mintingType?: MintingType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCandyMachineNFTOnesQueryVariables = {
  filter?: ModelCandyMachineNFTOneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCandyMachineNFTOnesQuery = {
  listCandyMachineNFTOnes?:  {
    __typename: "ModelCandyMachineNFTOneConnection",
    items:  Array< {
      __typename: "CandyMachineNFTOne",
      id: string,
      candyMachineId?: string | null,
      collectionId?: string | null,
      mintingType?: MintingType | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CandyByAddressQueryVariables = {
  candyMachineId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCandyMachineNFTOneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CandyByAddressQuery = {
  candyByAddress?:  {
    __typename: "ModelCandyMachineNFTOneConnection",
    items:  Array< {
      __typename: "CandyMachineNFTOne",
      id: string,
      candyMachineId?: string | null,
      collectionId?: string | null,
      mintingType?: MintingType | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CandyByCollectionQueryVariables = {
  collectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCandyMachineNFTOneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CandyByCollectionQuery = {
  candyByCollection?:  {
    __typename: "ModelCandyMachineNFTOneConnection",
    items:  Array< {
      __typename: "CandyMachineNFTOne",
      id: string,
      candyMachineId?: string | null,
      collectionId?: string | null,
      mintingType?: MintingType | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCandyMachineNFTOnesQueryVariables = {
  filter?: SearchableCandyMachineNFTOneFilterInput | null,
  sort?: Array< SearchableCandyMachineNFTOneSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableCandyMachineNFTOneAggregationInput | null > | null,
};

export type SearchCandyMachineNFTOnesQuery = {
  searchCandyMachineNFTOnes?:  {
    __typename: "SearchableCandyMachineNFTOneConnection",
    items:  Array< {
      __typename: "CandyMachineNFTOne",
      id: string,
      candyMachineId?: string | null,
      collectionId?: string | null,
      mintingType?: MintingType | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCollectionStatusTtlQueryVariables = {
  id: string,
};

export type GetCollectionStatusTtlQuery = {
  getCollectionStatusTtl?:  {
    __typename: "CollectionStatusTtl",
    id: string,
    collectionId?: string | null,
    status?: StatusTtl | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCollectionStatusTtlsQueryVariables = {
  filter?: ModelCollectionStatusTtlFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionStatusTtlsQuery = {
  listCollectionStatusTtls?:  {
    __typename: "ModelCollectionStatusTtlConnection",
    items:  Array< {
      __typename: "CollectionStatusTtl",
      id: string,
      collectionId?: string | null,
      status?: StatusTtl | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CollectioStatusTtlByCollectionQueryVariables = {
  collectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionStatusTtlFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CollectioStatusTtlByCollectionQuery = {
  collectioStatusTtlByCollection?:  {
    __typename: "ModelCollectionStatusTtlConnection",
    items:  Array< {
      __typename: "CollectionStatusTtl",
      id: string,
      collectionId?: string | null,
      status?: StatusTtl | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRemindLaunchpadQueryVariables = {
  id: string,
};

export type GetRemindLaunchpadQuery = {
  getRemindLaunchpad?:  {
    __typename: "RemindLaunchpad",
    id: string,
    collectionId: string,
    userId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRemindLaunchpadsQueryVariables = {
  filter?: ModelRemindLaunchpadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRemindLaunchpadsQuery = {
  listRemindLaunchpads?:  {
    __typename: "ModelRemindLaunchpadConnection",
    items:  Array< {
      __typename: "RemindLaunchpad",
      id: string,
      collectionId: string,
      userId: string,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RemindLaunchpadByCollectionQueryVariables = {
  collectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRemindLaunchpadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RemindLaunchpadByCollectionQuery = {
  remindLaunchpadByCollection?:  {
    __typename: "ModelRemindLaunchpadConnection",
    items:  Array< {
      __typename: "RemindLaunchpad",
      id: string,
      collectionId: string,
      userId: string,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RemindLaunchpadByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRemindLaunchpadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RemindLaunchpadByUserQuery = {
  remindLaunchpadByUser?:  {
    __typename: "ModelRemindLaunchpadConnection",
    items:  Array< {
      __typename: "RemindLaunchpad",
      id: string,
      collectionId: string,
      userId: string,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCandyMachineStatusQueryVariables = {
  id: string,
};

export type GetCandyMachineStatusQuery = {
  getCandyMachineStatus?:  {
    __typename: "CandyMachineStatus",
    id: string,
    collectionId: string,
    status: string,
    startDate: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListCandyMachineStatusesQueryVariables = {
  filter?: ModelCandyMachineStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCandyMachineStatusesQuery = {
  listCandyMachineStatuses?:  {
    __typename: "ModelCandyMachineStatusConnection",
    items:  Array< {
      __typename: "CandyMachineStatus",
      id: string,
      collectionId: string,
      status: string,
      startDate: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CandyMachineStatusByCollectionQueryVariables = {
  collectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCandyMachineStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CandyMachineStatusByCollectionQuery = {
  candyMachineStatusByCollection?:  {
    __typename: "ModelCandyMachineStatusConnection",
    items:  Array< {
      __typename: "CandyMachineStatus",
      id: string,
      collectionId: string,
      status: string,
      startDate: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CandyMachineStatusByStatusAndCreatedAtQueryVariables = {
  status: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCandyMachineStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CandyMachineStatusByStatusAndCreatedAtQuery = {
  candyMachineStatusByStatusAndCreatedAt?:  {
    __typename: "ModelCandyMachineStatusConnection",
    items:  Array< {
      __typename: "CandyMachineStatus",
      id: string,
      collectionId: string,
      status: string,
      startDate: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CandyMachineStatusByStatusAndStartDateQueryVariables = {
  status: string,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCandyMachineStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CandyMachineStatusByStatusAndStartDateQuery = {
  candyMachineStatusByStatusAndStartDate?:  {
    __typename: "ModelCandyMachineStatusConnection",
    items:  Array< {
      __typename: "CandyMachineStatus",
      id: string,
      collectionId: string,
      status: string,
      startDate: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNumberOfMintingQueryVariables = {
  id: string,
};

export type GetNumberOfMintingQuery = {
  getNumberOfMinting?:  {
    __typename: "NumberOfMinting",
    id: string,
    collectionId: string,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNumberOfMintingsQueryVariables = {
  filter?: ModelNumberOfMintingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNumberOfMintingsQuery = {
  listNumberOfMintings?:  {
    __typename: "ModelNumberOfMintingConnection",
    items:  Array< {
      __typename: "NumberOfMinting",
      id: string,
      collectionId: string,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NumberOfMintingByCollectionQueryVariables = {
  collectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNumberOfMintingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NumberOfMintingByCollectionQuery = {
  NumberOfMintingByCollection?:  {
    __typename: "ModelNumberOfMintingConnection",
    items:  Array< {
      __typename: "NumberOfMinting",
      id: string,
      collectionId: string,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemListingTtlQueryVariables = {
  id: string,
};

export type GetItemListingTtlQuery = {
  getItemListingTtl?:  {
    __typename: "ItemListingTtl",
    id?: string | null,
    itemId?: string | null,
    currency?: string | null,
    price?: number | null,
    status?: StatusTtl | null,
    expiredDate?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListItemListingTtlsQueryVariables = {
  filter?: ModelItemListingTtlFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemListingTtlsQuery = {
  listItemListingTtls?:  {
    __typename: "ModelItemListingTtlConnection",
    items:  Array< {
      __typename: "ItemListingTtl",
      id?: string | null,
      itemId?: string | null,
      currency?: string | null,
      price?: number | null,
      status?: StatusTtl | null,
      expiredDate?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemListingByItemIdQueryVariables = {
  itemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemListingTtlFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemListingByItemIdQuery = {
  itemListingByItemId?:  {
    __typename: "ModelItemListingTtlConnection",
    items:  Array< {
      __typename: "ItemListingTtl",
      id?: string | null,
      itemId?: string | null,
      currency?: string | null,
      price?: number | null,
      status?: StatusTtl | null,
      expiredDate?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRedeemCodeQueryVariables = {
  code: string,
};

export type GetRedeemCodeQuery = {
  getRedeemCode?:  {
    __typename: "RedeemCode",
    id: string,
    code: string,
    amount: number,
    isActive: string,
    action: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetAppConfigQueryVariables = {
  key?: string | null,
};

export type GetAppConfigQuery = {
  getAppConfig:  {
    __typename: "ResponseGetAppConfig",
    value: string,
  },
};

export type GetOrderDetailQueryVariables = {
  orderId: string,
};

export type GetOrderDetailQuery = {
  getOrderDetail:  {
    __typename: "ResponseGetOrderDetail",
    orderId: string,
    userId: string,
    itemDetails:  Array< {
      __typename: "ItemOrderDetail",
      id: string,
      name: string,
      imageUrl: string,
      price:  Array< {
        __typename: "ItemPriceOrderDetail",
        amount: number,
        currency: PriceCurrency,
      } >,
    } >,
    tax:  Array< {
      __typename: "ItemTaxOrderDetail",
      taxName: string,
      amount: number,
      currency: PriceCurrency,
    } >,
    status: string,
    action: string,
    totalIDR: number,
    totalSOL: number,
    expiredAt: string,
    createdAt: string,
    updatedAt: string,
  },
};

export type GetPaymentDetailQueryVariables = {
  paymentId: string,
};

export type GetPaymentDetailQuery = {
  getPaymentDetail:  {
    __typename: "ResponseGetPaymentDetail",
    paymentId: string,
    orderId: string,
    userId: string,
    amount: number,
    currency: PriceCurrency,
    paymentType: string,
    status: string,
    expiredAt: string,
    createdAt: string,
    updatedAt: string,
    orderType: string,
    itemDetails:  Array< {
      __typename: "ItemOrderDetail",
      id: string,
      name: string,
      imageUrl: string,
      price:  Array< {
        __typename: "ItemPriceOrderDetail",
        amount: number,
        currency: PriceCurrency,
      } >,
    } >,
    tax:  Array< {
      __typename: "ItemTaxOrderDetail",
      taxName: string,
      amount: number,
      currency: PriceCurrency,
    } >,
    actions:  Array< {
      __typename: "PaymentAction",
      name: string,
      method: string,
      url: string,
    } >,
  },
};

export type GetRateSolanaIDRQueryVariables = {
};

export type GetRateSolanaIDRQuery = {
  getRateSolanaIDR:  {
    __typename: "ResponseGetRateSolanaIDR",
    value: number,
  },
};

export type OnUpdateCollectionTotalItemSubscriptionVariables = {
  collectionId: string,
};

export type OnUpdateCollectionTotalItemSubscription = {
  onUpdateCollectionTotalItem?:  {
    __typename: "MutationCallbackUpdateTotalItemCollection",
    collectionId: string,
    totalItems: number,
  } | null,
};

export type OnCreateUserProfileSubscriptionVariables = {
  filter?: ModelSubscriptionUserProfileFilterInput | null,
  id?: string | null,
};

export type OnCreateUserProfileSubscription = {
  onCreateUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    name: string,
    email?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    description?: string | null,
    type: UserType,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    wallets?:  {
      __typename: "ModelWalletConnection",
      items:  Array< {
        __typename: "Wallet",
        id: string,
        userId: string,
        currency?: string | null,
        publicKey?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    whitelistUser?:  {
      __typename: "ModelWhitelistUserConnection",
      items:  Array< {
        __typename: "WhitelistUser",
        id: string,
        userId: string,
        whitelistId: string,
        ttl: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    executeNFT?:  {
      __typename: "ModelExecuteNFTConnection",
      items:  Array< {
        __typename: "ExecuteNFT",
        id: string,
        orderId: string,
        quantity?: number | null,
        userId: string,
        collectionId: string,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserProfileSubscriptionVariables = {
  filter?: ModelSubscriptionUserProfileFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserProfileSubscription = {
  onUpdateUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    name: string,
    email?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    description?: string | null,
    type: UserType,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    wallets?:  {
      __typename: "ModelWalletConnection",
      items:  Array< {
        __typename: "Wallet",
        id: string,
        userId: string,
        currency?: string | null,
        publicKey?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    whitelistUser?:  {
      __typename: "ModelWhitelistUserConnection",
      items:  Array< {
        __typename: "WhitelistUser",
        id: string,
        userId: string,
        whitelistId: string,
        ttl: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    executeNFT?:  {
      __typename: "ModelExecuteNFTConnection",
      items:  Array< {
        __typename: "ExecuteNFT",
        id: string,
        orderId: string,
        quantity?: number | null,
        userId: string,
        collectionId: string,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserProfileSubscriptionVariables = {
  filter?: ModelSubscriptionUserProfileFilterInput | null,
  id?: string | null,
};

export type OnDeleteUserProfileSubscription = {
  onDeleteUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    name: string,
    email?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    description?: string | null,
    type: UserType,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    wallets?:  {
      __typename: "ModelWalletConnection",
      items:  Array< {
        __typename: "Wallet",
        id: string,
        userId: string,
        currency?: string | null,
        publicKey?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    whitelistUser?:  {
      __typename: "ModelWhitelistUserConnection",
      items:  Array< {
        __typename: "WhitelistUser",
        id: string,
        userId: string,
        whitelistId: string,
        ttl: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    executeNFT?:  {
      __typename: "ModelExecuteNFTConnection",
      items:  Array< {
        __typename: "ExecuteNFT",
        id: string,
        orderId: string,
        quantity?: number | null,
        userId: string,
        collectionId: string,
        ttl?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateWhitelistSubscriptionVariables = {
  filter?: ModelSubscriptionWhitelistFilterInput | null,
};

export type OnCreateWhitelistSubscription = {
  onCreateWhitelist?:  {
    __typename: "Whitelist",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    expiredAt?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWhitelistSubscriptionVariables = {
  filter?: ModelSubscriptionWhitelistFilterInput | null,
};

export type OnUpdateWhitelistSubscription = {
  onUpdateWhitelist?:  {
    __typename: "Whitelist",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    expiredAt?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWhitelistSubscriptionVariables = {
  filter?: ModelSubscriptionWhitelistFilterInput | null,
};

export type OnDeleteWhitelistSubscription = {
  onDeleteWhitelist?:  {
    __typename: "Whitelist",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    expiredAt?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWhitelistUserSubscriptionVariables = {
  filter?: ModelSubscriptionWhitelistUserFilterInput | null,
};

export type OnCreateWhitelistUserSubscription = {
  onCreateWhitelistUser?:  {
    __typename: "WhitelistUser",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    whitelistId: string,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWhitelistUserSubscriptionVariables = {
  filter?: ModelSubscriptionWhitelistUserFilterInput | null,
};

export type OnUpdateWhitelistUserSubscription = {
  onUpdateWhitelistUser?:  {
    __typename: "WhitelistUser",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    whitelistId: string,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWhitelistUserSubscriptionVariables = {
  filter?: ModelSubscriptionWhitelistUserFilterInput | null,
};

export type OnDeleteWhitelistUserSubscription = {
  onDeleteWhitelistUser?:  {
    __typename: "WhitelistUser",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    whitelistId: string,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCollectionSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionFilterInput | null,
  creatorId?: string | null,
};

export type OnCreateCollectionSubscription = {
  onCreateCollection?:  {
    __typename: "Collection",
    id: string,
    slug?: string | null,
    name: string,
    description?: string | null,
    thumbnailUrl?: string | null,
    bannerUrl?: string | null,
    metadataUrl?: string | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    address?: string | null,
    status?: CollectionStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    totalItems?: number | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
  } | null,
};

export type OnUpdateCollectionSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionFilterInput | null,
  creatorId?: string | null,
};

export type OnUpdateCollectionSubscription = {
  onUpdateCollection?:  {
    __typename: "Collection",
    id: string,
    slug?: string | null,
    name: string,
    description?: string | null,
    thumbnailUrl?: string | null,
    bannerUrl?: string | null,
    metadataUrl?: string | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    address?: string | null,
    status?: CollectionStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    totalItems?: number | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
  } | null,
};

export type OnDeleteCollectionSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionFilterInput | null,
  creatorId?: string | null,
};

export type OnDeleteCollectionSubscription = {
  onDeleteCollection?:  {
    __typename: "Collection",
    id: string,
    slug?: string | null,
    name: string,
    description?: string | null,
    thumbnailUrl?: string | null,
    bannerUrl?: string | null,
    metadataUrl?: string | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    address?: string | null,
    status?: CollectionStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    totalItems?: number | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
  } | null,
};

export type OnCreateExecuteNFTSubscriptionVariables = {
  filter?: ModelSubscriptionExecuteNFTFilterInput | null,
};

export type OnCreateExecuteNFTSubscription = {
  onCreateExecuteNFT?:  {
    __typename: "ExecuteNFT",
    id: string,
    orderId: string,
    quantity?: number | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateExecuteNFTSubscriptionVariables = {
  filter?: ModelSubscriptionExecuteNFTFilterInput | null,
};

export type OnUpdateExecuteNFTSubscription = {
  onUpdateExecuteNFT?:  {
    __typename: "ExecuteNFT",
    id: string,
    orderId: string,
    quantity?: number | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteExecuteNFTSubscriptionVariables = {
  filter?: ModelSubscriptionExecuteNFTFilterInput | null,
};

export type OnDeleteExecuteNFTSubscription = {
  onDeleteExecuteNFT?:  {
    __typename: "ExecuteNFT",
    id: string,
    orderId: string,
    quantity?: number | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
  creatorId?: string | null,
};

export type OnCreateItemSubscription = {
  onCreateItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    description?: string | null,
    imageUrl: string,
    metadataUrl: string,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null,
    tradeStateAddress?: string | null,
    recipientAddress?: string | null,
    address?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holdUserId?: string | null,
  } | null,
};

export type OnUpdateItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
  creatorId?: string | null,
};

export type OnUpdateItemSubscription = {
  onUpdateItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    description?: string | null,
    imageUrl: string,
    metadataUrl: string,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null,
    tradeStateAddress?: string | null,
    recipientAddress?: string | null,
    address?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holdUserId?: string | null,
  } | null,
};

export type OnDeleteItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
  creatorId?: string | null,
};

export type OnDeleteItemSubscription = {
  onDeleteItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    description?: string | null,
    imageUrl: string,
    metadataUrl: string,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    creator?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    collectionId: string,
    collection?:  {
      __typename: "Collection",
      id: string,
      slug?: string | null,
      name: string,
      description?: string | null,
      thumbnailUrl?: string | null,
      bannerUrl?: string | null,
      metadataUrl?: string | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      address?: string | null,
      status?: CollectionStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      totalItems?: number | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
    } | null,
    tradeStateAddress?: string | null,
    recipientAddress?: string | null,
    address?: string | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holdUserId?: string | null,
  } | null,
};

export type OnCreateSellSubscriptionVariables = {
  filter?: ModelSubscriptionSellFilterInput | null,
  ownerId?: string | null,
};

export type OnCreateSellSubscription = {
  onCreateSell?:  {
    __typename: "Sell",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    status?: SellStatus | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    reservedFor?: string | null,
    sellDuration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    sellingTypes?: SellingMethod | null,
    currency?: PriceCurrency | null,
    reservedPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    endingPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateSellSubscriptionVariables = {
  filter?: ModelSubscriptionSellFilterInput | null,
  ownerId?: string | null,
};

export type OnUpdateSellSubscription = {
  onUpdateSell?:  {
    __typename: "Sell",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    status?: SellStatus | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    reservedFor?: string | null,
    sellDuration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    sellingTypes?: SellingMethod | null,
    currency?: PriceCurrency | null,
    reservedPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    endingPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteSellSubscriptionVariables = {
  filter?: ModelSubscriptionSellFilterInput | null,
  ownerId?: string | null,
};

export type OnDeleteSellSubscription = {
  onDeleteSell?:  {
    __typename: "Sell",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    status?: SellStatus | null,
    price?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    reservedFor?: string | null,
    sellDuration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    sellingTypes?: SellingMethod | null,
    currency?: PriceCurrency | null,
    reservedPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    endingPrice?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateSellDurationSubscriptionVariables = {
  filter?: ModelSubscriptionSellDurationFilterInput | null,
};

export type OnCreateSellDurationSubscription = {
  onCreateSellDuration?:  {
    __typename: "SellDuration",
    id: string,
    itemId: string,
    sellId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSellDurationSubscriptionVariables = {
  filter?: ModelSubscriptionSellDurationFilterInput | null,
};

export type OnUpdateSellDurationSubscription = {
  onUpdateSellDuration?:  {
    __typename: "SellDuration",
    id: string,
    itemId: string,
    sellId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSellDurationSubscriptionVariables = {
  filter?: ModelSubscriptionSellDurationFilterInput | null,
};

export type OnDeleteSellDurationSubscription = {
  onDeleteSellDuration?:  {
    __typename: "SellDuration",
    id: string,
    itemId: string,
    sellId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePurchaseSubscriptionVariables = {
  filter?: ModelSubscriptionPurchaseFilterInput | null,
};

export type OnCreatePurchaseSubscription = {
  onCreatePurchase?:  {
    __typename: "Purchase",
    id: string,
    orderId?: string | null,
    userId: string,
    itemId: string,
    paymentGatewayName: PurchasePaymentGatewayName,
    bank?: string | null,
    amount?: number | null,
    status: PurchaseStatus,
    virtualAccount?: string | null,
    checkoutUrl?:  Array< {
      __typename: "CheckoutUrl",
      name: string,
      method: string,
      url: string,
    } | null > | null,
    redirectUrl?: string | null,
    metaPaymentNotification?: string | null,
    metaRequestPayment?: string | null,
    metaResponsePayment?: string | null,
    expiredAt?: string | null,
    executeNFTId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    collectionId?: string | null,
    numberOfMintingId?: string | null,
    action?: string | null,
    metaListing?: string | null,
  } | null,
};

export type OnUpdatePurchaseSubscriptionVariables = {
  filter?: ModelSubscriptionPurchaseFilterInput | null,
};

export type OnUpdatePurchaseSubscription = {
  onUpdatePurchase?:  {
    __typename: "Purchase",
    id: string,
    orderId?: string | null,
    userId: string,
    itemId: string,
    paymentGatewayName: PurchasePaymentGatewayName,
    bank?: string | null,
    amount?: number | null,
    status: PurchaseStatus,
    virtualAccount?: string | null,
    checkoutUrl?:  Array< {
      __typename: "CheckoutUrl",
      name: string,
      method: string,
      url: string,
    } | null > | null,
    redirectUrl?: string | null,
    metaPaymentNotification?: string | null,
    metaRequestPayment?: string | null,
    metaResponsePayment?: string | null,
    expiredAt?: string | null,
    executeNFTId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    collectionId?: string | null,
    numberOfMintingId?: string | null,
    action?: string | null,
    metaListing?: string | null,
  } | null,
};

export type OnDeletePurchaseSubscriptionVariables = {
  filter?: ModelSubscriptionPurchaseFilterInput | null,
};

export type OnDeletePurchaseSubscription = {
  onDeletePurchase?:  {
    __typename: "Purchase",
    id: string,
    orderId?: string | null,
    userId: string,
    itemId: string,
    paymentGatewayName: PurchasePaymentGatewayName,
    bank?: string | null,
    amount?: number | null,
    status: PurchaseStatus,
    virtualAccount?: string | null,
    checkoutUrl?:  Array< {
      __typename: "CheckoutUrl",
      name: string,
      method: string,
      url: string,
    } | null > | null,
    redirectUrl?: string | null,
    metaPaymentNotification?: string | null,
    metaRequestPayment?: string | null,
    metaResponsePayment?: string | null,
    expiredAt?: string | null,
    executeNFTId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    collectionId?: string | null,
    numberOfMintingId?: string | null,
    action?: string | null,
    metaListing?: string | null,
  } | null,
};

export type OnCreatePurchaseHoldSubscriptionVariables = {
  filter?: ModelSubscriptionPurchaseHoldFilterInput | null,
};

export type OnCreatePurchaseHoldSubscription = {
  onCreatePurchaseHold?:  {
    __typename: "PurchaseHold",
    id: string,
    orderId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePurchaseHoldSubscriptionVariables = {
  filter?: ModelSubscriptionPurchaseHoldFilterInput | null,
};

export type OnUpdatePurchaseHoldSubscription = {
  onUpdatePurchaseHold?:  {
    __typename: "PurchaseHold",
    id: string,
    orderId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePurchaseHoldSubscriptionVariables = {
  filter?: ModelSubscriptionPurchaseHoldFilterInput | null,
};

export type OnDeletePurchaseHoldSubscription = {
  onDeletePurchaseHold?:  {
    __typename: "PurchaseHold",
    id: string,
    orderId: string,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWalletSubscriptionVariables = {
  filter?: ModelSubscriptionWalletFilterInput | null,
  userId?: string | null,
};

export type OnCreateWalletSubscription = {
  onCreateWallet?:  {
    __typename: "Wallet",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    currency?: string | null,
    publicKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateWalletSubscriptionVariables = {
  filter?: ModelSubscriptionWalletFilterInput | null,
  userId?: string | null,
};

export type OnUpdateWalletSubscription = {
  onUpdateWallet?:  {
    __typename: "Wallet",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    currency?: string | null,
    publicKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteWalletSubscriptionVariables = {
  filter?: ModelSubscriptionWalletFilterInput | null,
  userId?: string | null,
};

export type OnDeleteWalletSubscription = {
  onDeleteWallet?:  {
    __typename: "Wallet",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    currency?: string | null,
    publicKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateBidHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBidHistoryFilterInput | null,
};

export type OnCreateBidHistorySubscription = {
  onCreateBidHistory?:  {
    __typename: "BidHistory",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    sellId: string,
    sell?:  {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    status?: SellStatus | null,
    currency?: PriceCurrency | null,
    bidPrice?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateBidHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBidHistoryFilterInput | null,
};

export type OnUpdateBidHistorySubscription = {
  onUpdateBidHistory?:  {
    __typename: "BidHistory",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    sellId: string,
    sell?:  {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    status?: SellStatus | null,
    currency?: PriceCurrency | null,
    bidPrice?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteBidHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBidHistoryFilterInput | null,
};

export type OnDeleteBidHistorySubscription = {
  onDeleteBidHistory?:  {
    __typename: "BidHistory",
    id: string,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    sellId: string,
    sell?:  {
      __typename: "Sell",
      id: string,
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        description?: string | null,
        imageUrl: string,
        metadataUrl: string,
        ownerId: string,
        creatorId: string,
        collectionId: string,
        tradeStateAddress?: string | null,
        recipientAddress?: string | null,
        address?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        holdUserId?: string | null,
      } | null,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      status?: SellStatus | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      reservedFor?: string | null,
      sellDuration?:  {
        __typename: "Duration",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      auctionMethod?: AuctionMethodType | null,
      sellingTypes?: SellingMethod | null,
      currency?: PriceCurrency | null,
      reservedPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      endingPrice?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    auctionMethod?: AuctionMethodType | null,
    status?: SellStatus | null,
    currency?: PriceCurrency | null,
    bidPrice?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateOfferSubscriptionVariables = {
  filter?: ModelSubscriptionOfferFilterInput | null,
};

export type OnCreateOfferSubscription = {
  onCreateOffer?:  {
    __typename: "Offer",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    currency?: PriceCurrency | null,
    quantity?: number | null,
    duration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    expiredAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateOfferSubscriptionVariables = {
  filter?: ModelSubscriptionOfferFilterInput | null,
};

export type OnUpdateOfferSubscription = {
  onUpdateOffer?:  {
    __typename: "Offer",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    currency?: PriceCurrency | null,
    quantity?: number | null,
    duration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    expiredAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteOfferSubscriptionVariables = {
  filter?: ModelSubscriptionOfferFilterInput | null,
};

export type OnDeleteOfferSubscription = {
  onDeleteOffer?:  {
    __typename: "Offer",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      description?: string | null,
      imageUrl: string,
      metadataUrl: string,
      ownerId: string,
      owner?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      creatorId: string,
      creator?:  {
        __typename: "UserProfile",
        id: string,
        name: string,
        email?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        description?: string | null,
        type: UserType,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      collectionId: string,
      collection?:  {
        __typename: "Collection",
        id: string,
        slug?: string | null,
        name: string,
        description?: string | null,
        thumbnailUrl?: string | null,
        bannerUrl?: string | null,
        metadataUrl?: string | null,
        creatorId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        address?: string | null,
        status?: CollectionStatus | null,
        startDate?: string | null,
        endDate?: string | null,
        totalItems?: number | null,
      } | null,
      tradeStateAddress?: string | null,
      recipientAddress?: string | null,
      address?: string | null,
      price?:  Array< {
        __typename: "Price",
        value: number,
        currency?: PriceCurrency | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      holdUserId?: string | null,
    } | null,
    ownerId: string,
    owner?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    price?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    currency?: PriceCurrency | null,
    quantity?: number | null,
    duration?:  {
      __typename: "Duration",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    expiredAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateUserBalanceSubscriptionVariables = {
  filter?: ModelSubscriptionUserBalanceFilterInput | null,
};

export type OnCreateUserBalanceSubscription = {
  onCreateUserBalance?:  {
    __typename: "UserBalance",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    balance?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    currency?: PriceCurrency | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateUserBalanceSubscriptionVariables = {
  filter?: ModelSubscriptionUserBalanceFilterInput | null,
};

export type OnUpdateUserBalanceSubscription = {
  onUpdateUserBalance?:  {
    __typename: "UserBalance",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    balance?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    currency?: PriceCurrency | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteUserBalanceSubscriptionVariables = {
  filter?: ModelSubscriptionUserBalanceFilterInput | null,
};

export type OnDeleteUserBalanceSubscription = {
  onDeleteUserBalance?:  {
    __typename: "UserBalance",
    id: string,
    userId: string,
    user?:  {
      __typename: "UserProfile",
      id: string,
      name: string,
      email?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      description?: string | null,
      type: UserType,
      collections?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      wallets?:  {
        __typename: "ModelWalletConnection",
        nextToken?: string | null,
      } | null,
      whitelistUser?:  {
        __typename: "ModelWhitelistUserConnection",
        nextToken?: string | null,
      } | null,
      executeNFT?:  {
        __typename: "ModelExecuteNFTConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    deposit?:  {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null,
    balance?:  Array< {
      __typename: "Price",
      value: number,
      currency?: PriceCurrency | null,
    } | null > | null,
    currency?: PriceCurrency | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateCandyMachineNFTOneSubscriptionVariables = {
  filter?: ModelSubscriptionCandyMachineNFTOneFilterInput | null,
};

export type OnCreateCandyMachineNFTOneSubscription = {
  onCreateCandyMachineNFTOne?:  {
    __typename: "CandyMachineNFTOne",
    id: string,
    candyMachineId?: string | null,
    collectionId?: string | null,
    mintingType?: MintingType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCandyMachineNFTOneSubscriptionVariables = {
  filter?: ModelSubscriptionCandyMachineNFTOneFilterInput | null,
};

export type OnUpdateCandyMachineNFTOneSubscription = {
  onUpdateCandyMachineNFTOne?:  {
    __typename: "CandyMachineNFTOne",
    id: string,
    candyMachineId?: string | null,
    collectionId?: string | null,
    mintingType?: MintingType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCandyMachineNFTOneSubscriptionVariables = {
  filter?: ModelSubscriptionCandyMachineNFTOneFilterInput | null,
};

export type OnDeleteCandyMachineNFTOneSubscription = {
  onDeleteCandyMachineNFTOne?:  {
    __typename: "CandyMachineNFTOne",
    id: string,
    candyMachineId?: string | null,
    collectionId?: string | null,
    mintingType?: MintingType | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCollectionStatusTtlSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionStatusTtlFilterInput | null,
};

export type OnCreateCollectionStatusTtlSubscription = {
  onCreateCollectionStatusTtl?:  {
    __typename: "CollectionStatusTtl",
    id: string,
    collectionId?: string | null,
    status?: StatusTtl | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCollectionStatusTtlSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionStatusTtlFilterInput | null,
};

export type OnUpdateCollectionStatusTtlSubscription = {
  onUpdateCollectionStatusTtl?:  {
    __typename: "CollectionStatusTtl",
    id: string,
    collectionId?: string | null,
    status?: StatusTtl | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCollectionStatusTtlSubscriptionVariables = {
  filter?: ModelSubscriptionCollectionStatusTtlFilterInput | null,
};

export type OnDeleteCollectionStatusTtlSubscription = {
  onDeleteCollectionStatusTtl?:  {
    __typename: "CollectionStatusTtl",
    id: string,
    collectionId?: string | null,
    status?: StatusTtl | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnPublishNFTRedeemCodeSubscriptionVariables = {
  orderId: string,
};

export type OnPublishNFTRedeemCodeSubscription = {
  onPublishNFTRedeemCode?:  {
    __typename: "ResponsePublishNFTRedeemCode",
    orderId: string,
    itemId: string,
    itemAddress: string,
    itemName: string,
    itemImageUrl: string,
    redeemCode: string,
    amount: number,
  } | null,
};
