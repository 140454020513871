import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image as ChakraImage,
  Icon,
  IconButton,
  Input,
  InputElementProps,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  StyleProps,
  useBreakpointValue,
  useColorModeValue,
  useDimensions,
  useToken,
  Portal,
} from "@chakra-ui/react";
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  format,
  formatDuration,
  getWeeksInMonth,
  intervalToDuration,
  set,
  startOfISOWeek,
  startOfWeek,
} from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import {
  CaptionProps,
  ClassNames,
  DateFormatter,
  DateRange,
  DayPicker,
  useDayPicker,
  useNavigation,
} from "react-day-picker";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCalendarAlt,
  FaChevronDown,
  FaClock,
} from "react-icons/fa";
import { IoChevronDown, IoClose } from "react-icons/io5";
import {
  MdClose,
  MdDateRange,
  MdRemove,
  MdTimer,
  MdTimerOff,
} from "react-icons/md";
import { InputMenuListState } from "utils/interface";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

interface MenuListProps {
  image: string;
  name: string;
}

interface InputMenuDatePickerProps {
  value?: Date;
  setValue?: (date: Date) => void;
}

const InputMenu = (props: {
  menuList: MenuListProps[];
  type: "menu" | "select" | "searchable";
  value: MenuListProps;
  defaultValue?: MenuListProps;
  placeholder?: string;
  buttonSx?: StyleProps;
  handler?: (item: any) => void;
}) => {
  const {
    menuList,
    type,
    value,
    defaultValue,
    placeholder,
    handler,
    buttonSx,
  } = props;
  const buttonMenu = useRef<HTMLButtonElement>(null);
  const inputMenu = useRef<HTMLInputElement>(null);
  const dimensions = useDimensions(buttonMenu, true);

  const [menuWidth, setMenuWidth] = useState<number>(0);
  const initialSelectedList: MenuListProps = { image: "", name: "" };
  const [search, setSearch] = useState<string>("");
  const [selectedList, setSelectedList] = useState<MenuListProps>(
    defaultValue !== undefined ? defaultValue : initialSelectedList
  );

  useEffect(() => {
    let mount = true;
    if (mount) {
      setMenuWidth(dimensions !== null ? dimensions.borderBox.width : 0);
    }
    return () => {
      mount = false;
    };
  }, [dimensions]);

  if (type == "menu") {
    return (
      <Menu variant={"as-select"}>
        <Flex alignItems={"center"} gap={2}>
          <MenuButton
            ref={buttonMenu}
            as={Button}
            variant={"input"}
            size={"md"}
            w={"fit-content"}
            isDisabled={selectedList.name != ""}
          >
            <Flex alignItems={"center"} gap={4} minH={8}>
              {placeholder}
            </Flex>
          </MenuButton>
          {selectedList.name !== "" && (
            <Button
              variant={"input"}
              size={"md"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              rightIcon={<MdClose />}
              onClick={() => setSelectedList(initialSelectedList)}
            >
              <Flex alignItems={"center"} gap={2}>
                <ChakraImage
                  width={"25px"}
                  height={"25px"}
                  alt={selectedList.name}
                  src={selectedList.image}
                />
                <span>{selectedList.name}</span>
              </Flex>
            </Button>
          )}
        </Flex>
        <Portal>
          <MenuList w={menuWidth} zIndex={20}>
            {menuList
              .filter((f) => f.name !== selectedList.name)
              .map((item, index) => (
                <MenuItem key={index} onClick={() => setSelectedList(item)}>
                  <ChakraImage
                    width={"25px"}
                    height={"25px"}
                    alt={item.name}
                    src={item.image}
                  />
                  <span>{item.name}</span>
                </MenuItem>
              ))}
          </MenuList>
        </Portal>
      </Menu>
    );
  } else if (type == "searchable") {
    return (
      <Menu variant={"as-select"} autoSelect={false}>
        {({ onClose }) => (
          <>
            <Flex flexDir={"column"} pos={"relative"}>
              <InputGroup>
                <Input
                  type={"text"}
                  py={5}
                  ref={inputMenu}
                  variant={"main"}
                  pr={4}
                  placeholder={placeholder}
                  onClick={(e) => {
                    buttonMenu.current?.click();
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                />
                <InputRightElement w={"fit-content"} pr={4}>
                  <ButtonGroup gap={4}>
                    {search !== "" && (
                      <IoClose
                        onClick={() => setSearch("")}
                        cursor={"pointer"}
                      />
                    )}
                    <IoChevronDown />
                  </ButtonGroup>
                </InputRightElement>
              </InputGroup>
              <MenuButton
                ref={buttonMenu}
                pos={"relative"}
                w={"100%"}
                onClick={(e) => inputMenu.current?.focus()}
                visibility={"hidden"}
                p={0}
              />
            </Flex>
            <Portal>
              <MenuList
                w={menuWidth}
                onMouseEnter={() => inputMenu.current?.focus()}
              >
                {menuList.filter((f) => f.name.includes(search)).length < 1 && (
                  <Flex justifyContent={"center"}>No Result</Flex>
                )}
                {menuList
                  .filter((f) => f.name.includes(search))
                  .map((item, index) => (
                    <Flex
                      key={index}
                      p={2}
                      gap={3}
                      cursor={"pointer"}
                      alignItems={"center"}
                      _hover={{ bg: "whiteAlpha.100" }}
                      onClick={() => {
                        setSelectedList(item);
                        handler !== undefined && handler(item);
                        setSearch("");
                        onClose();
                      }}
                    >
                      <ChakraImage
                        width={"25px"}
                        height={"25px"}
                        alt={item.name}
                        src={item.image}
                      />
                      <span>{item.name}</span>
                    </Flex>
                  ))}
              </MenuList>
            </Portal>
          </>
        )}
      </Menu>
    );
  }

  return (
    <Menu variant={"as-select"}>
      <MenuButton
        ref={buttonMenu}
        pos={"relative"}
        w={type == "select" ? "100%" : "fit-content"}
        sx={buttonSx}
        type={"button"}
      >
        {value && value.name !== "" ? (
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Flex
              alignItems={"center"}
              gap={4}
              overflow={"hidden"}
              pr={4}
              minW={"100px"}
            >
              <ChakraImage
                width={"25px"}
                height={"25px"}
                alt={value.name}
                src={value.image}
              />
              <span>{value.name}</span>
            </Flex>
            <Icon as={IoChevronDown} />
          </Flex>
        ) : (
          <Flex alignItems={"center"} gap={4} minH={8}>
            {placeholder}
          </Flex>
        )}
      </MenuButton>
      <Portal>
        <MenuList
          w={type == "select" ? menuWidth : "auto"}
          minW={"100px"}
          zIndex={"modal"}
        >
          {menuList.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setSelectedList(item);
                handler && handler(item.name);
              }}
            >
              <ChakraImage
                width={"25px"}
                height={"25px"}
                alt={item.name}
                src={item.image}
              />
              <span>{item.name}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export const InputWithPrefix = (props: {
  icon?: JSX.Element;
  prefix: string;
  placeholder?: string;
  inputProps?: InputProps;
  leftElementProps?: InputElementProps;
}) => {
  return (
    <InputGroup>
      <InputLeftElement
        color={useColorModeValue("gray.500", "gray.400")}
        pos={"relative"}
        w={"fit-content"}
        display={"flex"}
        gap={3}
        pl={3}
        bg={useColorModeValue("gray.100", "main.spaceCadet")}
        {...props.leftElementProps}
      >
        {props.icon}
        {props.prefix}
      </InputLeftElement>
      <Input
        {...props.inputProps}
        variant="main"
        pl={0}
        placeholder={props.placeholder}
      />
    </InputGroup>
  );
};

export const InputMenuDateRangePicker = (props: {
  value?: DateRange | undefined;
  setRangeValue?: (value: DateRange | undefined) => void;
  isInvalid?: boolean;
}) => {
  const { value, setRangeValue, isInvalid } = props;

  const [gradientMain, mainMagenta, mainCyan, mainBlue] = useToken("colors", [
    "gradient.main",
    "main.magenta",
    "main.cyan",
    "main.blue",
  ]);

  const optionsRange = [
    { value: 30, label: "1 month" },
    { value: 60, label: "2 months" },
    { value: 90, label: "3 months" },
  ];

  const menuButton = useRef<HTMLButtonElement>(null);
  const dimensions = useDimensions(menuButton, true);
  const monthNumber = useBreakpointValue({ base: 1, md: 2 });
  const monthSize = useBreakpointValue({ base: "100%", md: "max-width" });

  const [rangeOption, setRangeOption] = useState<{
    value: number;
    label: string;
  }>(optionsRange[0]);
  const [css, setCss] = useState("");
  const [timeRange, setTimeRange] = useState<DateRange | undefined>(value);

  useEffect(() => {
    setCss(`
      .monthsDatePicker-months {
        gap: 3rem;
        display: flex;
        justify-content: center;
      }

      .monthsDatePicker-month {
        margin: 0;
        width: ${monthSize};
      }

      .buttonDatePicker:hover {
        background-color: ${mainMagenta};
        background-image: none;
        border: 3px solid ${mainCyan};
        cursor: pointer;
        color: white;
        max-width: 40px;
        margin: auto;
        transition: background 0.25s ease-in-out;
      }

      .buttonDatePickerSelected {
        background: ${gradientMain};
        border: none;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }

      .buttonDatePickerSelected:hover {
        background: ${gradientMain};
        border: none;
        max-width: 100%;
      }

      .buttonDatePickerSelectedStart {      
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 40px;
        border-top-left-radius: 40px;
        box-sizing: border-box;
        background: ${gradientMain};
        border: none;
        position: relative;
        max-width: 70%;
        float: right;
      }

      .buttonDatePickerSelectedStart:hover {
        background: ${gradientMain};
        border: none;
        max-width: 70%;
        float: right;
      }

      ${
        value &&
        `.buttonDatePickerSelectedStart::after {
        position: absolute;
        content: '${value?.from?.getDate().toString()}';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: #c51fa0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid ${mainCyan};
        max-width: 40px;
        margin: auto;
      }`
      }
      
      .buttonDatePickerSelectedEnd {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 40px;
        border-top-right-radius: 40px;
        box-sizing: border-box;
        background: ${gradientMain};
        border: none;
        position: relative;
        max-width: 70%;
        margin: auto;
        float:left;
      }

      .buttonDatePickerSelectedEnd:hover {
        background: ${gradientMain};
        border: none;
        max-width: 70%;
        margin: auto;
        float:left;
      }

      ${
        value &&
        `.buttonDatePickerSelectedEnd::after {
        position: absolute;
        content: '${
          value?.to?.getDate().toString() ?? value?.from?.getDate().toString()
        }';
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        background-color: #c51fa0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid ${mainCyan};
        max-width: 40px;
        margin: auto;
      }`
      }

      .tableDatePicker {
        border-collapse: separate; 
        border-spacing: 0 0.5rem;
        width: ${monthSize};
      }

      .datePicker-row {
        width: ${monthSize};
      }

      .datePicker-cell {
        padding: 0;
        text-align: center;
      }

      .datePicker-cell:hover {
        background: transparent;
      }

      .datePicker-cell:first-child {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        overflow: hidden;
      }

      .datePicker-cell:last-child {
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        overflow: hidden;
      }

      .datePicker-day {
        width: 100%;
        min-width: 40px;
      }

      .buttonDatePickerSelectedStart.buttonDatePickerSelectedEnd {
        float: initial;
        max-width: 40px;
        border-radius: 40px;
      }

      .dayPicker-datToday {
        font-weight: 800;
      }

    `);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, dimensions]);

  const datePickerClass: ClassNames = {
    day_today: "dayPicker-datToday",
    months: "monthsDatePicker-months",
    month: "monthsDatePicker-month",
    row: "datePicker-row",
    cell: "datePicker-cell",
    caption: "datePicker-caption",
    caption_label: "datePicker-captionLabel",
    multiple_months: "datePicker-container",
    button: "buttonDatePicker",
    table: "tableDatePicker",
    day_range_start: "buttonDatePickerSelectedStart",
    day_range_middle: "buttonDatePickerSelected",
    day_range_end: "buttonDatePickerSelectedEnd",
    day_disabled: "datePicker-disabled",
    day_outside: "datePicker-disabled",
  };

  return (
    <Menu variant={"main"}>
      <MenuButton
        as={Button}
        w={"full"}
        variant={"input"}
        textAlign={"left"}
        ref={menuButton}
        border={isInvalid ? "2px solid" : ""}
        borderColor={isInvalid ? "red.300" : ""}
      >
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex gap={2} alignItems={"center"}>
            <Icon as={MdDateRange} />
            {value && value.from && value.to
              ? formatDuration(
                  intervalToDuration({
                    start: new Date(value.from),
                    end: new Date(value.to),
                  }),
                  { delimiter: " - " }
                )
              : rangeOption.label}
          </Flex>
          <Icon as={FaChevronDown} boxSize={3} />
        </Flex>
      </MenuButton>
      <Portal>
        <MenuList minWidth={dimensions?.borderBox.width} p={6}>
          <Stack gap={2}>
            <FormControl>
              <FormLabel>Date Range</FormLabel>
              <Select
                variant={"main"}
                onChange={(e) => {
                  setRangeValue && setRangeValue(undefined);
                  setRangeOption(optionsRange[Number(e.target.value)]);
                }}
              >
                {optionsRange.map((i, x) => (
                  <option key={x} value={x}>
                    {i.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Flex
              gap={4}
              flexDirection={{ base: "column", md: "row" }}
              alignItems={"center"}
            >
              <FormControl>
                <FormLabel>Starting</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <Icon as={MdDateRange} color={"blue.600"} />
                  </InputLeftElement>
                  <Input
                    alt={"Select date on date picker"}
                    type={"date"}
                    variant={"main"}
                    disabled
                    defaultValue={
                      value?.from && format(new Date(value?.from), "yyyy-MM-dd")
                    }
                  />
                </InputGroup>
              </FormControl>
              <Box display={{ base: "none", md: "block" }}>
                <Icon as={MdRemove} mt={8} />
              </Box>
              <FormControl>
                <FormLabel>Ending</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <Icon as={MdDateRange} color={"blue.600"} />
                  </InputLeftElement>
                  <Input
                    type={"date"}
                    variant={"main"}
                    disabled
                    defaultValue={
                      value?.to && format(new Date(value?.to), "yyyy-MM-dd")
                    }
                  />
                </InputGroup>
              </FormControl>
            </Flex>
            <Box pt={{ base: 0, lg: 4 }}>
              {css && <style>{css}</style>}
              <DayPicker
                fromYear={2022}
                numberOfMonths={monthNumber}
                classNames={datePickerClass}
                mode={"range"}
                selected={value}
                onSelect={(e) => {
                  setRangeValue && setRangeValue(e);
                }}
                max={rangeOption.value}
              />
            </Box>
            <Flex
              gap={4}
              flexDirection={{ base: "column", md: "row" }}
              alignItems={"center"}
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement>
                    <Icon as={MdTimer} color={"blue.600"} />
                  </InputLeftElement>
                  <Input
                    variant={"main"}
                    type={"time"}
                    value={format(
                      value?.from
                        ? new Date(value.from)
                        : new Date().setHours(0, 0, 0, 0),
                      "HH:mm"
                    )}
                    onChange={(e) => {
                      if (e.target.value && value && value.from) {
                        const time: string[] = e.target.value.split(":");
                        let timeFrom = set(new Date(value.from), {
                          hours: Number(time[0]),
                          minutes: Number(time[1]),
                        });
                        value.from = timeFrom;
                        if (setRangeValue) {
                          setRangeValue(value);
                        }
                      }
                    }}
                  />
                  {value &&
                    value.from &&
                    format(new Date(value.from), "HH:mm") !== "00:00" && (
                      <InputRightElement>
                        <Icon
                          as={MdClose}
                          color={"blue.600"}
                          cursor={"pointer"}
                          onClick={() => {
                            if (value && value.from) {
                              let timeFrom = set(new Date(value.from), {
                                hours: 0,
                                minutes: 0,
                              });
                              value.from = timeFrom;
                              if (setRangeValue) {
                                setRangeValue(value);
                              }
                              console.log(
                                format(new Date(value.from), "HH:mm")
                              );
                            }
                          }}
                        />
                      </InputRightElement>
                    )}
                </InputGroup>
              </FormControl>
              <Box display={{ base: "none", md: "block" }}>
                <Icon as={MdRemove} />
              </Box>
              <FormControl>
                <InputGroup>
                  <InputLeftElement>
                    <Icon as={MdTimerOff} color={"blue.600"} />
                  </InputLeftElement>
                  <Input
                    variant={"main"}
                    type={"time"}
                    value={format(
                      value?.to
                        ? new Date(value.to)
                        : new Date().setHours(0, 0, 0, 0),
                      "HH:mm"
                    )}
                    onChange={(e) => {
                      if (e.target.value && value && value.to) {
                        const time: string[] = e.target.value.split(":");
                        let timeTo = set(new Date(value.to), {
                          hours: Number(time[0]),
                          minutes: Number(time[1]),
                        });
                        value.to = timeTo;
                        if (setRangeValue) {
                          setRangeValue(value);
                        }
                      }
                    }}
                  />
                  {value &&
                    value.to &&
                    format(new Date(value.to), "HH:mm") !== "00:00" && (
                      <InputRightElement>
                        <Icon
                          as={MdClose}
                          color={"blue.600"}
                          cursor={"pointer"}
                          onClick={() => {
                            if (value && value.to) {
                              let timeTo = set(new Date(value.to), {
                                hours: 0,
                                minutes: 0,
                              });
                              value.to = timeTo;
                              if (setRangeValue) {
                                setRangeValue(value);
                              }
                            }
                          }}
                        />
                      </InputRightElement>
                    )}
                </InputGroup>
              </FormControl>
            </Flex>
          </Stack>
        </MenuList>
      </Portal>
    </Menu>
  );
};

const CustomCaption = (props: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb={2}>
      <IconButton
        aria-label={"Previous"}
        variant={"ghost"}
        size={"md"}
        rounded={"full"}
        icon={<Icon as={FaArrowLeft} />}
        isDisabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      />
      <Heading as={"span"} fontSize={"lg"} fontWeight={"semibold"}>
        {format(props.displayMonth, "MMMM yyy")}
      </Heading>
      <IconButton
        aria-label={"Next"}
        variant={"ghost"}
        size={"md"}
        rounded={"full"}
        icon={<Icon as={FaArrowRight} />}
        isDisabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      />
    </Flex>
  );
};

const formatWeekdayName: DateFormatter = (date, option) => {
  return (
    <chakra.span textTransform={"uppercase"}>
      {format(date, "EEE", { locale: option?.locale })}
    </chakra.span>
  );
};

export const InputMenuDatePicker = React.forwardRef<
  HTMLButtonElement,
  InputMenuDatePickerProps
>(
  (
    props: InputMenuDatePickerProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const { value, setValue } = props;

    const [gradientMain, mainCyan] = useToken("colors", [
      "gradient.main",
      "main.cyan",
    ]);

    const [css, setCss] = useState("");

    const nextMonth = addMonths(new Date(), 1);
    const [month, setMonth] = useState<Date>(nextMonth);

    const tomorrow = addDays(new Date(), 1);

    useEffect(() => {
      setCss(`
      .monthsDatePicker-months {
        gap: 3rem;
        display: flex;
        justify-content: center;
      }

      .monthsDatePicker-month {
        margin: 0;
        width: 100%;
      }

      .buttonDatePicker:hover {
        background: ${gradientMain};
        border: 2px solid ${mainCyan};
        cursor: pointer;
        color: white;
        max-width: 40px;
        margin: auto;
        font-weight: bold;
        transition: background 0.25s ease-in-out;
      }

      .buttonDatePickerSelected {
        background: ${gradientMain};
        color: #FFF;
        border: none;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        margin: 0;
      }

      .datePicker-row {
        width: 100%;
      }

      .datePicker-cell {
        padding: 0;
        text-align: center;
      }

      .datePicker-cell:hover {
        background: transparent;
      }

      .datePicker-cell:first-child {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        overflow: hidden;
      }

      .datePicker-cell:last-child {
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        overflow: hidden;
      }

      .datePicker-day {
        width: 100%;
        min-width: 40px;
      }

      .buttonDatePickerSelectedStart.buttonDatePickerSelectedEnd {
        float: initial;
        max-width: 40px;
        border-radius: 40px;
      }

      .dayPicker-dateToday {
        font-weight: 800;
        background: rgba(255, 255, 255, 0.2)
      }

    `);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const datePickerClass: ClassNames = {
      day_today: "dayPicker-dateToday",
      months: "monthsDatePicker-months",
      month: "monthsDatePicker-month",
      row: "datePicker-row",
      cell: "datePicker-cell",
      caption: "datePicker-caption",
      caption_label: "datePicker-captionLabel",
      multiple_months: "datePicker-container",
      button: "buttonDatePicker",
      table: "tableDatePicker",
      day_selected: "buttonDatePickerSelected",
      day_range_start: "buttonDatePickerSelectedStart",
      day_range_middle: "buttonDatePickerSelected",
      day_range_end: "buttonDatePickerSelectedEnd",
      day_disabled: "datePicker-disabled",
      day_outside: "datePicker-disabled",
    };

    useEffect(() => {
      if (value) {
        setMonth(value);
      }
    }, [value]);

    return (
      <Menu variant={"main"} placement={"bottom-end"}>
        <MenuButton
          as={IconButton}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          p={0}
          size={"xs"}
          variant={"unstyled"}
          icon={<Icon as={FaCalendarAlt} opacity={0.4} boxSize={4} />}
          ref={ref}
        />
        <Portal>
          <MenuList p={6} my={2} className={"hideFirefox"}>
            <Stack gap={2}>
              {css && <style>{css}</style>}
              <DayPicker
                mode={"single"}
                fromYear={2023}
                numberOfMonths={1}
                classNames={datePickerClass}
                selected={value}
                month={month}
                onMonthChange={setMonth}
                disabled={{ before: tomorrow }}
                components={{ Caption: CustomCaption }}
                formatters={{ formatWeekdayName }}
                onSelect={(e) => {
                  if (setValue && e) {
                    setValue(e);
                  }
                }}
              />
            </Stack>
          </MenuList>
        </Portal>
      </Menu>
    );
  }
);

InputMenuDatePicker.displayName = "InputMenuDatePicker";

export const InputMenuTimePicker = React.forwardRef<
  HTMLButtonElement,
  InputMenuDatePickerProps
>(
  (
    props: InputMenuDatePickerProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const { value, setValue } = props;

    const swiperHoursRef = useRef<SwiperCore>(null) as any;
    const swiperMinuteRef = useRef<SwiperCore>(null) as any;

    useEffect(() => {
      let mount = true;
      if (value?.getHours() && swiperHoursRef.current) {
        if (mount) {
          swiperHoursRef.current.swiper.slideTo(value.getHours() + 4);
        }
      }

      return () => {
        mount = false;
      };
    }, [swiperHoursRef, value]);

    useEffect(() => {
      let mount = true;
      if (value?.getHours() && swiperMinuteRef.current) {
        if (mount) {
          swiperMinuteRef.current.swiper.slideTo(value.getMinutes() + 4);
        }
      }

      return () => {
        mount = false;
      };
    }, [swiperMinuteRef, value]);

    return (
      <Menu variant={"main"} placement={"bottom-end"}>
        <MenuButton
          as={IconButton}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          p={0}
          size={"xs"}
          variant={"unstyled"}
          icon={<Icon as={FaClock} opacity={0.4} boxSize={4} />}
          ref={ref}
        />
        <Portal>
          <MenuList p={6} my={2} zIndex={"popover"} minW={"300px"}>
            <Grid templateColumns={"1fr 1fr"} gap={2}>
              <GridItem maxH={"300px"} overflow={"hidden"}>
                <Swiper
                  cssMode={false}
                  direction={"vertical"}
                  slidesPerView={7}
                  spaceBetween={5}
                  height={300}
                  modules={[Mousewheel]}
                  mousewheel={{ sensitivity: 10 }}
                  onInit={(core: SwiperCore) => {
                    swiperHoursRef.current = core.el;
                  }}
                  grabCursor
                  loop
                  freeMode
                >
                  {Array.from({ length: 24 }, (_, index) => index + 1).map(
                    (hour, index) => (
                      <SwiperSlide key={index}>
                        <Button
                          w={"full"}
                          textAlign={"center"}
                          size={"sm"}
                          variant={
                            index === value?.getHours() ? "gradient" : "ghost"
                          }
                          borderWidth={"1px"}
                          _hover={{
                            bgGradient:
                              "linear(to-br, main.magenta, main.purple ,main.blue)",
                          }}
                          onClick={() => {
                            if (setValue) {
                              var newDate =
                                value && !isNaN(value.getTime())
                                  ? value
                                  : new Date();
                              newDate?.setHours(index);
                              setValue(newDate);
                            }
                          }}
                        >
                          {index.toString().padStart(2, "0")}
                        </Button>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </GridItem>
              <GridItem maxH={"300px"} overflow={"hidden"}>
                <Swiper
                  cssMode={false}
                  direction={"vertical"}
                  slidesPerView={7}
                  spaceBetween={5}
                  height={300}
                  modules={[Mousewheel]}
                  mousewheel={{ sensitivity: 10 }}
                  onInit={(core: SwiperCore) => {
                    swiperMinuteRef.current = core.el;
                  }}
                  grabCursor
                  loop
                  freeMode
                >
                  {[...Array(60)].map((hour, index) => (
                    <SwiperSlide key={index}>
                      <Button
                        w={"full"}
                        textAlign={"center"}
                        size={"sm"}
                        variant={
                          index === value?.getMinutes() ? "gradient" : "ghost"
                        }
                        borderWidth={"1px"}
                        _hover={{
                          bgGradient:
                            "linear(to-br, main.magenta, main.purple ,main.blue)",
                        }}
                        onClick={() => {
                          if (setValue) {
                            var newDate =
                              value && !isNaN(value.getTime())
                                ? value
                                : new Date();
                            newDate?.setMinutes(index);
                            setValue(newDate);
                          }
                        }}
                      >
                        {index.toString().padStart(2, "0")}
                      </Button>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </GridItem>
            </Grid>
          </MenuList>
        </Portal>
      </Menu>
    );
  }
);

InputMenuTimePicker.displayName = "InputMenuTimePicker";

export const InputMenuSelect = (props: {
  placeholder?: string;
  value?: string | number;
  setValue?: (value: string | number) => void;
  options: InputMenuListState[];
}) => {
  const { placeholder, options, value, setValue } = props;

  const buttonMenu = useRef<HTMLButtonElement>(null);
  const dimensions = useDimensions(buttonMenu, true);

  return (
    <Menu variant={"as-select"}>
      <Flex alignItems={"center"} gap={2}>
        <MenuButton
          ref={buttonMenu}
          as={Button}
          variant={"input"}
          size={"md"}
          w={"full"}
        >
          <Flex alignItems={"center"} gap={4} minH={8} color={""}>
            {value ? (
              <>
                <Icon
                  as={options.filter((f) => f.value == value)[0].icon}
                  boxSize={5}
                />
                <span>{options.filter((f) => f.value == value)[0].label}</span>
              </>
            ) : (
              placeholder
            )}
          </Flex>
        </MenuButton>
      </Flex>
      <Portal>
        <MenuList w={dimensions?.borderBox.width} zIndex={20}>
          {options.map((item, index) => (
            <MenuItem
              key={index}
              display={"flex"}
              gap={4}
              onClick={() => setValue && setValue(item.value)}
            >
              <Icon as={item.icon} boxSize={5} />
              <span>{item.label}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default InputMenu;
