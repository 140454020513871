import { Flex, IconButton, Link, Text, Tooltip } from "@chakra-ui/react";

import { FaCopy } from "react-icons/fa";
import { useCopyLinkToClipboard } from "utils/helpers";

export const TokenTruncate = (props: {
  text: string;
  substring?: number;
  enableCopy?: boolean;
  isFull?: boolean;
  type?: string;
}) => {
  const { text, substring = 6, enableCopy, isFull = false, type } = props;
  const { copyContentToClipboard } = useCopyLinkToClipboard();

  return (
    <Flex gap={2} alignItems={"center"}>
      {enableCopy && (
        <Tooltip
          hasArrow
          variant={"main"}
          label={"Copy to clipboard"}
          aria-label={`Token Copy`}
          textAlign={"center"}
          placement={"top"}
        >
          <IconButton
            aria-label="Copy"
            size={"auto"}
            variant={"unstyled"}
            textAlign={"center"}
            color={"main.blue"}
            onClick={() => copyContentToClipboard(text)}
          >
            <FaCopy />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip
        hasArrow
        variant={"main"}
        label={text}
        aria-label={`Token Tooltip ${text}`}
        textAlign={"center"}
        placement={"bottom"}
      >
        <Link
          href={`https://solscan.io/${type ?? "token"}/${text}?cluster=${
            process.env.NEXT_PUBLIC_AWS_EXPORT_TYPE == "PROD"
              ? "mainnet"
              : "devnet"
          }`}
          target={"_blank"}
          color={"main.blue"}
          _hover={{ textDecoration: "underline" }}
        >
          {isFull
            ? text
            : text.length > substring
            ? text.substring(0, substring) +
              "..." +
              text.substring(text.length - substring, text.length)
            : text}
        </Link>
      </Tooltip>
    </Flex>
  );
};
