import NextLink from "next/link";

import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  chakra,
  Image as ChakraImage,
  Container,
  Flex,
  Heading,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import { Verified } from "components/global/logo";
import { motion } from "framer-motion";
import moment from "moment";
import { MouseEventHandler, ReactElement, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { Collection, CollectionStatus } from "src/API";
import { CardLabel } from "./cards";

export default function CardCollection(props: {
  data: Collection;
  gridSize?: "medium" | "small";
}) {
  const {
    id,
    name,
    description,
    creator,
    slug,
    thumbnailUrl,
    bannerUrl,
    status,
  } = props.data;
  const { gridSize } = props;

  const bgColor = useColorModeValue("white", "gradient.purpleDark");
  const borderColor = useColorModeValue("gray.200", "main.borderBlue");
  const textColor = useColorModeValue("gray.600", "white");
  const bgHover = useColorModeValue("gray.50", "main.newDarkBlue");
  const bgAvatar = useColorModeValue("white", "main.newDarkBlue");

  const isCollectionAvailableEndedSoldout =
    status !== CollectionStatus.COMING_SOON &&
    status !== CollectionStatus.DRAFT;

  return (
    <LinkBox
      as={motion.div}
      layout={"size"}
      alignItems="center"
      rounded="lg"
      position="relative"
      height={"auto"}
      shadow="lg"
      p={"1px"}
      bg={"gradient.blue"}
      _hover={{ shadow: "xl" }}
      cursor={"pointer"}
      overflow={"hidden"}
      role={"group"}
      transition={"box-shadow 0.25s ease-in-out"}
    >
      <Box
        position={"relative"}
        overflow={"hidden"}
        rounded="lg"
        bg={bgColor}
        height={"full"}
        as={motion.div}
        layout={"size"}
      >
        <AspectRatio
          height={{ base: gridSize == "medium" ? "180px" : "120px" }}
          bgColor={"main.spaceCadet"}
        >
          <ChakraImage
            src={
              isCollectionAvailableEndedSoldout
                ? bannerUrl
                  ? bannerUrl
                  : creator?.profileBannerUrl
                  ? creator?.profileBannerUrl
                  : "/images/default-image/default-banner-1.webp"
                : "/images/default-image/coming-soon-banner.webp"
            }
            alt={`Banner of ${name}`}
            objectFit="cover"
            w={"full"}
            h={"full"}
            fallback={<Skeleton w={"full"} h={"full"} />}
          />
        </AspectRatio>
        <Flex
          justify={"center"}
          mt={-7}
          _groupHover={{ bg: bgHover }}
          transition={"background 0.25s ease-in-out"}
        >
          <Avatar
            size={"lg"}
            bg={bgAvatar}
            src={
              thumbnailUrl
                ? thumbnailUrl
                : "/images/default-image/nftone-square.webp"
            }
            borderWidth={3}
            borderColor={bgAvatar}
            _groupHover={{ transform: "translateY(-0.5rem)" }}
            transition={"transform 0.25s ease-in"}
          />
        </Flex>
        <Box
          p={6}
          _groupHover={{ bg: bgHover }}
          transition={"all 0.25s ease-in-out"}
        >
          <Stack
            spacing={0}
            align={"center"}
            pb={4}
            borderBottomWidth={1}
            borderBottomColor={borderColor}
            fontSize={gridSize == "medium" ? "auto" : 14}
          >
            <LinkOverlay
              as={NextLink}
              href={`/collection/${slug}`}
              aria-label={`Read more about ${name}`}
            >
              <Heading
                fontSize={"lg"}
                fontWeight={"bold"}
                textAlign={"center"}
                noOfLines={1}
              >
                {name}
              </Heading>
            </LinkOverlay>
            <Box
              display={"flex"}
              alignItems={"center"}
              color={textColor}
              fontSize={"base"}
              textAlign={"center"}
              gap={1}
            >
              <Text flexShrink={0}>Created By</Text>
              <Link
                as={NextLink}
                href={`/creator/${creator?.name}`}
                display={"inline"}
                color="main.blue"
                flexShrink={1}
                noOfLines={1}
                _hover={{ color: "blue.700" }}
              >
                {creator?.name}
              </Link>
              {creator?.type == "OFFICIAL" && <Verified />}
            </Box>
          </Stack>
          <Box color={textColor} mt={"4"} minHeight={10}>
            <Text noOfLines={2} align="center" fontSize={"sm"}>
              {description}
            </Text>
          </Box>
        </Box>
      </Box>
    </LinkBox>
  );
}

export const CardCollectionGridSkeleton = () => {
  const bgColor = useColorModeValue("white", "gradient.purpleDark");
  const borderColor = useColorModeValue("gray.200", "main.borderBlue");

  return (
    <Box
      p={"1px"}
      bg={"gradient.blue"}
      position={"relative"}
      overflow={"hidden"}
      rounded="lg"
    >
      <Box bg={bgColor} h={"full"} rounded="lg">
        <Box width={"full"} height={{ base: "180px" }} rounded="lg">
          <Skeleton width={"full"} height={"full"} />
        </Box>
        <Flex justify={"center"} mt={-7}>
          <Box rounded={"full"} bg={bgColor} w={"4rem"} h={"4rem"} zIndex={2}>
            <SkeletonCircle size={"4rem"} />
          </Box>
        </Flex>
        <Box p={6}>
          <Stack
            align={"center"}
            pb={8}
            gap={2}
            borderBottomWidth={1}
            borderBottomColor={borderColor}
          >
            <Skeleton h={2} w={"100px"} />
            <Skeleton h={2} w={"200px"} />
          </Stack>
          <Box mt={4}>
            <SkeletonText
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              noOfLines={2}
              spacing={3}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const CardCollectionSmall = (props: { item: Collection }) => {
  const { id, slug, name, thumbnailUrl, creator } = props.item;
  const textColor = useColorModeValue("gray.500", "gray.400");
  const bgHover = useColorModeValue("blackAlpha.50", "blackAlpha.200");

  return (
    <LinkBox
      as={Flex}
      gap={4}
      alignItems={"center"}
      p={2}
      _hover={{ bg: bgHover }}
      pl={4}
    >
      <Avatar
        w={8}
        h={8}
        bg={"main.newDarkBlue"}
        src={
          thumbnailUrl
            ? thumbnailUrl
            : "/images/default-image/nftone-square.webp"
        }
        transition={"all 0.25s ease-in"}
      />
      <Box>
        <Text
          fontSize={"sm"}
          fontWeight={"semibold"}
          lineHeight={1.5}
          noOfLines={1}
        >
          {name}
        </Text>
        <Text
          fontSize={"xs"}
          lineHeight={1.5}
          color={textColor}
          display={"flex"}
          alignItems={"center"}
        >
          by {creator?.name} {creator?.type === "OFFICIAL" && <Verified />}
        </Text>
      </Box>
      <LinkOverlay
        as={NextLink}
        href={`/collection/${slug}`}
        aria-label={`Read more about ${name}`}
      />
    </LinkBox>
  );
};

export const CardCollectionLaunchpad = ({
  collection,
}: {
  collection: Collection;
}) => {
  const bgColor = useColorModeValue("white", "main.darkblue");
  const bgHover = useColorModeValue("gray.50", "main.newDarkBlue");

  const [launchDate, setLaunchDate] = useState<moment.Duration | undefined>(
    collection && collection.startDate
      ? moment.duration(moment(collection.startDate).diff(moment()))
      : undefined
  );
  const [durationLeftLaunchDate, setDurationLeftLaunchDate] = useState<
    string | null
  >();

  useEffect(() => {
    if (launchDate && launchDate.days() > 0) {
      const days = launchDate?.days() ? `${launchDate?.days()}d` : "";
      const hours = launchDate?.hours() ? `${launchDate?.hours()}h` : "";
      const minutes = launchDate?.minutes() ? `${launchDate?.minutes()}m` : "";
      const seconds = launchDate?.seconds() ? `${launchDate?.seconds()}s` : "";

      setDurationLeftLaunchDate(`${days} ${hours} ${minutes} ${seconds}`);
    } else setDurationLeftLaunchDate(null);
  }, [launchDate]);

  useEffect(() => {
    if (collection && collection.startDate) {
      const timer = setInterval(() => {
        setLaunchDate(
          moment.duration(moment(collection.startDate).diff(moment()))
        );
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [collection]);

  const collectionStatus: string | null =
    collection.status === CollectionStatus.COMING_SOON
      ? launchDate?.isValid && durationLeftLaunchDate
        ? durationLeftLaunchDate
        : null
      : String(collection.status?.replace(/_/g, " "));

  return (
    <LinkBox
      title={`Read more about ${collection.name} collection`}
      rounded="lg"
      position="relative"
      p={"1px"}
      shadow={"md"}
      bg={"gradient.blue"}
    >
      <Box
        position={"relative"}
        overflow={"visible"}
        rounded={"lg"}
        bg={bgColor}
        height={"full"}
        transition={"filter 0.25s ease-in-out"}
        role={"group"}
      >
        <Box width={"full"} overflow={"hidden"} roundedTop="lg">
          <AspectRatio
            ratio={1}
            width={"full"}
            overflow={"hidden"}
            _groupHover={{ transform: "scale(1.05)" }}
            transition={"all 0.25s ease-in-out"}
          >
            <ChakraImage
              src={
                collection.thumbnailUrl && collection.thumbnailUrl !== "null"
                  ? collection.thumbnailUrl
                  : "/images/default-image/nftone-square.webp"
              }
              alt={`Picture of Img`}
              objectFit="cover"
              w={"full"}
              h={"full"}
              fallback={<Skeleton w={"full"} h={"full"} />}
            />
          </AspectRatio>
        </Box>
        <VStack
          p={5}
          roundedBottom={"lg"}
          _groupHover={{ bg: bgHover }}
          transition={"all 0.25s ease-in-out"}
        >
          <LinkOverlay
            as={NextLink}
            href={`/launchpad/collection/${collection.slug}`}
            aria-label={`Read more about this collection`}
          >
            <Heading as={"span"} size={"sm"} noOfLines={1}>
              {collection.name}
            </Heading>
          </LinkOverlay>

          <Heading
            as={"span"}
            fontSize={"sm"}
            color={"red.500"}
            fontWeight={"semibold"}
            minHeight={"17px"}
          >
            {collectionStatus}
          </Heading>
          <Flex
            gap={2}
            w={"full"}
            flexDirection={{ base: "row", sm: "column", lg: "row" }}
          >
            <CardLabel>
              <>
                <chakra.strong noOfLines={1}>
                  {collection.totalItems}
                </chakra.strong>{" "}
                <span>ITEMS</span>
              </>
            </CardLabel>
            <CardLabel>
              <>
                <span>PRICE</span>
                <chakra.strong noOfLines={1}>
                  {collection.price && collection.price[0]
                    ? `${collection.price[0].value} ${collection.price[0].currency}`
                    : null}
                </chakra.strong>
              </>
            </CardLabel>
          </Flex>
        </VStack>
      </Box>
    </LinkBox>
  );
};

export const CardCollectionLaunchpadSkeleton = () => {
  const bgColor = useColorModeValue("white", "main.darkblue");
  const bgHover = useColorModeValue("gray.50", "main.newDarkBlue");

  return (
    <LinkBox
      as={Box}
      rounded="lg"
      position="relative"
      p={"1px"}
      shadow={"md"}
      bg={"gradient.blue"}
    >
      <Box
        position={"relative"}
        overflow={"visible"}
        rounded={"lg"}
        bg={bgColor}
        height={"full"}
        transition={"filter 0.25s ease-in-out"}
      >
        <Box width={"full"} overflow={"hidden"} roundedTop="lg">
          <AspectRatio
            ratio={1}
            width={"full"}
            overflow={"hidden"}
            _groupHover={{ transform: "scale(1.05)" }}
            transition={"all 0.25s ease-in-out"}
          >
            <Skeleton w={"full"} h={"full"} />
          </AspectRatio>
        </Box>
        <VStack
          p={5}
          roundedBottom={"lg"}
          _groupHover={{ bg: bgHover }}
          transition={"all 0.25s ease-in-out"}
          gap={1.5}
        >
          <Skeleton h={4} w={"40%"} />
          <Skeleton h={3} w={"60%"} />
          <Flex gap={2} w={"full"}>
            <Skeleton h={6} w={"50%"} />
            <Skeleton h={6} w={"50%"} />
          </Flex>
        </VStack>
      </Box>
    </LinkBox>
  );
};

export const ContainerCollection = ({
  collection,
}: {
  collection: Collection;
}) => {
  const swiperPageOverlay = useColorModeValue(
    "blackAlpha.500",
    "blackAlpha.600"
  );

  return (
    <Flex alignItems={"center"} h={"550px"}>
      <Container maxW={"container.xl"} py={{ base: 8, md: 20 }}>
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={{ base: "column-reverse", md: "row" }}
          gap={4}
        >
          <Stack
            zIndex={"3"}
            gap={8}
            width={{ base: "full", md: "60%", lg: "50%" }}
            textAlign={{ base: "center", md: "initial" }}
            color={"gray.50"}
          >
            <Heading as={"h3"}>{collection.name}</Heading>
            <Text noOfLines={{ base: 3, md: 6 }}>{collection.description}</Text>
            <Flex gap={4} justifyContent={{ base: "center", md: "flex-start" }}>
              {collection.status === CollectionStatus.AVAILABLE ? (
                <Button
                  as={NextLink}
                  href={`/launchpad/collection/${collection.slug}`}
                  title={"Mint collection"}
                  variant={"gradient"}
                  size={"lg"}
                  px={12}
                  shadow={"md"}
                >
                  Mint Now
                </Button>
              ) : (
                <Button
                  as={NextLink}
                  href={`/launchpad/collection/${collection.slug}`}
                  title={"View detail collection"}
                  variant={"gradient"}
                  size={"lg"}
                  px={12}
                >
                  View Collection
                </Button>
              )}
            </Flex>
          </Stack>
          <Box>
            <AspectRatio
              ratio={1}
              w={{ base: 32, md: 96 }}
              overflow={"hidden"}
              rounded={"lg"}
            >
              <ChakraImage src={String(collection.thumbnailUrl)} />
            </AspectRatio>
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};

export const LaunchpadCollectionBox = ({
  type = "box",
  image,
  onClick,
  href,
  children,
}: {
  type?: "box" | "add" | "draft";
  onClick?: MouseEventHandler<HTMLDivElement>;
  href: string;
  image?: string;
  children?: ReactElement;
}) => {
  const bg = useColorModeValue("gray.100", "spaceCadet.800");
  const textColor = useColorModeValue("gray.400", "spaceCadet.500");
  const borderColor = useColorModeValue("gray.400", "gray.500");

  return (
    <LinkBox as={"article"}>
      <Flex
        border={type !== "add" ? "none" : "2px dashed"}
        borderColor={borderColor}
        bg={bg}
        bgRepeat={"no-repeat"}
        bgPosition={"center"}
        h={"200px"}
        rounded={"lg"}
        justifyContent={"center"}
        alignItems={"center"}
        cursor={type == "draft" ? "default" : "pointer"}
        transition={"all 0.25s ease-in-out"}
        overflow={"hidden"}
        role={"group"}
        shadow={"md"}
        _hover={
          type == "add"
            ? {
                filter: "brightness(110%)",
              }
            : {
                _after: {
                  transform: "scale(1.05)",
                },
              }
        }
      >
        {type == "add" && (
          <VStack>
            <Icon
              as={MdAdd}
              boxSize={12}
              bg={textColor}
              rounded={"lg"}
              color={bg}
            />
            <Text
              as={"span"}
              size={"md"}
              fontWeight={"semibold"}
              color={textColor}
              textAlign={"center"}
            >
              Create New Collection
            </Text>
          </VStack>
        )}
        {type == "box" || type == "draft" ? (
          <ChakraImage
            src={image ?? "/images/default-image/nftone-square.webp"}
            transition={"all .25s ease-in-out"}
            boxSize={!image ? 32 : undefined}
            _groupHover={{ transform: "scale(1.05)" }}
          />
        ) : null}
        <LinkOverlay href={href}></LinkOverlay>
      </Flex>
      {children}
    </LinkBox>
  );
};

export const LaunchpadCollectionBoxSkeleton = () => {
  return (
    <Box>
      <Skeleton h={"200px"} w={"full"} rounded={"lg"} />
      <Flex py={2} gap={2}>
        <Skeleton h={3} w={12} />
        <Skeleton h={3} w={32} />
      </Flex>
    </Box>
  );
};
