import Router from 'next/router';
import { useEffect, useState } from 'react';

import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Image as ChakraImage,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { Listing } from '@metaplex-foundation/js';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

import { MainButton } from 'components/global/accent-button';
import InfoPopover from 'components/global/info-popover';
import InputMenu from 'components/global/input-menu';
import { PriceInput } from 'components/global/input-price';
import { SuccessLogo } from 'components/global/logo';
import { TokenTruncate } from 'components/global/token-truncate';
import { SolanaIcon } from 'components/layout/logo';
import { PriceAndBalanceInfo } from 'components/listing/payment';

import { FaArrowLeft, FaChevronLeft, FaCopy } from 'react-icons/fa';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { IoRefresh } from 'react-icons/io5';

import { GraphQLQuery } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import {
  ParameterStoreQuery,
  Price,
  SolTransferMutation,
  SolTransferMutationVariables,
  Wallet,
  WalletByPublicKeyQuery,
  WalletByPublicKeyQueryVariables,
  GetAppConfigQuery,
} from 'src/API';
import { solTransfer } from 'src/graphql/mutations';
import {
  parameterStore,
  walletByPublicKey,
  getAppConfig,
} from 'src/graphql/queries';
import { useParameterStore } from 'utils/context';
import {
  formatIDR,
  useBalanceSol,
  useBlockChainMenuList,
  useCopyLinkToClipboard,
  validateSolanaAddressAccountWithBalance,
} from 'utils/helpers';
import { createStatusCallback } from 'utils/interface';

interface WalletModalProps extends ModalProps {
  defaultIndex?: number;
  solToIdrPrice?: number | null;
  listingData?: Listing | null;
  price?: Price | null;
  handleRefresh?: () => void;
}

export const ModalTopUp = (props: WalletModalProps) => {
  const {
    defaultIndex = 0,
    onClose,
    isOpen,
    price,
    listingData,
    handleRefresh,
  } = props;

  const { copyLinkAddressToClipboard, copyContentToClipboard } =
    useCopyLinkToClipboard();
  const { idrBalance, userProfile } = useParameterStore();
  const { accInfo, getBalanceSol } = useBalanceSol(userProfile);

  const [tabIndex, setTabIndex] = useState(defaultIndex);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Modal
      size={'3xl'}
      variant={'main'}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={tabIndex != 3}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'left'}>
          {tabIndex == 0 && 'Top Up Solana'}
          {tabIndex == 1 && 'Midtrans Payment'}
          {tabIndex == 2 && 'Top Up Result'}
          {tabIndex == 3 && 'Deposit Solana'}
        </ModalHeader>
        {(tabIndex == 0 || tabIndex == 1) && <ModalCloseButton />}
        <ModalBody marginTop={0} pb={8}>
          <Tabs
            variant={'unstyled'}
            index={tabIndex}
            onChange={handleTabsChange}
          >
            <TabPanels>
              {/* Wallet Top Up Insuficient */}
              <TabPanel p={0}>
                <Stack gap={6}>
                  <Text>
                    Balance SOL kamu tidak mencukupi untuk melakukan pembelian
                    NFT, apakah kamu ingin melakukan top-up SOL?
                  </Text>
                  <Flex gap={8}>
                    <Box w={'50%'}>
                      <Heading as={'span'} size={'sm'}>
                        Harga Item
                      </Heading>
                      <Flex
                        p={4}
                        gap={4}
                        mt={2}
                        alignItems={'center'}
                        rounded={'sm'}
                        border={'4px solid'}
                        borderColor={useColorModeValue(
                          'gray.300',
                          'main.spaceCadet'
                        )}
                        bg={useColorModeValue('gray.300', 'main.spaceCadet')}
                      >
                        <Box w={12}>
                          <SolanaIcon />
                        </Box>
                        <Heading
                          as={'span'}
                          fontWeight={'semibold'}
                          size={'lg'}
                        >
                          {listingData?.price
                            ? listingData?.price.basisPoints.toNumber() /
                                LAMPORTS_PER_SOL +
                              ' ' +
                              listingData?.price.currency.symbol
                            : price?.value + ' ' + price?.currency}
                        </Heading>
                      </Flex>
                    </Box>
                    <Box w={'50%'}>
                      <Heading as={'span'} size={'sm'}>
                        Balance SOL kamu
                      </Heading>
                      <Flex
                        p={4}
                        mt={2}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        rounded={'sm'}
                        border={'4px solid'}
                        borderColor={useColorModeValue(
                          'gray.300',
                          'main.spaceCadet'
                        )}
                      >
                        <Flex gap={4} alignItems={'center'}>
                          <Box w={12}>
                            <SolanaIcon />
                          </Box>
                          <Heading
                            as={'span'}
                            fontWeight={'semibold'}
                            size={'lg'}
                          >
                            {accInfo?.balance} SOL
                          </Heading>
                        </Flex>
                        <IconButton
                          aria-label="Refresh Balance"
                          variant={'ghost'}
                          fontSize={'3xl'}
                          rounded={'full'}
                          icon={<IoRefresh />}
                          onClick={() => getBalanceSol()}
                        />
                      </Flex>
                    </Box>
                  </Flex>
                  <Box>
                    <Text as={Flex} gap={1} alignItems={'center'}>
                      Estimasi ~{' '}
                      {idrBalance &&
                        Intl.NumberFormat('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                        }).format(
                          listingData
                            ? (listingData?.price.basisPoints.toNumber() /
                                LAMPORTS_PER_SOL) *
                                idrBalance
                            : price
                            ? price.value * idrBalance
                            : 0
                        )}
                      <InfoPopover placement={'right'}>
                        Harga yang tertera berikut hanyalah estimasi. Harga
                        final akan terlihat jika kamu melanjutkan proses top up.
                      </InfoPopover>
                    </Text>
                  </Box>
                </Stack>
              </TabPanel>
              {/* Top Up Midtrans Payment */}
              <TabPanel p={0}>
                <Stack
                  gap={6}
                  bg={'white'}
                  minH={'400px'}
                  onClick={() => setTabIndex(2)}
                ></Stack>
              </TabPanel>
              {/* Top Up Result */}
              <TabPanel p={0}>
                <VStack gap={6}>
                  <SuccessLogo size="sm" />
                  <Text align={'center'} fontSize={'lg'}>
                    Saldo SOL berhasil di Top Up! Silakan refresh wallet kamu
                    jika saldo belum berubah
                  </Text>
                </VStack>
              </TabPanel>
              {/* Deposit Solana */}
              <TabPanel p={0}>
                <VStack gap={4}>
                  <Text align={'center'}>
                    Kamu bisa mengirimkan Solana dari wallet lain ke wallet akun
                    NFTONE.io milikmu ini.
                  </Text>
                  <Box w={64} h={64} bg={'white'}></Box>
                  <VStack>
                    <Box
                      p={4}
                      bg={useColorModeValue('gray.300', 'main.spaceCadet')}
                    >
                      <Text>EeK1rioNRnz7ZLGxiBrUfBMcN3cgK7zTNahfGuY2r2aG</Text>
                    </Box>
                    <Button
                      variant={'unstyled'}
                      leftIcon={<FaCopy />}
                      size={'sm'}
                      onClick={() =>
                        copyContentToClipboard(
                          'EeK1rioNRnz7ZLGxiBrUfBMcN3cgK7zTNahfGuY2r2aG'
                        )
                      }
                    >
                      click to copy address
                    </Button>
                  </VStack>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter
          borderTopWidth={1}
          borderColor={'main.magenta'}
          pt={8}
          gap={4}
        >
          {/* Insuficient SOL Footer */}
          {tabIndex == 0 && (
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              w={'full'}
              gap={8}
            >
              <Flex gap={2} w={'50%'}>
                <Button
                  variant={'solid'}
                  bg={'red.500'}
                  _hover={{ bg: 'red.600' }}
                  onClick={() => setTabIndex(3)}
                >
                  Deposit SOL
                </Button>
              </Flex>
              <Flex gap={2} w={'50%'}>
                <MainButton
                  width={'full'}
                  onClick={() => {
                    onClose();
                    setTabIndex(0);
                  }}
                >
                  Cancel
                </MainButton>
                <Button
                  variant={'gradient'}
                  onClick={() => setTabIndex(1)}
                  width={'full'}
                >
                  Top Up Wallet
                </Button>
              </Flex>
            </Flex>
          )}
          {/* Result Footer */}
          {tabIndex == 2 && (
            <Flex gap={8} w={'full'}>
              <MainButton
                width={'full'}
                onClick={() => {
                  setTabIndex(0);
                  Router.push('/wallet');
                }}
              >
                Lihat di Wallet
              </MainButton>
              <Button
                width={'full'}
                variant={'gradient'}
                onClick={() => {
                  onClose();
                  setTabIndex(0);
                }}
              >
                Lanjutkan Pembelian
              </Button>
            </Flex>
          )}
          {/* Deposit Solana Footer */}
          {tabIndex == 3 && (
            <Flex w={'full'} justifyContent={'flex-start'}>
              <MainButton
                onClick={() => {
                  if (defaultIndex) {
                    onClose();
                  } else {
                    setTabIndex(0);
                  }
                }}
              >
                <Flex gap={4} px={8}>
                  <FaArrowLeft />
                  <span>Kembali</span>
                </Flex>
              </MainButton>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const ModalSendSol = (props: WalletModalProps) => {
  const { defaultIndex = 0, onClose, isOpen, handleRefresh } = props;

  const {
    isOpen: isAlertVisible,
    onClose: onCloseAlertVisible,
    onOpen: onOpenAlertVisible,
  } = useDisclosure({ defaultIsOpen: true });
  const { blockchainMenuList } = useBlockChainMenuList();
  const { userProfile, idrBalance, connectionUrl } = useParameterStore();
  const { accInfo, getBalanceSol, loaded } = useBalanceSol(userProfile);

  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [sendDataSol, setSendDataSol] = useState<{
    address?: string;
    total: number;
  }>({ total: 0 });
  const [addressIsValid, setAddressIsValid] = useState<boolean>();
  const [userProfileTargettedAddress, setUserProfileTargettedAddress] =
    useState<Wallet | null>(null);

  const [sendSolResultStatus, setSendSolResultStatus] = useState<
    createStatusCallback & { signature?: string | null }
  >();
  const [transferFee, setTranferFee] = useState<number>(0);

  const totalTransfer = parseFloat(
    (transferFee + sendDataSol.total).toFixed(10)
  );

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const checkSolanaAddressTarget = async (address?: string) => {
    try {
      if (connectionUrl && address) {
        const checkAccountInSolanaBlockchain =
          await validateSolanaAddressAccountWithBalance(address, connectionUrl);

        if (checkAccountInSolanaBlockchain.valueOf()) {
          setAddressIsValid(true);

          const variable: WalletByPublicKeyQueryVariables = {
            publicKey: address,
          };

          await API.graphql<GraphQLQuery<WalletByPublicKeyQuery>>({
            query: walletByPublicKey,
            variables: variable,
          })
            .then((res) => {
              if (
                res.data?.walletByPublicKey?.items &&
                res.data?.walletByPublicKey?.items.length > 0
              ) {
                setUserProfileTargettedAddress(
                  res.data.walletByPublicKey.items[0]
                );
              } else {
                setUserProfileTargettedAddress(null);
              }
            })
            .catch((error) => {
              console.log(error);
              setUserProfileTargettedAddress(null);
            });
        } else {
          setAddressIsValid(false);
          setUserProfileTargettedAddress(null);
        }
      }
    } catch (error) {
      console.log(error);
    }

    onOpenAlertVisible();
  };

  const submitSendSOL = async () => {
    try {
      if (!sendDataSol.address) return null;

      setSendSolResultStatus((prev) => ({
        ...prev,
        status: 'loading',
        message: null,
      }));

      const variable: SolTransferMutationVariables = {
        amount: sendDataSol.total,
        to: sendDataSol.address,
      };

      const submitSendSolMutation = await API.graphql<
        GraphQLQuery<SolTransferMutation>
      >({
        query: solTransfer,
        variables: variable,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });

      if (!submitSendSolMutation.data?.solTransfer?.errorMessage) {
        setSendSolResultStatus({
          status: true,
          message: 'Success transfer SOL to targeted address',
          signature: submitSendSolMutation.data?.solTransfer?.signature,
        });
        setTabIndex(2);
      } else {
        console.log(submitSendSolMutation.data);
        setSendSolResultStatus({
          status: false,
          message: submitSendSolMutation.data?.solTransfer?.errorMessage,
        });
      }
    } catch (error) {
      console.log(error);
      setSendSolResultStatus({
        status: false,
        message: 'Failed transfer SOL, please try again later',
      });
    }
  };

  useEffect(() => {
    let isMount = true;

    const getTransferFee = async () => {
      await API.graphql<GraphQLQuery<GetAppConfigQuery>>({
        query: getAppConfig,
        // query: parameterStore,
        variables: {
          key: 'nft.transferSolFee',
        },
      })
        .then((res) => {
          if (res.data?.getAppConfig?.value) {
            if (isMount) setTranferFee(parseFloat(res.data.getAppConfig.value));
          }
        })
        .catch((error) => console.log(error));
    };

    getTransferFee();

    return () => {
      isMount = false;
    };
  }, []);

  return (
    <Modal
      size={'3xl'}
      variant={'main'}
      onClose={() => {
        sendSolResultStatus?.status === 'loading' ? {} : onClose();
      }}
      isOpen={isOpen}
      scrollBehavior={'inside'}
      closeOnEsc={false}
      isCentered
      closeOnOverlayClick={tabIndex != 3}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'left'}>
          {tabIndex == 0 && 'Send Solana'}
          {tabIndex == 1 && 'Send Solana'}
          {tabIndex == 2 && 'Send On Progress'}
        </ModalHeader>
        {(tabIndex == 0 || tabIndex == 1) && (
          <ModalCloseButton
            isDisabled={sendSolResultStatus?.status === 'loading'}
          />
        )}
        <ModalBody marginTop={0} pb={8} p={0}>
          <Tabs
            variant={'unstyled'}
            index={tabIndex}
            onChange={handleTabsChange}
          >
            <TabPanels>
              {/* Send Solana */}
              <TabPanel p={12}>
                <Stack gap={4}>
                  <PriceAndBalanceInfo
                    balance={accInfo?.balance ?? 0}
                    balanceLoaded={loaded}
                    getBalance={getBalanceSol}
                  />
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Stack gap={2}>
                      <FormControl>
                        <FormLabel>Address</FormLabel>
                        <Input
                          variant={'main'}
                          placeholder={'SOL address target'}
                          onChange={(e) =>
                            setSendDataSol((d) => ({
                              ...d,
                              address: e.target.value,
                            }))
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <Flex>
                          <FormLabel>Amount</FormLabel>
                        </Flex>
                        <InputGroup variant={'main'}>
                          <PriceInput
                            max={accInfo?.balance}
                            onChange={(value) =>
                              setSendDataSol((d) => ({
                                ...d,
                                total: Number(value ? value : 0),
                              }))
                            }
                          />
                          <InputRightAddon px={0}>
                            <InputMenu
                              menuList={blockchainMenuList}
                              type={'select'}
                              value={
                                blockchainMenuList.filter(
                                  (f) => f.name == 'SOL'
                                )[0]
                              }
                              handler={(value) => {}}
                              buttonSx={{ bg: 'transparent' }}
                            />
                          </InputRightAddon>
                        </InputGroup>
                        <FormHelperText>
                          <Flex w={'full'} justifyContent={'space-between'}>
                            <span>
                              {formatIDR(
                                sendDataSol.total && idrBalance
                                  ? sendDataSol.total * idrBalance
                                  : 0,
                                'id-ID'
                              )}
                            </span>
                          </Flex>
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </form>
                </Stack>
              </TabPanel>
              {/* Send Solana Summary */}
              <TabPanel p={0}>
                <Stack
                  gap={0}
                  divider={<Divider opacity={1} borderColor={'main.magenta'} />}
                >
                  <Grid
                    templateColumns={'3fr 1fr 3fr'}
                    alignItems={'center'}
                    px={12}
                    py={6}
                  >
                    <GridItem
                      display={'flex'}
                      alignItems={'center'}
                      flexDirection={{ base: 'column', lg: 'row' }}
                      gap={2}
                      minWidth={0}
                    >
                      <Avatar
                        bgGradient={
                          'linear(to-br, main.magenta, main.purple ,main.blue)'
                        }
                      />
                      <Box textAlign={{ base: 'center', lg: 'left' }}>
                        <Heading as={'span'} noOfLines={1} fontSize={'md'}>
                          {userProfile?.name}
                        </Heading>
                        {accInfo?.publicKey ? (
                          <TokenTruncate text={accInfo.publicKey} />
                        ) : null}
                      </Box>
                    </GridItem>
                    <GridItem
                      display={'flex'}
                      justifyContent={'center'}
                      minWidth={0}
                    >
                      <Icon as={HiArrowNarrowRight} boxSize={6} />
                    </GridItem>
                    <GridItem
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'flex-end'}
                      flexDirection={{ base: 'column-reverse', lg: 'row' }}
                      gap={2}
                      minWidth={0}
                    >
                      <Box textAlign={{ base: 'center', lg: 'right' }}>
                        {userProfileTargettedAddress ? (
                          <Heading as={'span'} noOfLines={1} fontSize={'md'}>
                            {userProfileTargettedAddress?.user?.name}
                          </Heading>
                        ) : null}
                        {sendDataSol.address ? (
                          <TokenTruncate text={sendDataSol.address} />
                        ) : null}
                      </Box>
                      <Avatar
                        bgGradient={
                          'linear(to-br, main.magenta, main.purple ,main.blue)'
                        }
                      />
                    </GridItem>
                    <GridItem colSpan={7}>
                      <Alert
                        mt={6}
                        status={addressIsValid ? 'info' : 'error'}
                        width={'full'}
                        hidden={!isAlertVisible}
                      >
                        <AlertIcon />
                        <Box>
                          {addressIsValid
                            ? `The target Solana account address is registered ${
                                userProfileTargettedAddress
                                  ? 'in Solana blockhain and NFTOne'
                                  : 'only in Solana blockhain'
                              }. Please double-check once again that the address you entered matches your desired destination.`
                            : 'The target Solana address is invalid. Please ensure that the target Solana account address is accurate and registered in Solana blockchain.'}
                        </Box>
                        <CloseButton
                          size={'sm'}
                          rounded={'full'}
                          alignSelf={'flex-start'}
                          position={'relative'}
                          right={-1}
                          top={-1}
                          onClick={onCloseAlertVisible}
                        />
                      </Alert>
                    </GridItem>
                  </Grid>
                  <Grid templateColumns={'1fr 1fr'} gap={4} px={12} py={8}>
                    <GridItem colSpan={2}>
                      <Heading as={'span'} noOfLines={1} fontSize={'md'}>
                        Details
                      </Heading>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Divider
                        borderColor={useColorModeValue('gray.600', 'gray.100')}
                      />
                    </GridItem>
                    <GridItem>Send Amount</GridItem>
                    <GridItem textAlign={'right'} fontWeight={'semibold'}>
                      {sendDataSol.total} SOL
                    </GridItem>
                    <GridItem>Transfer Fee</GridItem>
                    <GridItem textAlign={'right'} fontWeight={'semibold'}>
                      {transferFee} SOL
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Divider
                        borderColor={useColorModeValue('gray.600', 'gray.100')}
                      />
                    </GridItem>
                    <GridItem>
                      <Heading as={'span'} fontSize={'md'}>
                        Total
                      </Heading>
                    </GridItem>
                    <GridItem textAlign={'right'} fontWeight={'semibold'}>
                      {totalTransfer} SOL
                    </GridItem>
                  </Grid>
                </Stack>
              </TabPanel>
              {/* Send Solana Result */}
              <TabPanel px={12} py={8}>
                <VStack gap={6}>
                  <ChakraImage
                    alt={'Rocket'}
                    src={'/images/ic_baseline-rocket-launch.svg'}
                    width={'250px'}
                    height={'250px'}
                  />
                  <Text
                    align={'center'}
                    fontSize={'lg'}
                    fontWeight={'semibold'}
                  >
                    SOL is on its way to a target solana address. Please check
                    your wallet periodically.
                  </Text>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter
          borderTopWidth={1}
          borderColor={'main.magenta'}
          pt={8}
          gap={4}
        >
          <Flex
            gap={4}
            w={'full'}
            justifyContent={'space-between'}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            {/* Send Solana Footer */}
            {tabIndex == 0 ? (
              <>
                <MainButton
                  w={{ base: 'full', md: 'max-content' }}
                  px={12}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </MainButton>
                <Button
                  w={{ base: 'full', md: 'max-content' }}
                  px={12}
                  isDisabled={
                    !sendDataSol.address || sendDataSol.total <= 0 || !loaded
                  }
                  variant={'gradient'}
                  onClick={() => {
                    setTabIndex(1);
                    checkSolanaAddressTarget(sendDataSol.address);
                  }}
                >
                  Next
                </Button>
              </>
            ) : tabIndex == 1 ? (
              <>
                <MainButton
                  w={{ base: 'full', md: 'max-content' }}
                  px={12}
                  isDisabled={sendSolResultStatus?.status === 'loading'}
                  onClick={() => {
                    setTabIndex(0);
                  }}
                >
                  <Icon as={FaChevronLeft} mr={3} />
                  Back
                </MainButton>
                <Button
                  w={{ base: 'full', md: 'max-content' }}
                  px={12}
                  variant={'gradient'}
                  isLoading={sendSolResultStatus?.status === 'loading'}
                  isDisabled={!addressIsValid}
                  loadingText={'Sending SOL...'}
                  onClick={() => {
                    submitSendSOL();
                  }}
                >
                  Proceed
                </Button>
              </>
            ) : tabIndex == 2 ? (
              <>
                <MainButton
                  w={{ base: 'full', md: 'max-content' }}
                  px={12}
                  onClick={() => {
                    onClose();
                    setTabIndex(0);
                    Router.push('/wallet');
                  }}
                >
                  View your wallet
                </MainButton>
                <Button
                  w={{ base: 'full', md: 'max-content' }}
                  px={12}
                  variant={'gradient'}
                  onClick={() => {
                    onClose();
                    setTabIndex(0);
                  }}
                >
                  Close
                </Button>
              </>
            ) : null}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
