/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const signUp = /* GraphQL */ `mutation SignUp($name: String!, $email: String!, $password: String!) {
  signUp(name: $name, email: $email, password: $password) {
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SignUpMutationVariables,
  APITypes.SignUpMutation
>;
export const createPayment = /* GraphQL */ `mutation CreatePayment($input: CreatePaymentInput) {
  createPayment(input: $input) {
    purchaseId
    amount
    success
    bank
    virtualAccount
    checkoutUrl {
      name
      method
      url
      __typename
    }
    expiredAt
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentMutationVariables,
  APITypes.CreatePaymentMutation
>;
export const createPaymentSnap = /* GraphQL */ `mutation CreatePaymentSnap($input: CreateSnapPaymentInput) {
  createPaymentSnap(input: $input) {
    token
    success
    redirectUrl
    expiredAt
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentSnapMutationVariables,
  APITypes.CreatePaymentSnapMutation
>;
export const createSellFixed = /* GraphQL */ `mutation CreateSellFixed(
  $itemId: ID!
  $price: Float!
  $status: SellStatus!
  $currency: PriceCurrency!
  $startDate: AWSDateTime!
  $endDate: AWSDateTime!
  $reservedFor: ID
) {
  createSellFixed(
    itemId: $itemId
    price: $price
    status: $status
    currency: $currency
    startDate: $startDate
    endDate: $endDate
    reservedFor: $reservedFor
  ) {
    itemId
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSellFixedMutationVariables,
  APITypes.CreateSellFixedMutation
>;
export const createSellAuction = /* GraphQL */ `mutation CreateSellAuction(
  $itemId: ID!
  $method: AuctionMethodType!
  $price: Float!
  $status: SellStatus!
  $currency: PriceCurrency!
  $endingPrice: Float
  $reservedPrice: Float
  $startDate: AWSDateTime!
  $endDate: AWSDateTime!
) {
  createSellAuction(
    itemId: $itemId
    method: $method
    price: $price
    status: $status
    currency: $currency
    endingPrice: $endingPrice
    reservedPrice: $reservedPrice
    startDate: $startDate
    endDate: $endDate
  ) {
    itemId
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSellAuctionMutationVariables,
  APITypes.CreateSellAuctionMutation
>;
export const cancelSells = /* GraphQL */ `mutation CancelSells($sellId: [ID!]) {
  cancelSells(sellId: $sellId) {
    success
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelSellsMutationVariables,
  APITypes.CancelSellsMutation
>;
export const uploadNft = /* GraphQL */ `mutation UploadNft($input: SingleItemUploadInput) {
  uploadNft(input: $input) {
    data {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UploadNftMutationVariables,
  APITypes.UploadNftMutation
>;
export const createOfferItem = /* GraphQL */ `mutation CreateOfferItem(
  $itemId: ID!
  $price: Float!
  $currency: PriceCurrency!
  $quantity: Int!
  $deposit: Float
  $startDate: AWSDateTime!
  $endDate: AWSDateTime!
) {
  createOfferItem(
    itemId: $itemId
    price: $price
    currency: $currency
    quantity: $quantity
    deposit: $deposit
    startDate: $startDate
    endDate: $endDate
  ) {
    itemId
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOfferItemMutationVariables,
  APITypes.CreateOfferItemMutation
>;
export const candyMachine = /* GraphQL */ `mutation CandyMachine($collectionId: String, $creatorId: String) {
  candyMachine(collectionId: $collectionId, creatorId: $creatorId) {
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CandyMachineMutationVariables,
  APITypes.CandyMachineMutation
>;
export const createListing = /* GraphQL */ `mutation CreateListing($userId: ID!, $price: Float, $limit: Int!) {
  createListing(userId: $userId, price: $price, limit: $limit) {
    userId
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateListingMutationVariables,
  APITypes.CreateListingMutation
>;
export const createWhitelistDate = /* GraphQL */ `mutation CreateWhitelistDate(
  $startDate: AWSDateTime
  $endDate: AWSDateTime
  $currency: PriceCurrency!
  $price: Float!
) {
  createWhitelistDate(
    startDate: $startDate
    endDate: $endDate
    currency: $currency
    price: $price
  ) {
    success
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWhitelistDateMutationVariables,
  APITypes.CreateWhitelistDateMutation
>;
export const createWhitelistUserStream = /* GraphQL */ `mutation CreateWhitelistUserStream(
  $userId: String
  $whitelistId: String
  $price: Float
  $currency: PriceCurrency
) {
  createWhitelistUserStream(
    userId: $userId
    whitelistId: $whitelistId
    price: $price
    currency: $currency
  ) {
    success
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWhitelistUserStreamMutationVariables,
  APITypes.CreateWhitelistUserStreamMutation
>;
export const mintingCandy = /* GraphQL */ `mutation MintingCandy(
  $candyMachineAddress: String
  $userId: String
  $numberOfMinting: Int
) {
  mintingCandy(
    candyMachineAddress: $candyMachineAddress
    userId: $userId
    numberOfMinting: $numberOfMinting
  ) {
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MintingCandyMutationVariables,
  APITypes.MintingCandyMutation
>;
export const createListingSingleItem = /* GraphQL */ `mutation CreateListingSingleItem(
  $currency: String!
  $itemId: String!
  $price: Float!
  $endDate: AWSDateTime!
) {
  createListingSingleItem(
    currency: $currency
    itemId: $itemId
    price: $price
    endDate: $endDate
  ) {
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateListingSingleItemMutationVariables,
  APITypes.CreateListingSingleItemMutation
>;
export const createBuyNFT = /* GraphQL */ `mutation CreateBuyNFT($itemId: String!, $amount: Float!) {
  createBuyNFT(itemId: $itemId, amount: $amount) {
    itemId
    address
    status
    redirectUrl
    token
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBuyNFTMutationVariables,
  APITypes.CreateBuyNFTMutation
>;
export const createCollectionByCollectionId = /* GraphQL */ `mutation CreateCollectionByCollectionId($collectionId: String!) {
  createCollectionByCollectionId(collectionId: $collectionId) {
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCollectionByCollectionIdMutationVariables,
  APITypes.CreateCollectionByCollectionIdMutation
>;
export const generatePrice = /* GraphQL */ `mutation GeneratePrice($collectionId: String, $price: Float) {
  generatePrice(collectionId: $collectionId, price: $price) {
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GeneratePriceMutationVariables,
  APITypes.GeneratePriceMutation
>;
export const createExecuteBuy = /* GraphQL */ `mutation CreateExecuteBuy(
  $currency: PriceCurrency!
  $amount: Float!
  $collection: [CollectionExecute]!
) {
  createExecuteBuy(
    currency: $currency
    amount: $amount
    collection: $collection
  ) {
    success
    redirectUrl
    token
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExecuteBuyMutationVariables,
  APITypes.CreateExecuteBuyMutation
>;
export const generateItem = /* GraphQL */ `mutation GenerateItem(
  $name: String!
  $description: String!
  $url: String!
  $creatorId: String!
  $collectionId: String!
  $startNo: Int!
  $endNo: Int!
) {
  generateItem(
    name: $name
    description: $description
    url: $url
    creatorId: $creatorId
    collectionId: $collectionId
    startNo: $startNo
    endNo: $endNo
  ) {
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateItemMutationVariables,
  APITypes.GenerateItemMutation
>;
export const createRemindLaunchpad = /* GraphQL */ `mutation CreateRemindLaunchpad($collectionId: String!) {
  createRemindLaunchpad(collectionId: $collectionId) {
    id
    collectionId
    userId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRemindLaunchpadMutationVariables,
  APITypes.CreateRemindLaunchpadMutation
>;
export const changeImageUrlJsonFile = /* GraphQL */ `mutation ChangeImageUrlJsonFile($prefix: String) {
  changeImageUrlJsonFile(prefix: $prefix) {
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ChangeImageUrlJsonFileMutationVariables,
  APITypes.ChangeImageUrlJsonFileMutation
>;
export const generateItemByS3Prefix = /* GraphQL */ `mutation GenerateItemByS3Prefix($prefixJson: String) {
  generateItemByS3Prefix(prefixJson: $prefixJson) {
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateItemByS3PrefixMutationVariables,
  APITypes.GenerateItemByS3PrefixMutation
>;
export const launchpadMinting = /* GraphQL */ `mutation LaunchpadMinting(
  $collectionId: String!
  $ccurrency: PriceCurrency!
  $amount: Float!
) {
  launchpadMinting(
    collectionId: $collectionId
    ccurrency: $ccurrency
    amount: $amount
  ) {
    success
    redirectUrl
    token
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.LaunchpadMintingMutationVariables,
  APITypes.LaunchpadMintingMutation
>;
export const deleteFileByCreator = /* GraphQL */ `mutation DeleteFileByCreator($key: String!) {
  deleteFileByCreator(key: $key) {
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFileByCreatorMutationVariables,
  APITypes.DeleteFileByCreatorMutation
>;
export const listingItems = /* GraphQL */ `mutation ListingItems(
  $purchaseCurrency: PriceCurrency!
  $amount: Float!
  $data: [ItemListing]!
) {
  listingItems(
    purchaseCurrency: $purchaseCurrency
    amount: $amount
    data: $data
  ) {
    success
    redirectUrl
    token
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ListingItemsMutationVariables,
  APITypes.ListingItemsMutation
>;
export const cancelListing = /* GraphQL */ `mutation CancelListing($itemId: String!) {
  cancelListing(itemId: $itemId) {
    status
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelListingMutationVariables,
  APITypes.CancelListingMutation
>;
export const createCollectionV2 = /* GraphQL */ `mutation CreateCollectionV2($input: InputCreateCollectionV2!) {
  createCollectionV2(input: $input) {
    collection {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCollectionV2MutationVariables,
  APITypes.CreateCollectionV2Mutation
>;
export const calculateTotalItemCollection = /* GraphQL */ `mutation CalculateTotalItemCollection($collectionId: String!) {
  calculateTotalItemCollection(collectionId: $collectionId) {
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CalculateTotalItemCollectionMutationVariables,
  APITypes.CalculateTotalItemCollectionMutation
>;
export const publishLaunchpad = /* GraphQL */ `mutation PublishLaunchpad(
  $collectionId: String!
  $amount: Float!
  $currency: PriceCurrency!
) {
  publishLaunchpad(
    collectionId: $collectionId
    amount: $amount
    currency: $currency
  ) {
    success
    redirectUrl
    token
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PublishLaunchpadMutationVariables,
  APITypes.PublishLaunchpadMutation
>;
export const updateTotalItemCollection = /* GraphQL */ `mutation UpdateTotalItemCollection($collectionId: String!, $totalItem: Int!) {
  updateTotalItemCollection(
    collectionId: $collectionId
    totalItem: $totalItem
  ) {
    collectionId
    totalItems
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTotalItemCollectionMutationVariables,
  APITypes.UpdateTotalItemCollectionMutation
>;
export const buyDirectFromLaunchpad = /* GraphQL */ `mutation BuyDirectFromLaunchpad(
  $itemId: String!
  $amount: Float!
  $currency: PriceCurrency!
) {
  buyDirectFromLaunchpad(
    itemId: $itemId
    amount: $amount
    currency: $currency
  ) {
    success
    redirectUrl
    token
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BuyDirectFromLaunchpadMutationVariables,
  APITypes.BuyDirectFromLaunchpadMutation
>;
export const solTransfer = /* GraphQL */ `mutation SolTransfer($amount: Float!, $to: String!) {
  solTransfer(amount: $amount, to: $to) {
    signature
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SolTransferMutationVariables,
  APITypes.SolTransferMutation
>;
export const directBuyUnauthorized = /* GraphQL */ `mutation DirectBuyUnauthorized(
  $itemId: String!
  $amount: Float!
  $currency: PriceCurrency!
) {
  directBuyUnauthorized(itemId: $itemId, amount: $amount, currency: $currency) {
    success
    redirectUrl
    token
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DirectBuyUnauthorizedMutationVariables,
  APITypes.DirectBuyUnauthorizedMutation
>;
export const createUserProfile = /* GraphQL */ `mutation CreateUserProfile(
  $input: CreateUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  createUserProfile(input: $input, condition: $condition) {
    id
    name
    email
    profilePictureUrl
    profileBannerUrl
    description
    type
    collections {
      items {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      nextToken
      __typename
    }
    items {
      items {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    wallets {
      items {
        id
        userId
        currency
        publicKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    whitelistUser {
      items {
        id
        userId
        whitelistId
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    executeNFT {
      items {
        id
        orderId
        quantity
        userId
        collectionId
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserProfileMutationVariables,
  APITypes.CreateUserProfileMutation
>;
export const updateUserProfile = /* GraphQL */ `mutation UpdateUserProfile(
  $input: UpdateUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  updateUserProfile(input: $input, condition: $condition) {
    id
    name
    email
    profilePictureUrl
    profileBannerUrl
    description
    type
    collections {
      items {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      nextToken
      __typename
    }
    items {
      items {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    wallets {
      items {
        id
        userId
        currency
        publicKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    whitelistUser {
      items {
        id
        userId
        whitelistId
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    executeNFT {
      items {
        id
        orderId
        quantity
        userId
        collectionId
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProfileMutationVariables,
  APITypes.UpdateUserProfileMutation
>;
export const deleteUserProfile = /* GraphQL */ `mutation DeleteUserProfile(
  $input: DeleteUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  deleteUserProfile(input: $input, condition: $condition) {
    id
    name
    email
    profilePictureUrl
    profileBannerUrl
    description
    type
    collections {
      items {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      nextToken
      __typename
    }
    items {
      items {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    wallets {
      items {
        id
        userId
        currency
        publicKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    whitelistUser {
      items {
        id
        userId
        whitelistId
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    executeNFT {
      items {
        id
        orderId
        quantity
        userId
        collectionId
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserProfileMutationVariables,
  APITypes.DeleteUserProfileMutation
>;
export const createWhitelist = /* GraphQL */ `mutation CreateWhitelist(
  $input: CreateWhitelistInput!
  $condition: ModelWhitelistConditionInput
) {
  createWhitelist(input: $input, condition: $condition) {
    id
    startDate
    endDate
    expiredAt
    price {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWhitelistMutationVariables,
  APITypes.CreateWhitelistMutation
>;
export const updateWhitelist = /* GraphQL */ `mutation UpdateWhitelist(
  $input: UpdateWhitelistInput!
  $condition: ModelWhitelistConditionInput
) {
  updateWhitelist(input: $input, condition: $condition) {
    id
    startDate
    endDate
    expiredAt
    price {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWhitelistMutationVariables,
  APITypes.UpdateWhitelistMutation
>;
export const deleteWhitelist = /* GraphQL */ `mutation DeleteWhitelist(
  $input: DeleteWhitelistInput!
  $condition: ModelWhitelistConditionInput
) {
  deleteWhitelist(input: $input, condition: $condition) {
    id
    startDate
    endDate
    expiredAt
    price {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWhitelistMutationVariables,
  APITypes.DeleteWhitelistMutation
>;
export const createWhitelistUser = /* GraphQL */ `mutation CreateWhitelistUser(
  $input: CreateWhitelistUserInput!
  $condition: ModelWhitelistUserConditionInput
) {
  createWhitelistUser(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    whitelistId
    price {
      value
      currency
      __typename
    }
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWhitelistUserMutationVariables,
  APITypes.CreateWhitelistUserMutation
>;
export const updateWhitelistUser = /* GraphQL */ `mutation UpdateWhitelistUser(
  $input: UpdateWhitelistUserInput!
  $condition: ModelWhitelistUserConditionInput
) {
  updateWhitelistUser(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    whitelistId
    price {
      value
      currency
      __typename
    }
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWhitelistUserMutationVariables,
  APITypes.UpdateWhitelistUserMutation
>;
export const deleteWhitelistUser = /* GraphQL */ `mutation DeleteWhitelistUser(
  $input: DeleteWhitelistUserInput!
  $condition: ModelWhitelistUserConditionInput
) {
  deleteWhitelistUser(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    whitelistId
    price {
      value
      currency
      __typename
    }
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWhitelistUserMutationVariables,
  APITypes.DeleteWhitelistUserMutation
>;
export const createCollection = /* GraphQL */ `mutation CreateCollection(
  $input: CreateCollectionInput!
  $condition: ModelCollectionConditionInput
) {
  createCollection(input: $input, condition: $condition) {
    id
    slug
    name
    description
    thumbnailUrl
    bannerUrl
    metadataUrl
    creatorId
    creator {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    items {
      items {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    address
    status
    startDate
    endDate
    totalItems
    price {
      value
      currency
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCollectionMutationVariables,
  APITypes.CreateCollectionMutation
>;
export const updateCollection = /* GraphQL */ `mutation UpdateCollection(
  $input: UpdateCollectionInput!
  $condition: ModelCollectionConditionInput
) {
  updateCollection(input: $input, condition: $condition) {
    id
    slug
    name
    description
    thumbnailUrl
    bannerUrl
    metadataUrl
    creatorId
    creator {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    items {
      items {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    address
    status
    startDate
    endDate
    totalItems
    price {
      value
      currency
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCollectionMutationVariables,
  APITypes.UpdateCollectionMutation
>;
export const deleteCollection = /* GraphQL */ `mutation DeleteCollection(
  $input: DeleteCollectionInput!
  $condition: ModelCollectionConditionInput
) {
  deleteCollection(input: $input, condition: $condition) {
    id
    slug
    name
    description
    thumbnailUrl
    bannerUrl
    metadataUrl
    creatorId
    creator {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    items {
      items {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    address
    status
    startDate
    endDate
    totalItems
    price {
      value
      currency
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCollectionMutationVariables,
  APITypes.DeleteCollectionMutation
>;
export const createExecuteNFT = /* GraphQL */ `mutation CreateExecuteNFT(
  $input: CreateExecuteNFTInput!
  $condition: ModelExecuteNFTConditionInput
) {
  createExecuteNFT(input: $input, condition: $condition) {
    id
    orderId
    quantity
    price {
      value
      currency
      __typename
    }
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    collectionId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExecuteNFTMutationVariables,
  APITypes.CreateExecuteNFTMutation
>;
export const updateExecuteNFT = /* GraphQL */ `mutation UpdateExecuteNFT(
  $input: UpdateExecuteNFTInput!
  $condition: ModelExecuteNFTConditionInput
) {
  updateExecuteNFT(input: $input, condition: $condition) {
    id
    orderId
    quantity
    price {
      value
      currency
      __typename
    }
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    collectionId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExecuteNFTMutationVariables,
  APITypes.UpdateExecuteNFTMutation
>;
export const deleteExecuteNFT = /* GraphQL */ `mutation DeleteExecuteNFT(
  $input: DeleteExecuteNFTInput!
  $condition: ModelExecuteNFTConditionInput
) {
  deleteExecuteNFT(input: $input, condition: $condition) {
    id
    orderId
    quantity
    price {
      value
      currency
      __typename
    }
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    collectionId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExecuteNFTMutationVariables,
  APITypes.DeleteExecuteNFTMutation
>;
export const createItem = /* GraphQL */ `mutation CreateItem(
  $input: CreateItemInput!
  $condition: ModelItemConditionInput
) {
  createItem(input: $input, condition: $condition) {
    id
    name
    description
    imageUrl
    metadataUrl
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    creatorId
    creator {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    collectionId
    collection {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    tradeStateAddress
    recipientAddress
    address
    price {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    holdUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateItemMutationVariables,
  APITypes.CreateItemMutation
>;
export const updateItem = /* GraphQL */ `mutation UpdateItem(
  $input: UpdateItemInput!
  $condition: ModelItemConditionInput
) {
  updateItem(input: $input, condition: $condition) {
    id
    name
    description
    imageUrl
    metadataUrl
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    creatorId
    creator {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    collectionId
    collection {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    tradeStateAddress
    recipientAddress
    address
    price {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    holdUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateItemMutationVariables,
  APITypes.UpdateItemMutation
>;
export const deleteItem = /* GraphQL */ `mutation DeleteItem(
  $input: DeleteItemInput!
  $condition: ModelItemConditionInput
) {
  deleteItem(input: $input, condition: $condition) {
    id
    name
    description
    imageUrl
    metadataUrl
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    creatorId
    creator {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    collectionId
    collection {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    tradeStateAddress
    recipientAddress
    address
    price {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    holdUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteItemMutationVariables,
  APITypes.DeleteItemMutation
>;
export const createSell = /* GraphQL */ `mutation CreateSell(
  $input: CreateSellInput!
  $condition: ModelSellConditionInput
) {
  createSell(input: $input, condition: $condition) {
    id
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    status
    price {
      value
      currency
      __typename
    }
    reservedFor
    sellDuration {
      startDate
      endDate
      __typename
    }
    auctionMethod
    sellingTypes
    currency
    reservedPrice {
      value
      currency
      __typename
    }
    endingPrice {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSellMutationVariables,
  APITypes.CreateSellMutation
>;
export const updateSell = /* GraphQL */ `mutation UpdateSell(
  $input: UpdateSellInput!
  $condition: ModelSellConditionInput
) {
  updateSell(input: $input, condition: $condition) {
    id
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    status
    price {
      value
      currency
      __typename
    }
    reservedFor
    sellDuration {
      startDate
      endDate
      __typename
    }
    auctionMethod
    sellingTypes
    currency
    reservedPrice {
      value
      currency
      __typename
    }
    endingPrice {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSellMutationVariables,
  APITypes.UpdateSellMutation
>;
export const deleteSell = /* GraphQL */ `mutation DeleteSell(
  $input: DeleteSellInput!
  $condition: ModelSellConditionInput
) {
  deleteSell(input: $input, condition: $condition) {
    id
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    status
    price {
      value
      currency
      __typename
    }
    reservedFor
    sellDuration {
      startDate
      endDate
      __typename
    }
    auctionMethod
    sellingTypes
    currency
    reservedPrice {
      value
      currency
      __typename
    }
    endingPrice {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSellMutationVariables,
  APITypes.DeleteSellMutation
>;
export const createSellDuration = /* GraphQL */ `mutation CreateSellDuration(
  $input: CreateSellDurationInput!
  $condition: ModelSellDurationConditionInput
) {
  createSellDuration(input: $input, condition: $condition) {
    id
    itemId
    sellId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSellDurationMutationVariables,
  APITypes.CreateSellDurationMutation
>;
export const updateSellDuration = /* GraphQL */ `mutation UpdateSellDuration(
  $input: UpdateSellDurationInput!
  $condition: ModelSellDurationConditionInput
) {
  updateSellDuration(input: $input, condition: $condition) {
    id
    itemId
    sellId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSellDurationMutationVariables,
  APITypes.UpdateSellDurationMutation
>;
export const deleteSellDuration = /* GraphQL */ `mutation DeleteSellDuration(
  $input: DeleteSellDurationInput!
  $condition: ModelSellDurationConditionInput
) {
  deleteSellDuration(input: $input, condition: $condition) {
    id
    itemId
    sellId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSellDurationMutationVariables,
  APITypes.DeleteSellDurationMutation
>;
export const createPurchase = /* GraphQL */ `mutation CreatePurchase(
  $input: CreatePurchaseInput!
  $condition: ModelPurchaseConditionInput
) {
  createPurchase(input: $input, condition: $condition) {
    id
    orderId
    userId
    itemId
    paymentGatewayName
    bank
    amount
    status
    virtualAccount
    checkoutUrl {
      name
      method
      url
      __typename
    }
    redirectUrl
    metaPaymentNotification
    metaRequestPayment
    metaResponsePayment
    expiredAt
    executeNFTId
    createdAt
    updatedAt
    collectionId
    numberOfMintingId
    action
    metaListing
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePurchaseMutationVariables,
  APITypes.CreatePurchaseMutation
>;
export const updatePurchase = /* GraphQL */ `mutation UpdatePurchase(
  $input: UpdatePurchaseInput!
  $condition: ModelPurchaseConditionInput
) {
  updatePurchase(input: $input, condition: $condition) {
    id
    orderId
    userId
    itemId
    paymentGatewayName
    bank
    amount
    status
    virtualAccount
    checkoutUrl {
      name
      method
      url
      __typename
    }
    redirectUrl
    metaPaymentNotification
    metaRequestPayment
    metaResponsePayment
    expiredAt
    executeNFTId
    createdAt
    updatedAt
    collectionId
    numberOfMintingId
    action
    metaListing
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePurchaseMutationVariables,
  APITypes.UpdatePurchaseMutation
>;
export const deletePurchase = /* GraphQL */ `mutation DeletePurchase(
  $input: DeletePurchaseInput!
  $condition: ModelPurchaseConditionInput
) {
  deletePurchase(input: $input, condition: $condition) {
    id
    orderId
    userId
    itemId
    paymentGatewayName
    bank
    amount
    status
    virtualAccount
    checkoutUrl {
      name
      method
      url
      __typename
    }
    redirectUrl
    metaPaymentNotification
    metaRequestPayment
    metaResponsePayment
    expiredAt
    executeNFTId
    createdAt
    updatedAt
    collectionId
    numberOfMintingId
    action
    metaListing
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePurchaseMutationVariables,
  APITypes.DeletePurchaseMutation
>;
export const createPurchaseHold = /* GraphQL */ `mutation CreatePurchaseHold(
  $input: CreatePurchaseHoldInput!
  $condition: ModelPurchaseHoldConditionInput
) {
  createPurchaseHold(input: $input, condition: $condition) {
    id
    orderId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePurchaseHoldMutationVariables,
  APITypes.CreatePurchaseHoldMutation
>;
export const updatePurchaseHold = /* GraphQL */ `mutation UpdatePurchaseHold(
  $input: UpdatePurchaseHoldInput!
  $condition: ModelPurchaseHoldConditionInput
) {
  updatePurchaseHold(input: $input, condition: $condition) {
    id
    orderId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePurchaseHoldMutationVariables,
  APITypes.UpdatePurchaseHoldMutation
>;
export const deletePurchaseHold = /* GraphQL */ `mutation DeletePurchaseHold(
  $input: DeletePurchaseHoldInput!
  $condition: ModelPurchaseHoldConditionInput
) {
  deletePurchaseHold(input: $input, condition: $condition) {
    id
    orderId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePurchaseHoldMutationVariables,
  APITypes.DeletePurchaseHoldMutation
>;
export const createWallet = /* GraphQL */ `mutation CreateWallet(
  $input: CreateWalletInput!
  $condition: ModelWalletConditionInput
) {
  createWallet(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    currency
    publicKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWalletMutationVariables,
  APITypes.CreateWalletMutation
>;
export const updateWallet = /* GraphQL */ `mutation UpdateWallet(
  $input: UpdateWalletInput!
  $condition: ModelWalletConditionInput
) {
  updateWallet(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    currency
    publicKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWalletMutationVariables,
  APITypes.UpdateWalletMutation
>;
export const deleteWallet = /* GraphQL */ `mutation DeleteWallet(
  $input: DeleteWalletInput!
  $condition: ModelWalletConditionInput
) {
  deleteWallet(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    currency
    publicKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWalletMutationVariables,
  APITypes.DeleteWalletMutation
>;
export const createBidHistory = /* GraphQL */ `mutation CreateBidHistory(
  $input: CreateBidHistoryInput!
  $condition: ModelBidHistoryConditionInput
) {
  createBidHistory(input: $input, condition: $condition) {
    id
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    sellId
    sell {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      status
      price {
        value
        currency
        __typename
      }
      reservedFor
      sellDuration {
        startDate
        endDate
        __typename
      }
      auctionMethod
      sellingTypes
      currency
      reservedPrice {
        value
        currency
        __typename
      }
      endingPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    auctionMethod
    status
    currency
    bidPrice {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBidHistoryMutationVariables,
  APITypes.CreateBidHistoryMutation
>;
export const updateBidHistory = /* GraphQL */ `mutation UpdateBidHistory(
  $input: UpdateBidHistoryInput!
  $condition: ModelBidHistoryConditionInput
) {
  updateBidHistory(input: $input, condition: $condition) {
    id
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    sellId
    sell {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      status
      price {
        value
        currency
        __typename
      }
      reservedFor
      sellDuration {
        startDate
        endDate
        __typename
      }
      auctionMethod
      sellingTypes
      currency
      reservedPrice {
        value
        currency
        __typename
      }
      endingPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    auctionMethod
    status
    currency
    bidPrice {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBidHistoryMutationVariables,
  APITypes.UpdateBidHistoryMutation
>;
export const deleteBidHistory = /* GraphQL */ `mutation DeleteBidHistory(
  $input: DeleteBidHistoryInput!
  $condition: ModelBidHistoryConditionInput
) {
  deleteBidHistory(input: $input, condition: $condition) {
    id
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    sellId
    sell {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      status
      price {
        value
        currency
        __typename
      }
      reservedFor
      sellDuration {
        startDate
        endDate
        __typename
      }
      auctionMethod
      sellingTypes
      currency
      reservedPrice {
        value
        currency
        __typename
      }
      endingPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    auctionMethod
    status
    currency
    bidPrice {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBidHistoryMutationVariables,
  APITypes.DeleteBidHistoryMutation
>;
export const createOffer = /* GraphQL */ `mutation CreateOffer(
  $input: CreateOfferInput!
  $condition: ModelOfferConditionInput
) {
  createOffer(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    price {
      value
      currency
      __typename
    }
    deposit {
      value
      currency
      __typename
    }
    currency
    quantity
    duration {
      startDate
      endDate
      __typename
    }
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOfferMutationVariables,
  APITypes.CreateOfferMutation
>;
export const updateOffer = /* GraphQL */ `mutation UpdateOffer(
  $input: UpdateOfferInput!
  $condition: ModelOfferConditionInput
) {
  updateOffer(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    price {
      value
      currency
      __typename
    }
    deposit {
      value
      currency
      __typename
    }
    currency
    quantity
    duration {
      startDate
      endDate
      __typename
    }
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOfferMutationVariables,
  APITypes.UpdateOfferMutation
>;
export const deleteOffer = /* GraphQL */ `mutation DeleteOffer(
  $input: DeleteOfferInput!
  $condition: ModelOfferConditionInput
) {
  deleteOffer(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    price {
      value
      currency
      __typename
    }
    deposit {
      value
      currency
      __typename
    }
    currency
    quantity
    duration {
      startDate
      endDate
      __typename
    }
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOfferMutationVariables,
  APITypes.DeleteOfferMutation
>;
export const createUserBalance = /* GraphQL */ `mutation CreateUserBalance(
  $input: CreateUserBalanceInput!
  $condition: ModelUserBalanceConditionInput
) {
  createUserBalance(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    deposit {
      value
      currency
      __typename
    }
    balance {
      value
      currency
      __typename
    }
    currency
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserBalanceMutationVariables,
  APITypes.CreateUserBalanceMutation
>;
export const updateUserBalance = /* GraphQL */ `mutation UpdateUserBalance(
  $input: UpdateUserBalanceInput!
  $condition: ModelUserBalanceConditionInput
) {
  updateUserBalance(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    deposit {
      value
      currency
      __typename
    }
    balance {
      value
      currency
      __typename
    }
    currency
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserBalanceMutationVariables,
  APITypes.UpdateUserBalanceMutation
>;
export const deleteUserBalance = /* GraphQL */ `mutation DeleteUserBalance(
  $input: DeleteUserBalanceInput!
  $condition: ModelUserBalanceConditionInput
) {
  deleteUserBalance(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    deposit {
      value
      currency
      __typename
    }
    balance {
      value
      currency
      __typename
    }
    currency
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserBalanceMutationVariables,
  APITypes.DeleteUserBalanceMutation
>;
export const createCandyMachineNFTOne = /* GraphQL */ `mutation CreateCandyMachineNFTOne(
  $input: CreateCandyMachineNFTOneInput!
  $condition: ModelCandyMachineNFTOneConditionInput
) {
  createCandyMachineNFTOne(input: $input, condition: $condition) {
    id
    candyMachineId
    collectionId
    mintingType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCandyMachineNFTOneMutationVariables,
  APITypes.CreateCandyMachineNFTOneMutation
>;
export const updateCandyMachineNFTOne = /* GraphQL */ `mutation UpdateCandyMachineNFTOne(
  $input: UpdateCandyMachineNFTOneInput!
  $condition: ModelCandyMachineNFTOneConditionInput
) {
  updateCandyMachineNFTOne(input: $input, condition: $condition) {
    id
    candyMachineId
    collectionId
    mintingType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCandyMachineNFTOneMutationVariables,
  APITypes.UpdateCandyMachineNFTOneMutation
>;
export const deleteCandyMachineNFTOne = /* GraphQL */ `mutation DeleteCandyMachineNFTOne(
  $input: DeleteCandyMachineNFTOneInput!
  $condition: ModelCandyMachineNFTOneConditionInput
) {
  deleteCandyMachineNFTOne(input: $input, condition: $condition) {
    id
    candyMachineId
    collectionId
    mintingType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCandyMachineNFTOneMutationVariables,
  APITypes.DeleteCandyMachineNFTOneMutation
>;
export const createCollectionStatusTtl = /* GraphQL */ `mutation CreateCollectionStatusTtl(
  $input: CreateCollectionStatusTtlInput!
  $condition: ModelCollectionStatusTtlConditionInput
) {
  createCollectionStatusTtl(input: $input, condition: $condition) {
    id
    collectionId
    status
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCollectionStatusTtlMutationVariables,
  APITypes.CreateCollectionStatusTtlMutation
>;
export const updateCollectionStatusTtl = /* GraphQL */ `mutation UpdateCollectionStatusTtl(
  $input: UpdateCollectionStatusTtlInput!
  $condition: ModelCollectionStatusTtlConditionInput
) {
  updateCollectionStatusTtl(input: $input, condition: $condition) {
    id
    collectionId
    status
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCollectionStatusTtlMutationVariables,
  APITypes.UpdateCollectionStatusTtlMutation
>;
export const deleteCollectionStatusTtl = /* GraphQL */ `mutation DeleteCollectionStatusTtl(
  $input: DeleteCollectionStatusTtlInput!
  $condition: ModelCollectionStatusTtlConditionInput
) {
  deleteCollectionStatusTtl(input: $input, condition: $condition) {
    id
    collectionId
    status
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCollectionStatusTtlMutationVariables,
  APITypes.DeleteCollectionStatusTtlMutation
>;
export const redeemCode = /* GraphQL */ `mutation RedeemCode($code: String!) {
  redeemCode(code: $code) {
    success
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RedeemCodeMutationVariables,
  APITypes.RedeemCodeMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    id
    price {
      value
      currency
      __typename
    }
    status
    userId
    action
    details {
      id
      orderId
      userId
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const createPaymentV2 = /* GraphQL */ `mutation CreatePaymentV2($input: CreatePaymentV2Input!) {
  createPaymentV2(input: $input) {
    paymentId
    orderId
    amount
    paymentType
    status
    expiredAt
    createdAt
    updatedAt
    actions {
      name
      method
      url
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentV2MutationVariables,
  APITypes.CreatePaymentV2Mutation
>;
export const purchaseNFTUnauthorized = /* GraphQL */ `mutation PurchaseNFTUnauthorized($input: PurchaseNFTUnauthorizedInput!) {
  purchaseNFTUnauthorized(input: $input) {
    orderId
    paymentId
    itemId
    amount
    actions {
      name
      method
      url
      __typename
    }
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PurchaseNFTUnauthorizedMutationVariables,
  APITypes.PurchaseNFTUnauthorizedMutation
>;
export const publishNFTRedeemCode = /* GraphQL */ `mutation PublishNFTRedeemCode($input: InputPublishNFTRedeemCode!) {
  publishNFTRedeemCode(input: $input) {
    orderId
    itemId
    itemAddress
    itemName
    itemImageUrl
    redeemCode
    amount
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PublishNFTRedeemCodeMutationVariables,
  APITypes.PublishNFTRedeemCodeMutation
>;
export const redeemCodeNFT = /* GraphQL */ `mutation RedeemCodeNFT($code: String!) {
  redeemCodeNFT(code: $code) {
    itemId
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RedeemCodeNFTMutationVariables,
  APITypes.RedeemCodeNFTMutation
>;
export const publishLaundhpadV2 = /* GraphQL */ `mutation PublishLaundhpadV2($input: InputPublishLaunchpadV2!) {
  publishLaundhpadV2(input: $input) {
    orderId
    amount
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PublishLaundhpadV2MutationVariables,
  APITypes.PublishLaundhpadV2Mutation
>;
export const mintingLaunchpadV2 = /* GraphQL */ `mutation MintingLaunchpadV2($input: InputMintingLaunchpadV2!) {
  mintingLaunchpadV2(input: $input) {
    orderId
    amount
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MintingLaunchpadV2MutationVariables,
  APITypes.MintingLaunchpadV2Mutation
>;
export const directBuyLaunchpadV2 = /* GraphQL */ `mutation DirectBuyLaunchpadV2($input: InputDirectBuyLaunchpadV2!) {
  directBuyLaunchpadV2(input: $input) {
    orderId
    amount
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DirectBuyLaunchpadV2MutationVariables,
  APITypes.DirectBuyLaunchpadV2Mutation
>;
export const createListingNFTV2 = /* GraphQL */ `mutation CreateListingNFTV2($input: InputCreateListingNFTV2!) {
  createListingNFTV2(input: $input) {
    orderId
    amount
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateListingNFTV2MutationVariables,
  APITypes.CreateListingNFTV2Mutation
>;
export const directBuyV2 = /* GraphQL */ `mutation DirectBuyV2($input: InputDirectBuyV2!) {
  directBuyV2(input: $input) {
    orderId
    amount
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DirectBuyV2MutationVariables,
  APITypes.DirectBuyV2Mutation
>;
export const cancelOrder = /* GraphQL */ `mutation CancelOrder($orderId: String!) {
  cancelOrder(orderId: $orderId) {
    orderId
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelOrderMutationVariables,
  APITypes.CancelOrderMutation
>;
