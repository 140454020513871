import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const PriceInput = ({
  onChange,
  forceValue,
  max,
}: {
  onChange: (value: number) => void;
  forceValue?: number;
  max?: number;
}) => {
  const [value, setValue] = useState<number>(0);
  const handleChange = (value: any) => (value ? setValue(value) : setValue(0));

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (forceValue) {
      setValue(forceValue);
    }
  }, [forceValue]);

  return (
    <NumberInput
      variant={"main"}
      w={"100%"}
      placeholder={"Amount"}
      min={0.006}
      step={0.1}
      value={value}
      onChange={handleChange}
      max={max}
    >
      <NumberInputField roundedRight={"none"} />
      <NumberInputStepper>
        <NumberIncrementStepper rounded={"none"} />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};
