import NextLink from "next/link";
import {
  Box,
  Center,
  Flex,
  Icon,
  Image,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";

import { MdVerified } from "react-icons/md";
import {
  HiOutlineCheck,
  HiOutlineClock,
  HiOutlineXCircle,
} from "react-icons/hi";

export const Logo = ({ ...props }) => {
  const imageUrl = useColorModeValue(
    "/images/logo-text-long-black.png",
    "/images/logo-text-long.png"
  );
  return (
    <Box my={2}>
      <Link as={NextLink} href="/">
        <Image alt="NFTOne" {...props} src={imageUrl} />
      </Link>
    </Box>
  );
};

export const Verified = (props: { size?: string | number }) => {
  const color = useColorModeValue("main.blue", "main.blue");
  return (
    <Icon
      boxSize={props.size !== undefined ? props.size : "auto"}
      marginStart={1}
      color={color}
      as={MdVerified}
    />
  );
};

export const SuccessLogo = ({
  size = "md",
  logo = "check",
}: {
  size?: "sm" | "md" | "lg";
  logo?: string;
}) => {
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      bgGradient={"linear(to-br, main.magenta, main.purple ,main.blue)"}
      p={"2px"}
      rounded={"full"}
      transform={
        size == "md"
          ? ""
          : size == "sm"
          ? "scale(0.8)"
          : size == "lg"
          ? "scale(1.2)"
          : "scale(1)"
      }
    >
      <Flex w={"full"} p={3} bg={"main.darkblue"} rounded={"full"}>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          bgGradient={"linear(to-br, main.magenta, main.purple ,main.blue)"}
          p={"2px"}
          rounded={"full"}
        >
          <Flex w={"full"} p={0} bg={"main.newDarkBlue"} rounded={"full"}>
            <Flex w={"full"} p={3} bg={"main.darkblue"} rounded={"full"}>
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                bgGradient={
                  "linear(to-br, main.magenta, main.purple ,main.blue)"
                }
                p={"2px"}
                rounded={"full"}
              >
                <Flex w={"full"} p={6} bg={"main.newDarkBlue"} rounded={"full"}>
                  <svg width="0" height="0">
                    <linearGradient
                      id="blue-gradient"
                      x1="100%"
                      y1="100%"
                      x2="0%"
                      y2="0%"
                    >
                      <stop stopColor="#3081ed" offset="0%" />
                      <stop stopColor="#c51fa0" offset="100%" />
                    </linearGradient>
                  </svg>
                  {logo === "waiting" ? (
                    <Icon
                      boxSize={40}
                      as={HiOutlineClock}
                      style={{ stroke: "url(#blue-gradient)" }}
                    />
                  ) : logo === "error" ? (
                    <Icon
                      boxSize={40}
                      as={HiOutlineXCircle}
                      style={{ stroke: "url(#blue-gradient)" }}
                    />
                  ) : (
                    <Icon
                      boxSize={40}
                      as={HiOutlineCheck}
                      style={{ stroke: "url(#blue-gradient)" }}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
