import {
  chakra,
  Icon,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { MdOutlineInfo } from "react-icons/md";

export default function InfoPopover(props: {
  children: ReactNode;
  placement?: PlacementWithLogical;
}) {
  const arrowBg = useColorModeValue("gray.100", "main.spaceCadet");

  return (
    <Popover placement={props.placement} trigger={"hover"} variant={"main"}>
      <chakra.span
        display={"inline-block"}
        h={"fit-content"}
        verticalAlign={"middle"}
      >
        <PopoverTrigger>
          <chakra.span display={"flex"} cursor={"pointer"}>
            <Icon boxSize={5} as={MdOutlineInfo} />
          </chakra.span>
        </PopoverTrigger>
      </chakra.span>
      <PopoverContent padding={1} rounded={"lg"}>
        <PopoverArrow bg={arrowBg} />
        <PopoverBody
          fontSize={"sm"}
          fontWeight={"semibold"}
          color={useColorModeValue("gray.700", "gray.300")}
        >
          {props.children}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
