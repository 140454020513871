import TopNavBar from "components/layout/top-nav-bar";
import { ReactChild, ReactChildren } from "react";

interface Props {
  children: ReactChild | ReactChildren | ReactChild[];
}

const MainIndex = ({ children }: Props) => {
  return (
    <main>
      <TopNavBar searchBox={true} />
      {children}
    </main>
  );
};

export default MainIndex;
