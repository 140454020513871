import NextLink from 'next/link';
import Router, { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AspectRatio,
  Avatar,
  Box,
  Button,
  CloseButton,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  LinkBox,
  LinkOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Skeleton,
  SlideFade,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDimensions,
  useDisclosure,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';

import { GraphQLQuery } from '@aws-amplify/api';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { MainButton } from 'components/global/accent-button';
import { CardCollectionSmall } from 'components/global/card-collection';
import { CardCreatorSmall } from 'components/global/card-creator';
import {
  CardItemSmall,
  CardItemSmallSkeleton,
  EmptyItems,
} from 'components/global/card-item';
import { Hicon, LogoWithTextLong, SolanaIcon } from 'components/layout/logo';

import { IconType } from 'react-icons';
import {
  BiLogInCircle,
  BiLogOut,
  BiTransfer,
  BiUserPlus,
} from 'react-icons/bi';
import {
  FaClipboardList,
  FaCopy,
  FaExternalLinkAlt,
  FaSearch,
  FaUserCircle,
} from 'react-icons/fa';
import { FiChevronRight } from 'react-icons/fi';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { IoChevronDown, IoChevronForward, IoRefresh } from 'react-icons/io5';
import { MdDarkMode, MdLightMode } from 'react-icons/md';
import {
  RiCloseFill,
  RiImageEditFill,
  RiSecurePaymentLine,
  RiSettings2Line,
} from 'react-icons/ri';
import { PiBarcode } from 'react-icons/pi';

import {
  Collection,
  CollectionStatus,
  Item,
  SearchCollectionsQuery,
  UserProfile,
  UserType,
} from 'src/API';
import {
  searchCollections,
  searchItems,
  searchUserProfiles,
} from 'src/graphql/queries';

import { motion, useScroll } from 'framer-motion';
import moment from 'moment';

import { useParameterStore } from 'utils/context';
import {
  formatIDR,
  useBalanceSol,
  useCopyLinkToClipboard,
  useDebounce,
} from 'utils/helpers';
import { ModalSendSol } from 'components/payment/wallet';

interface NavItem {
  label: string;
  href?: string;
  current?: boolean;
  icon?: IconType;
  handler?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

interface NavDrawerProps extends DrawerProps {
  userProfile?: UserProfile | null;
  accInfo?: {
    publicKey?: string;
    balance?: number;
  };
  getBalanceHandler?: () => void;
  singOutHandler?: () => void;
}

const navigations: Array<NavItem> = [
  { label: 'Collections', href: '/collection', current: false },
  { label: 'Items', href: '/item', current: false },
  { label: 'Launchpad', href: '/launchpad', current: false },
];

export default function TopNavBar({
  searchBox = true,
}: {
  searchBox?: boolean;
}) {
  const router = useRouter();
  const { colorMode, toggleColorMode } = useColorMode();

  const bgNormalNav = useColorModeValue('transparent', 'transparent');
  const bgGlassNav = useColorModeValue('whiteAlpha.900', 'rgba(4, 4, 40, 0.8)');
  const buttonSwitchThemeColor = useColorModeValue(
    'gray.100',
    'whiteAlpha.200'
  );
  const borderBottomNav = useColorModeValue(
    '1px solid rgba(255, 255, 255, 0.08)',
    '1px solid rgba(4, 4, 40, 0.08)'
  );
  const popoverContentBgColor = useColorModeValue('white', 'main.newDarkBlue');

  const { isOpen: mobileNavOpen, onToggle, onClose } = useDisclosure();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const [header, setHeader] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState<boolean>();
  const [aboutToExpireCollection, setAboutToExpireCollection] = useState<{
    loaded: boolean;
    collection: Array<Collection & { dayLeft?: number }>;
  }>({ loaded: false, collection: [] });

  const { refreshUserProfile, userProfile } = useParameterStore();
  const { accInfo, getBalanceSol } = useBalanceSol(userProfile);

  const ref = useRef<HTMLDivElement>(null);
  const mobileNavRef = useRef<HTMLDivElement>(null);

  const triggerPopover = useBreakpointValue({
    base: 'click',
    md: 'hover',
  });

  const children = [
    {
      label: 'Sign in',
      href: '/signin',
      icon: BiLogInCircle,
    },
    {
      label: 'Sign up',
      href: '/signup',
      icon: BiUserPlus,
    },
  ];

  const listenScrollEvent = (event: any) => {
    if (window.scrollY < 23) {
      return setHeader(false);
    } else if (window.scrollY > 20) {
      return setHeader(true);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      refreshUserProfile();
      router.push('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  useOutsideClick({
    ref: ref,
    handler: () => setSearchMode(false),
  });

  useOutsideClick({
    ref: mobileNavRef,
    handler: () => onClose(),
  });

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, []);

  useEffect(() => {
    const start = () => {
      setLoading(true);
    };

    const end = () => {
      setLoading(false);
    };

    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);

    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, []);

  useEffect(() => {
    const getAboutToExpireCollection = async () => {
      setAboutToExpireCollection({ loaded: false, collection: [] });

      await API.graphql<GraphQLQuery<SearchCollectionsQuery>>({
        query: searchCollections,
        variables: {
          filter: {
            creatorId: {
              eq: userProfile?.id,
            },
            status: {
              eq: CollectionStatus.AVAILABLE,
            },
          },
        },
      })
        .then((res) => {
          if (
            res.data?.searchCollections &&
            res.data.searchCollections.items.length > 0
          ) {
            const availableCollection = res.data.searchCollections
              .items as Collection[];

            const dateNow = moment();

            availableCollection.map((collection) => {
              const collectionEndDate = collection.endDate
                ? moment(new Date(collection.endDate))
                : null;

              if (collectionEndDate) {
                if (collectionEndDate.diff(dateNow, 'days') <= 7) {
                  setAboutToExpireCollection((prev) => {
                    return {
                      loaded: false,
                      collection: [
                        ...prev.collection,
                        {
                          ...collection,
                          dayLeft: collectionEndDate.diff(dateNow, 'days'),
                        },
                      ],
                    };
                  });
                }
              }
            });

            setAboutToExpireCollection((prev) => ({ ...prev, loaded: true }));
          } else {
            setAboutToExpireCollection((prev) => ({ ...prev, loaded: true }));
          }
        })
        .catch((error) => {
          console.log(error);
          setAboutToExpireCollection((prev) => ({
            loaded: true,
            collection: [],
          }));
        });
    };

    if (userProfile?.id) {
      getAboutToExpireCollection();
    }
    return () => {};
  }, [userProfile]);

  return (
    <>
      <Box
        pos={'sticky'}
        w={'full'}
        top={0}
        bg={mobileNavOpen ? bgGlassNav : header ? bgGlassNav : bgNormalNav}
        backdropFilter={
          mobileNavOpen ? 'blur(10px)' : header ? 'blur(10px)' : 'none'
        }
        borderBottom={borderBottomNav}
        transition={'all 0.25s ease-in-out'}
        zIndex={'banner'}
      >
        {loading && (
          <Box zIndex={'overlay'} w={'full'} position={'fixed'} top={0}>
            <Progress
              size={'xs'}
              variant={'main'}
              bg={'transparent'}
              isIndeterminate
            />
          </Box>
        )}
        <Box
          position={'relative'}
          py={4}
          zIndex={2}
          px={{ base: 4, lg: 8 }}
          ref={mobileNavRef}
        >
          {searchMode && searchBox ? (
            <Flex gap={2} minH={14} alignItems={'center'} ref={ref}>
              <SearchBar header={header} searchMode={searchMode} />
              <IconButton
                variant={'ghost'}
                aria-label="Close Search"
                fontSize={'1.5rem'}
                icon={<RiCloseFill />}
                onClick={() => setSearchMode(false)}
              />
            </Flex>
          ) : (
            <>
              <Flex
                justifyContent={'space-between'}
                minH={14}
                alignItems={'center'}
                gap={12}
              >
                <Flex alignItems={'center'} gap={8} onClick={() => onClose()}>
                  <Link
                    as={NextLink}
                    href="/"
                    display={'Flex'}
                    minW={30}
                    maxW={40}
                  >
                    <LogoWithTextLong />
                  </Link>
                  <Box
                    display={{ base: 'none', md: 'flex' }}
                    width={{
                      base: '300px',
                      md: 'full',
                      xl: '600px',
                    }}
                  >
                    {searchBox && (
                      <SearchBar searchMode={searchMode} header={header} />
                    )}
                  </Box>
                </Flex>
                <Flex gap={{ base: 4, lg: 10 }} alignItems={'center'}>
                  <Flex display={{ base: 'none', lg: 'flex' }} gap={8}>
                    <DesktopNav />
                  </Flex>
                  {searchBox && !mobileNavOpen && (
                    <IconButton
                      display={{ base: 'flex', md: 'none' }}
                      aria-label="Search Icon"
                      icon={<FaSearch />}
                      rounded={'full'}
                      onClick={() => setSearchMode(true)}
                    />
                  )}
                  <IconButton
                    rounded={'full'}
                    bg={{ base: buttonSwitchThemeColor, lg: 'transparent' }}
                    icon={
                      colorMode == 'dark' ? <MdLightMode /> : <MdDarkMode />
                    }
                    aria-label="Dark Mode"
                    onClick={() => toggleColorMode()}
                    display={{
                      base: mobileNavOpen ? 'inline-flex' : 'none',
                      lg: 'inline-flex',
                    }}
                  />
                  <Button
                    display={{ base: 'flex', lg: 'none' }}
                    alignItems={'center'}
                    p={0}
                    rounded={'full'}
                    onClick={onToggle}
                    aria-label={'Toggle Navigation'}
                    size={'md'}
                    borderColor={'whiteAlpha.50'}
                    _active={{
                      boxShadow: 'none',
                    }}
                  >
                    <Hicon isOpen={mobileNavOpen} />
                  </Button>
                  {userProfile ? (
                    <Button
                      aria-label="User"
                      variant={'unstyled'}
                      display={'flex'}
                      gap={4}
                      role={'group'}
                      _hover={{ color: 'gray.200' }}
                      _focus={{ color: 'gray.200' }}
                      onClick={() => {
                        onDrawerOpen();
                        getBalanceSol();
                      }}
                    >
                      <Heading
                        as={'span'}
                        size={'sm'}
                        bgGradient={'linear(to-r, main.purple, main.blue)'}
                        bgClip={'text'}
                        display={{ base: 'none', '2xl': 'block' }}
                      >
                        {userProfile.name}
                      </Heading>
                      <Flex
                        p={userProfile.profilePictureUrl ? 0 : 2}
                        bgGradient={
                          'linear(to-br, main.magenta, main.purple ,main.blue)'
                        }
                        rounded={'full'}
                        color={'gray.50'}
                        _groupHover={{
                          color: 'gray.100',
                          filter: 'brightness(98%)',
                        }}
                      >
                        {userProfile.profilePictureUrl ? (
                          <Avatar
                            name={
                              userProfile.profilePictureUrl
                                ? userProfile.name
                                : undefined
                            }
                            src={userProfile.profilePictureUrl}
                          />
                        ) : (
                          <Icon
                            as={FaUserCircle}
                            fontSize="1.5rem"
                            aria-label="Settings"
                            _groupHover={{ color: 'gray.100' }}
                          />
                        )}
                      </Flex>
                    </Button>
                  ) : (
                    <Popover
                      trigger={triggerPopover == 'click' ? 'click' : 'hover'}
                      placement={'bottom-start'}
                      closeOnBlur={true}
                      closeOnEsc={true}
                    >
                      {({ isOpen, onClose }) => (
                        <>
                          <PopoverTrigger>
                            <Button
                              aria-label="User"
                              variant={'unstyled'}
                              display={'flex'}
                              gap={4}
                              role={'group'}
                              _hover={{ color: 'gray.200' }}
                              _focus={{ color: 'gray.200' }}
                            >
                              <Flex
                                p={2}
                                bgGradient={
                                  'linear(to-br, main.magenta, main.purple ,main.blue)'
                                }
                                rounded={'full'}
                                color={'gray.50'}
                                _groupHover={{
                                  color: 'gray.100',
                                  filter: 'brightness(98%)',
                                }}
                              >
                                <Icon
                                  as={FaUserCircle}
                                  fontSize="1.5rem"
                                  aria-label="Settings"
                                  _groupHover={{ color: 'gray.100' }}
                                />
                              </Flex>
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent
                            border={0}
                            boxShadow={'xl'}
                            bg={popoverContentBgColor}
                            p={4}
                            rounded={'xl'}
                            minWidth={{ base: '200px' }}
                            onClick={onClose}
                          >
                            <Stack>
                              {children.map((child) => (
                                <DesktopSubNav key={child.label} {...child} />
                              ))}
                            </Stack>
                          </PopoverContent>
                        </>
                      )}
                    </Popover>
                  )}
                  <NavDrawer
                    userProfile={userProfile}
                    onClose={onDrawerClose}
                    isOpen={isDrawerOpen}
                    singOutHandler={() => signOut()}
                    accInfo={accInfo}
                    getBalanceHandler={() => getBalanceSol()}
                  >
                    <Box />
                  </NavDrawer>
                </Flex>
              </Flex>
              <Collapse in={mobileNavOpen} animateOpacity>
                <MobileNav onClose={onClose} />
              </Collapse>
            </>
          )}
        </Box>
      </Box>

      <SlideFade in={!header}>
        {userProfile &&
        aboutToExpireCollection.loaded &&
        aboutToExpireCollection.collection.length > 0 ? (
          <TopNavAlert collection={aboutToExpireCollection.collection} />
        ) : null}
      </SlideFade>
    </>
  );
}

const TopNavAlert = (props: {
  collection: Array<Collection & { dayLeft?: number }>;
}) => {
  const { collection } = props;
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <Alert
      status="warning"
      variant="left-accent"
      rounded={'none'}
      bg={'orange.100'}
      borderColor={'orange.400'}
      hidden={!isVisible}
    >
      <AlertIcon color={'orange.400'} />
      <Box>
        <AlertDescription>
          <Text color={'gray.800'} fontWeight={'medium'}>
            You have {collection.length} active collection in the launchpad that
            will end in <strong>{collection[0].dayLeft} days</strong>. To mint
            the remaining items,{' '}
            <Link
              color={'gray.800'}
              fontWeight={'bold'}
              as={NextLink}
              href={`/launchpad/creator/dashboard`}
              _hover={{ textDecoration: 'underline' }}
            >
              click here
            </Link>
          </Text>
        </AlertDescription>
      </Box>
      <CloseButton
        title="Close Notification"
        size={'sm'}
        rounded={'full'}
        position={'absolute'}
        right={2}
        top={3}
        color={'gray.800'}
        onClick={onClose}
      />
    </Alert>
  );
};

const NavDrawer = (props: NavDrawerProps) => {
  const { userProfile, isOpen, onClose, singOutHandler } = props;

  const { copyLinkAddressToClipboard, copyContentToClipboard } =
    useCopyLinkToClipboard();
  const { accInfo, getBalanceSol, loaded } = useBalanceSol(userProfile);
  const { idrBalance } = useParameterStore();

  const {
    isOpen: modalIsOpen,
    onClose: modalOnClose,
    onOpen: modalOnOpen,
  } = useDisclosure();
  const cardBg = useColorModeValue('gray.50', 'spaceCadet.700');
  const bgColorOutline = useColorModeValue('gray.50', 'spaceCadet.700');

  return (
    <Drawer
      variant={'main'}
      placement={'right'}
      onClose={onClose}
      isOpen={isOpen}
      size={'sm'}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton rounded={'full'} variant={'ghost'} />
        <DrawerBody>
          {userProfile && (
            <Stack
              py={8}
              gap={4}
              divider={<Divider borderColor={'alpha.purple'} />}
            >
              <VStack gap={2}>
                <AspectRatio ratio={1} h={'100px'} w={'100px'}>
                  <Avatar
                    name={
                      userProfile.profilePictureUrl
                        ? userProfile.name
                        : undefined
                    }
                    src={userProfile?.profilePictureUrl ?? undefined}
                  />
                </AspectRatio>
                <VStack gap={0}>
                  <Heading as={'span'} size={'md'}>
                    {userProfile?.name}
                  </Heading>
                  <Button
                    as={NextLink}
                    href="/profile"
                    shallow={true}
                    variant={'link'}
                    size={'md'}
                    fontWeight={'normal'}
                    rightIcon={<Icon as={FaExternalLinkAlt} />}
                    onClick={() => onClose()}
                  >
                    Go to profile
                  </Button>
                </VStack>
              </VStack>
              {accInfo ? (
                <Stack px={6} py={4}>
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Heading as={'span'} size={'sm'}>
                      Balance
                    </Heading>
                    <Button
                      variant={'unstyiled'}
                      rightIcon={<FaCopy />}
                      fontWeight={'medium'}
                      fontSize={'sm'}
                      px={0}
                      maxW={'40%'}
                      onClick={() =>
                        accInfo.publicKey &&
                        copyContentToClipboard(accInfo.publicKey)
                      }
                    >
                      <Text noOfLines={1} whiteSpace={'normal'}>
                        {accInfo.publicKey}
                      </Text>
                    </Button>
                  </Flex>
                  <Stack
                    p={6}
                    gap={4}
                    rounded={'lg'}
                    border={'2px solid'}
                    borderColor={'alpha.purple'}
                    shadow={'sm'}
                    bg={cardBg}
                    position={'relative'}
                  >
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Flex gap={3} alignItems={'center'}>
                        <Box w={12}>
                          <SolanaIcon />
                        </Box>
                        <Stack gap={0}>
                          <Heading
                            as={'span'}
                            fontSize={'xl'}
                            fontWeight={'semibold'}
                          >
                            SOL
                          </Heading>
                          <Text as={'span'} mt={'0!important'}>
                            Solana
                          </Text>
                        </Stack>
                      </Flex>
                      <Flex gap={1}>
                        <Flex
                          flexDirection={'column'}
                          alignItems={'flex-end'}
                          gap={0}
                          textAlign={'right'}
                        >
                          {loaded ? (
                            <>
                              <Heading
                                as={'span'}
                                fontSize={'xl'}
                                fontWeight={'semibold'}
                              >
                                {accInfo.balance}
                              </Heading>
                              <Text as={'span'} mt={'0!important'}>
                                {accInfo.balance && idrBalance
                                  ? formatIDR(
                                      accInfo.balance * idrBalance,
                                      'id-ID'
                                    )
                                  : null}
                              </Text>
                            </>
                          ) : (
                            <Stack gap={2} alignItems={'flex-end'}>
                              <Skeleton w={'100px'} h={3} />
                              <Skeleton w={'120px'} h={2} />
                            </Stack>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex gap={4} flexDirection={{ base: 'column', md: 'row' }}>
                      <MainButton
                        buttonType={'solid'}
                        onClick={(e) => getBalanceSol()}
                        width={'full'}
                      >
                        <Flex gap={2}>
                          <motion.div
                            animate={
                              !loaded
                                ? { rotate: [0, 180, 360] }
                                : { rotate: [0, 0] }
                            }
                            transition={{
                              duration: 0.5,
                              ease: 'easeInOut',
                              times: [0, 0.5, 1],
                              repeatDelay: 0.5,
                            }}
                          >
                            <IoRefresh />
                          </motion.div>
                          Refresh Wallet
                        </Flex>
                      </MainButton>

                      <LinkBox>
                        <MainButton
                          onClick={onClose}
                          p={'2px'}
                          bgColorChange={{
                            light: bgColorOutline,
                            dark: bgColorOutline,
                          }}
                          enableBgChange
                          width={'full'}
                        >
                          <LinkOverlay as={NextLink} href={'/wallet'}>
                            <Flex gap={2}>
                              <FaClipboardList />
                              Wallet History
                            </Flex>
                          </LinkOverlay>
                        </MainButton>
                      </LinkBox>
                    </Flex>
                    <Box>
                      <MainButton
                        width={'full'}
                        p={'2px'}
                        bgColorChange={{
                          light: bgColorOutline,
                          dark: bgColorOutline,
                        }}
                        enableBgChange
                        onClick={modalOnOpen}
                      >
                        <Flex gap={2}>
                          <BiTransfer />
                          Transfer SOL
                        </Flex>
                      </MainButton>
                      <ModalSendSol isOpen={modalIsOpen} onClose={modalOnClose}>
                        <></>
                      </ModalSendSol>
                    </Box>
                  </Stack>
                </Stack>
              ) : null}
              <VStack px={6}>
                <Button
                  as={NextLink}
                  href={'/redeem-code'}
                  variant={'ghost'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  gap={4}
                  width={'full'}
                  onClick={() => onClose()}
                >
                  <Icon as={PiBarcode} boxSize={6} />
                  Redeem Code
                </Button>
                <Button
                  as={NextLink}
                  href={'/launchpad/creator/dashboard'}
                  variant={'ghost'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  gap={4}
                  width={'full'}
                  onClick={() => onClose()}
                >
                  <Icon as={RiImageEditFill} boxSize={6} />
                  Creator Launchpad
                </Button>
                <Button
                  as={NextLink}
                  href={'/profile/setting'}
                  variant={'ghost'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  gap={4}
                  width={'full'}
                  onClick={() => onClose()}
                >
                  <Icon as={RiSettings2Line} boxSize={6} />
                  Account Settings
                </Button>
                <Button
                  as={NextLink}
                  href={'/terms'}
                  variant={'ghost'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  gap={4}
                  width={'full'}
                  onClick={() => onClose()}
                >
                  <Icon as={HiOutlineNewspaper} boxSize={6} />
                  Terms & Condition
                </Button>
                <Button
                  as={NextLink}
                  href={'/terms?tab=privacy'}
                  variant={'ghost'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  gap={4}
                  width={'full'}
                  onClick={() => onClose()}
                >
                  <Icon as={RiSecurePaymentLine} boxSize={6} />
                  Privacy Policy
                </Button>
                <Button
                  variant={'ghost'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  gap={4}
                  width={'full'}
                  onClick={() => {
                    if (singOutHandler) {
                      singOutHandler();
                      onClose();
                    }
                  }}
                >
                  <Icon as={BiLogOut} boxSize={6} />
                  Sign Out
                </Button>
              </VStack>
            </Stack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const DesktopNav = () => {
  const router = useRouter();

  return (
    <Flex gap={12} alignItems={'center'}>
      {navigations.map((navItem) => (
        <Box key={navItem.label}>
          <LinkNavItem
            label={navItem.label}
            href={navItem.href}
            icon={navItem.icon}
            current={router.route.split('/')[1] == navItem.href?.split('/')[1]}
          />
        </Box>
      ))}
    </Flex>
  );
};

const DesktopSubNav = ({ label, href, icon, handler }: NavItem) => {
  const linkHoverColor = useColorModeValue('main.blue', 'main.blue');

  return (
    <LinkBox
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('gray.50', 'main.spaceCadet') }}
      cursor="pointer"
      onClick={(e) => {
        handler && handler(e);
      }}
    >
      {handler ? (
        <Stack
          direction={'row'}
          align={'center'}
          _hover={{
            color: linkHoverColor,
          }}
        >
          <Flex gap={4} alignItems={'center'} fontWeight={500}>
            <Icon as={icon} boxSize={4} />
            {label}
          </Flex>
          <Flex
            transition={'all .2s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}
          >
            <Icon color={'main.purple'} w={5} h={5} as={FiChevronRight} />
          </Flex>
        </Stack>
      ) : (
        <LinkOverlay
          as={NextLink}
          href={href ?? '#'}
          fontWeight={500}
          fontSize={'md'}
          _hover={{
            color: linkHoverColor,
          }}
        >
          <Stack direction={'row'} align={'center'}>
            <Flex gap={4} alignItems={'center'}>
              <Icon as={icon} boxSize={4} />
              {label}
            </Flex>
            <Flex
              transition={'all .2s ease'}
              transform={'translateX(-10px)'}
              opacity={0}
              _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
              justify={'flex-end'}
              align={'center'}
              flex={1}
            >
              <Icon color={'main.blue'} w={5} h={5} as={FiChevronRight} />
            </Flex>
          </Stack>
        </LinkOverlay>
      )}
    </LinkBox>
  );
};

const MobileNav = (props: { onClose: () => void }) => {
  return (
    <Stack py={2} display={{ lg: 'none' }} zIndex={'docked'}>
      {navigations.map((navItem) => (
        <Flex
          px={1}
          justify={'space-between'}
          align={'center'}
          key={navItem.label}
          onClick={() => props.onClose()}
        >
          <LinkNavItem {...navItem} />
        </Flex>
      ))}
    </Stack>
  );
};

const LinkNavItem = ({ label, href, current }: NavItem) => {
  const linkColor = useColorModeValue('gray.700', 'gray.100');
  const linkHoverColor = useColorModeValue('gray.600', 'gray.100');

  return (
    <Link
      as={NextLink}
      href={href ?? '#'}
      px={1}
      py={2}
      fontSize={'sm'}
      fontWeight={current ? 'bold' : 'bold'}
      textTransform={'uppercase'}
      transition={'all'}
      color={linkColor}
      letterSpacing={'1.5px'}
      textDecoration={current ? 'underline' : 'none'}
      textUnderlineOffset={4}
      variant={current ? 'purpleGradient' : ''}
      _hover={{
        textDecoration: 'underline',
        textDecorationThickness: 2,
        textDecorationColor: 'main.purple',
        color: linkHoverColor,
      }}
      _focus={{
        boxShadow: 'none',
      }}
    >
      {label}
    </Link>
  );
};

const SearchBar = ({
  header,
  searchMode,
}: {
  header: boolean;
  searchMode: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(ref, true);

  const bgSearchContainer = useColorModeValue('gray.200', 'main.spaceCadet');
  const bgHover = useColorModeValue('blackAlpha.50', 'blackAlpha.200');

  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFocused, setSearchFocused] = useState(false);

  const [itemsRelated, setItemsRelated] = useState<{
    loaded: boolean;
    items: Item[];
  }>({ loaded: false, items: [] });
  const [collectionsRelated, setCollectionsRelated] = useState<{
    loaded: boolean;
    collections: Collection[];
  }>({ loaded: false, collections: [] });
  const [creatorsRelated, setCreatorsRelated] = useState<{
    loaded: boolean;
    creators: UserProfile[];
  }>({ loaded: false, creators: [] });

  const [dataLength, setDataLength] = useState({
    items: 4,
    collections: 4,
    creators: 4,
  });

  const debounceSearch = useDebounce(searchInput, 500);
  const isNotEmpty: boolean =
    itemsRelated.loaded &&
    itemsRelated.items.length == 0 &&
    collectionsRelated.loaded &&
    collectionsRelated.collections.length == 0 &&
    creatorsRelated.loaded &&
    creatorsRelated.creators.length == 0;

  useOutsideClick({
    ref: ref,
    handler: () => {
      setSearchFocused(false);
      setSearchInput('');
    },
  });

  useEffect(() => {
    const handleSearch = async () => {
      try {
        const collections: any = await API.graphql(
          graphqlOperation(searchCollections, {
            limit: 4,
            filter: {
              and: [
                { status: { ne: CollectionStatus.COMING_SOON } },
                { status: { ne: CollectionStatus.DRAFT } },
              ],
              name: {
                matchPhrasePrefix: `*${debounceSearch}`,
              },
            },
          })
        );

        const searchableCollections = await API.graphql<
          GraphQLQuery<SearchCollectionsQuery>
        >(
          graphqlOperation(searchCollections, {
            filter: {
              and: [
                { status: { ne: CollectionStatus.COMING_SOON } },
                { status: { ne: CollectionStatus.DRAFT } },
              ],
            },
          })
        );

        const items: any = await API.graphql(
          graphqlOperation(searchItems, {
            limit: 8,
            filter: {
              or: searchableCollections.data?.searchCollections?.items.map(
                (collection) => ({ collectionId: { eq: collection?.id } })
              ),
              name: { matchPhrasePrefix: `*${debounceSearch}*` },
            },
          })
        );

        const creators: any = await API.graphql(
          graphqlOperation(searchUserProfiles, {
            limit: 4,
            filter: {
              name: { matchPhrasePrefix: `*${debounceSearch}*` },
              type: { eq: 'OFFICIAL' },
            },
          })
        );

        // show items with available collection status
        setItemsRelated({
          loaded: true,
          items: items.data.searchItems.items,
        });

        // show available collection status
        setCollectionsRelated({
          loaded: true,
          collections: collections.data.searchCollections.items,
        });

        setCreatorsRelated({
          loaded: true,
          creators: creators.data.searchUserProfiles.items,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (debounceSearch) {
      setCollectionsRelated((c) => ({ ...c, loaded: false }));
      setItemsRelated((i) => ({ ...i, loaded: false }));
      setCreatorsRelated((c) => ({ ...c, loaded: false }));
      setDataLength({ items: 4, collections: 4, creators: 4 });
      handleSearch();
    }
  }, [debounceSearch]);

  return (
    <Box w={'full'} ref={ref}>
      <InputGroup width={'full'} ml={'auto'}>
        <InputLeftElement pointerEvents="none">
          <Icon as={FaSearch} boxSize="4" />
        </InputLeftElement>
        <Input
          name={'search'}
          autoFocus={searchMode}
          placeholder="Search items, collection, and account"
          variant={'main'}
          w={'full'}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onFocus={(e) => setSearchFocused(true)}
        />
        <InputRightElement>
          <IconButton
            aria-label="Delete search input"
            hidden={searchInput ? false : true}
            display={'flex'}
            fontSize={'md'}
            size={'xs'}
            icon={<RiCloseFill />}
            variant={'unstyled'}
            onClick={() => setSearchInput('')}
          />
        </InputRightElement>
      </InputGroup>
      {searchFocused && searchInput !== '' && (
        <Box
          position={'absolute'}
          top={24}
          left={dimensions?.borderBox.left + 'px'}
          zIndex={'overlay'}
          bg={bgSearchContainer}
          rounded={'lg'}
          shadow={'xl'}
          maxH={'80vh'}
          overflowY={'auto'}
          w={{
            base: Number(dimensions?.borderBox.width) + 48 + 'px',
            md: dimensions?.borderBox.width,
          }}
          sx={{
            '&::-webkit-scrollbar': {
              width: '12px',
              borderRadius: '8px',
              bg: 'main.newDarkBlue',
            },
            '&::-webkit-scrollbar-thumb': {
              bg: `whiteAlpha.400`,
            },
          }}
        >
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }}
            gap={8}
            p={6}
          >
            {!itemsRelated.loaded ? (
              <>
                <GridItem>
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Items
                  </Text>
                </GridItem>
                <GridItem
                  colSpan={4}
                  borderLeft={{ base: 'none', md: '1px solid' }}
                  borderColor={{ base: 'transparent', md: 'whiteAlpha.200' }}
                >
                  <Stack gap={0}>
                    {!itemsRelated.loaded &&
                      [...Array(2)].map((i, x) => (
                        <CardItemSmallSkeleton key={x} />
                      ))}
                  </Stack>
                </GridItem>
              </>
            ) : (
              itemsRelated.items.length > 0 && (
                <>
                  <GridItem>
                    <Text fontSize={'sm'} fontWeight={'semibold'}>
                      Items
                    </Text>
                  </GridItem>
                  <GridItem
                    colSpan={4}
                    borderLeft={{ base: 'none', md: '1px solid' }}
                    borderColor={{ base: 'transparent', md: 'whiteAlpha.200' }}
                  >
                    <Stack gap={0}>
                      {itemsRelated.loaded &&
                        itemsRelated.items
                          .slice(0, dataLength.items)
                          .map((item, index) => (
                            <Box
                              onClick={() => {
                                setSearchFocused(false);
                                setSearchInput('');
                              }}
                              key={index}
                            >
                              <CardItemSmall item={item} />
                            </Box>
                          ))}
                      <Flex justifyContent={'center'}>
                        <Button
                          size={'xs'}
                          rounded={'none'}
                          variant={'unstyled'}
                          rightIcon={<IoChevronDown />}
                          _hover={{ bg: bgHover }}
                          py={3}
                          h={12}
                          width={'full'}
                          hidden={itemsRelated.items.length <= dataLength.items}
                          onClick={() => {
                            setDataLength((d) => ({
                              ...d,
                              items: d.items + 4,
                            }));
                          }}
                        >
                          More
                        </Button>
                      </Flex>
                    </Stack>
                  </GridItem>
                </>
              )
            )}
            {!collectionsRelated.loaded ? (
              <>
                <GridItem>
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Collections
                  </Text>
                </GridItem>
                <GridItem
                  colSpan={4}
                  borderLeft={{ base: 'none', md: '1px solid' }}
                  borderColor={{ base: 'transparent', md: 'whiteAlpha.200' }}
                >
                  <Stack gap={0}>
                    {!collectionsRelated.loaded &&
                      [...Array(2)].map((i, x) => (
                        <CardItemSmallSkeleton key={x} />
                      ))}
                  </Stack>
                </GridItem>
              </>
            ) : (
              collectionsRelated.collections.length > 0 && (
                <>
                  <GridItem>
                    <Text fontSize={'sm'} fontWeight={'semibold'}>
                      Collections
                    </Text>
                  </GridItem>
                  <GridItem
                    colSpan={4}
                    borderLeft={{ base: 'none', md: '1px solid' }}
                    borderColor={{ base: 'transparent', md: 'whiteAlpha.200' }}
                  >
                    <Stack gap={0}>
                      {collectionsRelated.loaded &&
                        collectionsRelated.collections.map((item, index) => (
                          <Box
                            onClick={() => {
                              setSearchFocused(false);
                              setSearchInput('');
                            }}
                            key={index}
                          >
                            <CardCollectionSmall item={item} />
                          </Box>
                        ))}
                      <Flex justifyContent={'center'}>
                        <Button
                          size={'xs'}
                          rounded={'none'}
                          variant={'unstyled'}
                          rightIcon={<IoChevronDown />}
                          _hover={{ bg: bgHover }}
                          py={3}
                          h={12}
                          width={'full'}
                          hidden={
                            collectionsRelated.collections.length <=
                            dataLength.collections
                          }
                          onClick={() => {
                            setDataLength((d) => ({
                              ...d,
                              items: d.collections + 4,
                            }));
                          }}
                        >
                          More
                        </Button>
                      </Flex>
                    </Stack>
                  </GridItem>
                </>
              )
            )}
            {!creatorsRelated.loaded ? (
              <>
                <GridItem>
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Creators
                  </Text>
                </GridItem>
                <GridItem
                  colSpan={4}
                  borderLeft={{ base: 'none', md: '1px solid' }}
                  borderColor={{ base: 'transparent', md: 'whiteAlpha.200' }}
                >
                  <Stack gap={0}>
                    {!creatorsRelated.loaded &&
                      [...Array(2)].map((i, x) => (
                        <CardItemSmallSkeleton key={x} />
                      ))}
                  </Stack>
                </GridItem>
              </>
            ) : (
              creatorsRelated.creators.length > 0 && (
                <>
                  <GridItem>
                    <Text fontSize={'sm'} fontWeight={'semibold'}>
                      Creators
                    </Text>
                  </GridItem>
                  <GridItem
                    colSpan={4}
                    borderLeft={{ base: 'none', md: '1px solid' }}
                    borderColor={{ base: 'transparent', md: 'whiteAlpha.200' }}
                  >
                    <Stack gap={0}>
                      {creatorsRelated.loaded &&
                        creatorsRelated.creators.map((item, index) => (
                          <Box
                            onClick={() => {
                              setSearchFocused(false);
                              setSearchInput('');
                            }}
                            key={index}
                          >
                            <CardCreatorSmall item={item} />
                          </Box>
                        ))}
                      <Flex justifyContent={'center'}>
                        <Button
                          size={'xs'}
                          rounded={'none'}
                          variant={'unstyled'}
                          rightIcon={<IoChevronDown />}
                          _hover={{ bg: bgHover }}
                          py={3}
                          h={12}
                          width={'full'}
                          hidden={
                            creatorsRelated.creators.length <=
                            dataLength.creators
                          }
                          onClick={() => {
                            setDataLength((d) => ({
                              ...d,
                              items: d.creators + 4,
                            }));
                          }}
                        >
                          More
                        </Button>
                      </Flex>
                    </Stack>
                  </GridItem>
                </>
              )
            )}
            {isNotEmpty && (
              <GridItem colSpan={5}>
                <EmptyItems />
              </GridItem>
            )}
          </Grid>
          {!isNotEmpty && (
            <Flex
              w={'full'}
              onClick={() => {
                setSearchFocused(false);
                setSearchInput('');
              }}
            >
              <NextLink href={`/item?search=${debounceSearch}`} passHref>
                <Button
                  width={'full'}
                  size={'xs'}
                  rounded={'none'}
                  variant={'unstyled'}
                  rightIcon={<IoChevronForward />}
                  _hover={{ bg: bgHover }}
                  p={3}
                  h={12}
                >
                  Search all items
                </Button>
              </NextLink>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};
