import {
  Button,
  ButtonProps,
  Flex,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";

interface CustomButtonProps extends ButtonProps {
  buttonType?: "outline" | "accent-outline" | "solid";
  enableBgChange?: boolean;
  href?: string;
  target?: string;
  rel?: string;
  bgColorChange?: {
    light: string;
    dark: string;
  };
}

export const MainButton = (props: CustomButtonProps) => {
  const {
    buttonType = "outline",
    disabled,
    isDisabled,
    isLoading,
    children,
    rounded,
    roundedRight,
    roundedLeft,
    px,
    py,
    p,
    href,
    target,
    rel,
    enableBgChange,
    bgColorChange,
    ...others
  } = props;

  const bgButton = useColorModeValue("white", "main.newDarkBlue");
  const bgChange = useColorModeValue(bgColorChange?.light, bgColorChange?.dark);
  const textColor = useColorModeValue("gray.800", "gray.50");
  const textColorHover = useColorModeValue(
    buttonType !== "solid"
      ? disabled || isDisabled
        ? "gray.800"
        : "gray.50"
      : "gray.50",
    "gray.50"
  );

  return (
    <Button
      as={href ? Link : undefined}
      href={href}
      rel={href ? rel : undefined}
      target={href ? target : undefined}
      color={buttonType == "solid" ? "white" : textColor}
      variant={"gradient"}
      bg={buttonType == "accent-outline" ? "gradient.purpleRainbow" : undefined}
      p={p ?? "1px"}
      isDisabled={disabled || isDisabled}
      rounded={rounded ?? "md"}
      roundedRight={roundedRight ?? rounded ?? "md"}
      roundedLeft={roundedLeft ?? rounded ?? "md"}
      shadow={"md"}
      role={"button"}
      isLoading={isLoading}
      _focus={{
        color: textColor,
      }}
      _loading={{
        bgGradient: "linear(to-br, main.magenta, main.purple, main.blue)",
        px: props.loadingText ? 4 : "initial",
      }}
      _hover={
        buttonType == "accent-outline"
          ? {
              bg: "gradient.purpleRainbow",
              _loading: {
                bgGradient:
                  "linear(to-br, main.magenta, main.purple, main.blue)",
              },
            }
          : undefined
      }
      {...others}
    >
      <Flex
        width={"full"}
        height={"full"}
        overflow={"hidden"}
        alignItems={"center"}
        justifyContent={"center"}
        px={px ?? 3}
        py={py ?? 3}
        bg={
          enableBgChange
            ? bgChange
            : buttonType !== "solid"
            ? bgButton
            : "initial"
        }
        boxShadow={disabled || isDisabled ? "none" : "initial"}
        rounded={rounded ?? "md"}
        roundedRight={roundedRight ?? rounded ?? "md"}
        roundedLeft={roundedLeft ?? rounded ?? "md"}
        transition={"background .25s ease-in-out"}
        _focus={{
          color: textColor,
        }}
        _hover={{
          color: textColorHover,
          bg:
            disabled || isDisabled
              ? buttonType !== "solid"
                ? bgButton
                : "transparent"
              : "transparent",
          bgGradient:
            buttonType === "accent-outline"
              ? disabled || isDisabled
                ? undefined
                : "linear(to-br, main.magenta, main.purple, main.blue)"
              : undefined,
        }}
      >
        {children}
      </Flex>
    </Button>
  );
};
