import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { SolanaIcon } from "components/layout/logo";
import { IoRefresh } from "react-icons/io5";

import { Price } from "src/API";

import { motion } from "framer-motion";

export const PriceAndBalanceInfo = ({
  balance,
  balanceLoaded,
  getBalance,
  priceName,
  price,
}: {
  balance: number;
  balanceLoaded: boolean;
  getBalance?: () => void;
  priceName?: string;
  price?: Price;
}) => {
  const bgBoxColor = useColorModeValue("gray.200", "main.spaceCadet");

  return (
    <Stack gap={6}>
      <Flex gap={8} flexDirection={{ base: "column", md: "row" }}>
        {price && priceName ? (
          <Box w={{ base: "full", md: "50%" }}>
            <Heading as={"span"} size={"sm"}>
              {priceName}
            </Heading>
            <Flex
              p={4}
              gap={4}
              mt={2}
              alignItems={"center"}
              rounded={"sm"}
              border={"4px solid"}
              borderColor={bgBoxColor}
              bg={bgBoxColor}
            >
              <Box w={12}>
                <SolanaIcon />
              </Box>
              <Heading
                as={"span"}
                fontWeight={"semibold"}
                size={"lg"}
                noOfLines={1}
              >
                {price.value} {price.currency}
              </Heading>
            </Flex>
          </Box>
        ) : null}
        <Box w={{ base: "full", md: price || priceName ? "50%" : "full" }}>
          <Heading as={"span"} size={"sm"}>
            Your Balance
          </Heading>
          <Flex
            p={4}
            mt={2}
            alignItems={"center"}
            justifyContent={"space-between"}
            rounded={"sm"}
            border={"4px solid"}
            borderColor={bgBoxColor}
          >
            <Flex
              gap={4}
              justifyContent={"space-between"}
              alignItems={"center"}
              w={"full"}
            >
              <Flex gap={4} alignItems={"center"}>
                <Box w={12}>
                  <SolanaIcon />
                </Box>
                <Heading
                  as={"span"}
                  fontWeight={"semibold"}
                  size={"lg"}
                  noOfLines={1}
                >
                  {balance ? (
                    balanceLoaded ? (
                      `${balance.toString().slice(0, 5)} SOL`
                    ) : (
                      <Skeleton w={"100px"} h={3} />
                    )
                  ) : (
                    0
                  )}
                </Heading>
              </Flex>
              {getBalance ? (
                <IconButton
                  variant={"ghost"}
                  rounded={"full"}
                  aria-label="Refresh Balance"
                  title={"Refresh Balance"}
                  icon={
                    <motion.div
                      animate={
                        !balanceLoaded
                          ? { rotate: [0, 180, 360] }
                          : { rotate: [0, 0] }
                      }
                      transition={{
                        duration: 0.5,
                        ease: "easeInOut",
                        times: [0, 0.5, 1],
                        repeatDelay: 0.5,
                      }}
                    >
                      <Icon as={IoRefresh} boxSize={5} />
                    </motion.div>
                  }
                  onClick={() => getBalance()}
                />
              ) : null}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Stack>
  );
};
