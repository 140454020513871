import {
  Box,
  BoxProps,
  Flex,
  others,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

interface ICardAccent extends BoxProps {
  disabled?: boolean;
}

export const CardAccent = (props: ICardAccent) => {
  const { children, disabled, ...others } = props;

  const bgColor = useColorModeValue('white', 'gradient.purpleDark');

  return (
    <Box
      bgGradient={'linear(to-b, main.magenta, main.purple ,main.cyan)'}
      rounded={'lg'}
      p={'1px'}
      textAlign={'left'}
      opacity={disabled ? '50%' : undefined}
    >
      <Box bg={bgColor} rounded={'lg'} shadow={'lg'} {...others}>
        {children}
      </Box>
    </Box>
  );
};

export const CardBox = (props: BoxProps) => {
  const { children, ...others } = props;

  const bgColor = useColorModeValue('white', 'gradient.purpleDark');

  return (
    <Box
      bg={bgColor}
      rounded={'lg'}
      p={'1px'}
      textAlign={'left'}
      borderWidth={'1px'}
      borderColor={'alpha.purple'}
      shadow={'lg'}
      {...others}
    >
      {children}
    </Box>
  );
};

export const CardLabel = (props: { children: ReactElement }) => {
  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      w={'full'}
      bg={useColorModeValue('gray.200', 'spaceCadet.800')}
      rounded={'md'}
      p={1}
      fontSize={'sm'}
      gap={1}
    >
      {props.children}
    </Flex>
  );
};
