import NextLink from "next/link";

import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Image as ChakraImage,
  Link,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Stack,
  VStack,
  Text,
  useColorModeValue,
  Icon,
  AspectRatio,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";

import { Verified } from "components/global/logo";
import { UserProfile } from "src/API";
import { motion } from "framer-motion";

export default function CardCreator(props: { creator: UserProfile }) {
  const { id, name, description, profileBannerUrl, profilePictureUrl, type } =
    props.creator;

  const bgColor = useColorModeValue("white", "gradient.purpleDark");
  const borderColor = useColorModeValue("gray.200", "main.borderBlue");
  const textColor = useColorModeValue("gray.600", "white");
  const bgHover = useColorModeValue("gray.50", "main.newDarkBlue");
  const bgAvatar = useColorModeValue("white", "main.newDarkBlue");

  return (
    <LinkBox
      as={motion.div}
      layout={"size"}
      alignItems="center"
      rounded="lg"
      position="relative"
      height={"auto"}
      shadow={"lg"}
      p={"1px"}
      bg={"gradient.blue"}
      _hover={{ shadow: "xl" }}
      cursor="pointer"
      overflow={"hidden"}
      role={"group"}
      transition={"box-shadow 0.25s ease-in-out"}
    >
      <Box position={"relative"} rounded="lg" bg={bgColor} height={"full"}>
        <AspectRatio
          width={"100%"}
          height={{ base: "140px" }}
          bgColor={"main.spaceCadet"}
          overflow={"hidden"}
          roundedTop="lg"
        >
          <ChakraImage
            src={
              profileBannerUrl
                ? profileBannerUrl
                : "/images/default-image/default-banner-1.webp"
            }
            alt={`Banner of ${name}`}
            objectFit="cover"
            w={"full"}
            h={"full"}
            fallback={<Skeleton w={"full"} h={"full"} />}
          />
        </AspectRatio>

        <Flex
          justify={"center"}
          mt={-7}
          _groupHover={{ bg: bgHover }}
          transition={"all 0.25s ease-in-out"}
        >
          <Avatar
            aria-label={`Profile picture of ${name}`}
            name={name}
            size={"lg"}
            bg={bgAvatar}
            src={
              profilePictureUrl
                ? profilePictureUrl
                : "/images/default-image/nftone-square.webp"
            }
            borderWidth={3}
            borderColor={bgAvatar}
            _groupHover={{ transform: "translateY(-0.5rem)" }}
            transition={"transform 0.25s ease-in"}
          />
        </Flex>
        <Box
          p={6}
          _groupHover={{ bg: bgHover }}
          roundedBottom={"lg"}
          transition={"all 0.25s ease-in-out"}
        >
          <Stack
            spacing={0}
            align={"center"}
            pb={4}
            borderBottomWidth={1}
            borderBottomColor={borderColor}
          >
            <LinkOverlay
              as={NextLink}
              href={`/creator/${name}`}
              display={"flex"}
              alignItems={"center"}
              aria-label={`Read more about ${name}`}
            >
              <Heading
                fontSize={"lg"}
                fontWeight={500}
                textAlign="center"
                noOfLines={1}
              >
                {name}
              </Heading>
              {type == "OFFICIAL" && <Verified />}
            </LinkOverlay>
          </Stack>
          <Box color={"gray.500"} mt={"4"} minHeight={16}>
            <Text noOfLines={3} align="center" fontSize={"sm"}>
              {description}
            </Text>
          </Box>
        </Box>
      </Box>
    </LinkBox>
  );
}

export const CardCreatorSkeleton = () => {
  const bgColor = useColorModeValue("white", "main.darkblue");
  const borderColor = useColorModeValue("gray.200", "main.borderBlue");

  return (
    <Box
      as={motion.div}
      layout={"size"}
      alignItems="center"
      rounded="lg"
      position="relative"
      height={"auto"}
      p={"1px"}
      bg={"gradient.blue"}
      cursor="pointer"
      overflow={"hidden"}
      role={"group"}
    >
      <Box
        position={"relative"}
        overflow={"hidden"}
        rounded={"lg"}
        bg={bgColor}
        height={"full"}
      >
        <Box width={"full"} height={{ base: "140px" }} rounded="lg">
          <Skeleton width={"full"} height={"full"} />
        </Box>

        <Flex justify={"center"} mt={-7}>
          <Box rounded={"full"} bg={bgColor} w={"4rem"} h={"4rem"} zIndex={2}>
            <SkeletonCircle size={"4rem"} />
          </Box>
        </Flex>
        <Box p={6} roundedBottom={"lg"} transition={"all 0.25s ease-in-out"}>
          <Stack
            spacing={0}
            align={"center"}
            pb={3}
            pt={2}
            borderBottomWidth={1}
            borderBottomColor={borderColor}
          >
            <Skeleton h={2} w={"100px"} mb={2} />
          </Stack>
          <Box color={"gray.500"} pt={6} pb={3} minHeight={16}>
            <SkeletonText
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              noOfLines={3}
              spacing={3}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const CardCreatorSmall = (props: { item: UserProfile }) => {
  const { id, profilePictureUrl, name, type } = props.item;
  const bgHover = useColorModeValue("blackAlpha.50", "blackAlpha.200");

  return (
    <LinkBox
      as={Flex}
      gap={4}
      alignItems={"center"}
      p={2}
      _hover={{ bg: bgHover }}
      pl={4}
    >
      <Avatar
        aria-label={`Profile picture of ${name}`}
        name={name}
        w={8}
        h={8}
        bg={"main.newDarkBlue"}
        src={
          profilePictureUrl
            ? profilePictureUrl
            : "/images/default-image/nftone-square.webp"
        }
        transition={"all 0.25s ease-in"}
      />
      <Box>
        <Text
          fontSize={"sm"}
          fontWeight={"semibold"}
          lineHeight={1.5}
          noOfLines={1}
          display={"flex"}
          alignItems={"center"}
        >
          {name} {type === "OFFICIAL" && <Verified />}
        </Text>
      </Box>
      <LinkOverlay
        as={NextLink}
        href={`/creator/${name}`}
        aria-label={`Read more about ${name}`}
      />
    </LinkBox>
  );
};
